import React from 'react'

class UnknownFindinge extends React.Component {
  render() {
    return (
      <div>Unknown finding type: {this.props.data.__typename}</div>
    )
  }
}

export default UnknownFindinge