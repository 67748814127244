import React from 'react'
import { orderBy } from 'lodash'
import gql from 'graphql-tag'
import { withStyles } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Fab from '@material-ui/core/Fab';
import HelpIcon from '@material-ui/icons/Help'
import KernelIcon from '@material-ui/icons/Memory'
// TODO: This icon doesn't match sematic-ui 'address card' used in other places for this same data:
import CryptographicDataIcon from '@material-ui/icons/LockOpen'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EmptyContents from './EmptyContents'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import Page from '../../../../components/core/layout/Page'
import { formatHex } from '../../../../utils/format'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import ImageEntropyOverview from './ImageEntropyOverview'
import ComponentHierarchy from './ComponentHierarchy'


const getContentDescription = (content) => {
  switch (content.__typename) {
    case 'FileSystem':
      return content.fstype
    case 'Kernel':
      return content.desc
    case 'BaremetalContents':
      return content.desc
    default:
      return content.desc
  }
}

const getContentPrettyName = (typename) => {
  switch (typename) {
    case 'FileSystem':
      return 'File System'
    case 'BaremetalConents':
      return 'Baremetal Content'
    case 'ImageContents':
      return 'Other'
    default:
      return typename
  }
}

const getContentIcon = (content) => {
  switch (content.__typename) {
    case 'FileSystem':
      return <FolderIcon />
    case 'Kernel':
      return <KernelIcon />
    case 'BaremetalContents':
      return <KernelIcon />
    case 'CryptographicData':
      return <CryptographicDataIcon />
    default:
      return <HelpIcon />
  }
}

// Recursive function to handle things with extraction_context chains that need to be unpacked.
function recusiveBuildNested(arr, baseItem, preexistingAtLevel) {
  const current = arr.shift()
  const isBaseCase = arr.length === 0
  const offsetAlreadyAtLevel = current.offset in preexistingAtLevel
  let currentAtNextLevel;
  if (offsetAlreadyAtLevel) {
    currentAtNextLevel = preexistingAtLevel[current.offset];
  } else {
    currentAtNextLevel = [];
  }
  if (isBaseCase) {
    currentAtNextLevel.push(baseItem)
  } else {
    currentAtNextLevel.push(recusiveBuildNested(arr, baseItem, {}))
  }
  const res = preexistingAtLevel;
  // Overwrites or adds depending on if offsetAlreadyAtLevel:
  res[current['offset'].toString()] = currentAtNextLevel
  return res;
}

function showThisRow(c, showAll) {
  if (showAll)
    return true
  if (["FileSystem", "Kernel", "BaremetalContents"].includes(c.__typename))
    return true
  if (c.children !== undefined)
    return true
  return false
}

class Row extends React.Component {
  render() {
    const { classes, row, mode, showAll } = this.props;
    return (
      <TableRow className={classes.root} style={{display: showThisRow(row, showAll) ? 'table-row' : 'none'}}>
        <TableCell component="th" scope="row" className={classes.contentOffset}>
          {formatHex(row.offset)}
        </TableCell>
        <TableCell className={classes.contentBlock}>
          <Typography className={classes.contentBlockText}>
            {getContentIcon(row)}
            <span style={{marginLeft: '10px'}}>{getContentPrettyName(row.__typename)}</span>
          </Typography>
        </TableCell>
        <TableCell className={classes.sectionAnalysis}>
        { (mode === 'Details') ? <Typography>{`${getContentDescription(row)}${(row.children !== undefined) ? ', containing:':''}`}</Typography> : null }
        { (row.children === undefined) ? null : <ComponentHierarchy data={row.children} width={700} /> }
        </TableCell>
      </TableRow>
    )
  }
}

Row = withStyles((theme) => ({
  contentOffset: {
    fontSize: '1em',
    padding: 0,
    margin: 0
  },
  contentBlock: {
    width: 200,
    height: 50,
    padding: 5,
    borderWidth: '3px',
    borderTopStyle: 'none',
    borderStyle: 'solid',
    borderColor: '#5F5F5F',
    background: '#2d2f3d',
    color: 'white'
  },
  contentBlockText: {
    color: 'white',
    fontSize: 15
  },
  analysisBlock: {
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))(Row)


class ContentsTab extends React.Component {

  constructor(props) {
    super()
    const image = props.data.image.node
    let imageContentsRoots = []
    let imageContentsNested = {}
    function mergeNodeRelData(c) {
      let ec = c.extraction_context;
      if (ec !== null && ec !== undefined) {
        ec = ec.split(';');
        ec = ec.map((s) => {
          const tmp = s.split('|', 2);
          return {offset: parseInt(tmp[1]), type: tmp[0]};
        })
        //console.log('Have entries:', JSON.stringify(ec));
        imageContentsNested = recusiveBuildNested(ec, Object.assign({}, c.node, {offset: c.offset}), imageContentsNested)
        //console.log('imageContentsNested', JSON.stringify(imageContentsNested, null, 2));
      } else {
        imageContentsRoots.push(Object.assign({}, c.node, {offset: c.offset}));
        //console.log("Pushed onto:", c.node)
      }
    }

    image.filesystems.forEach(mergeNodeRelData)
    image.kernels.forEach(mergeNodeRelData)
    image.otherContents.forEach(mergeNodeRelData)
    image.cryptographicData.forEach(mergeNodeRelData)
    image.encryptedContents.forEach(mergeNodeRelData)
    image.baremetalContents.forEach(mergeNodeRelData)
    // TODO: Save off CryptographicKeys found at root of image, and access only those here.
    // NOTE: Resolver listed below gets from FileSystems & BaremetalContents items, not just at root:
    /*  cryptokeys {
          id
          node {
            id
          }
        }
    */
    //image.cryptokeys.forEach(mergeNodeRelData)
    imageContentsRoots = imageContentsRoots.map((c) => {
      if (c.offset in imageContentsNested) {
        c.children = imageContentsNested[c.offset];
      }
      return c;
    })
    const contents = orderBy(imageContentsRoots, [c => c.offset], ['asc'])

    this.state = {
      showAll: false,
      mode: 'Details',
      contents
    }
  }

  render() {
    const { classes, data } = this.props
    const { mode, showAll, contents } = this.state
    const submission = data
    const image = data.image.node
    
    return (
      <Page>
        <ImageEntropyOverview imageId={image.id} />
        <ScrollSpace plain={true}>
        { (contents.length === 0) ? (
          <EmptyContents submission={submission} />
        ) : (
          <React.Fragment>
            <Fab size="small" variant="extended" className={classes.fab} disableRipple={true}>
              <Checkbox
                checked={showAll}
                onChange={(e) => {this.setState({showAll: e.target.checked})}}
                value="checked"
              />
              Show all components
            </Fab>
            <div style={{height: 25}} />
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.sectionOffset}>Offset</TableCell>
                  <TableCell className={classes.sectionContent}>Extracted Components</TableCell>
                  <TableCell className={classes.sectionAnalysis}>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { contents
                  .map((c, i) => {
                    return <Row key={i} row={c} mode={mode} showAll={showAll} />
                  })
                }
              </TableBody>
            </Table>
          </React.Fragment>
        )}
        <div style={{paddingTop: 50}} />
        </ScrollSpace>
      </Page>
    )
  }
}

// styling
ContentsTab = withStyles((theme) => ({
  cell: {
    padding: 0,
    margin: 0
  },
  sectionOffset: {
    minWidth: 75,
    maxWidth: 100,
    fontSize: '1em',
  },
  sectionContent: {
    maxWidth: 250,
    minWidth: 250,
    fontSize: '1em',
  },
  sectionAnalysis: {
    marginLeft: 20,
    fontSize: '1em',
  },
  fab: {
    position: 'absolute',
    bottom: '30px',
    right: '30px',
    backgroundColor: "#ccc",
    cursor: "initial",
    "&:hover": {
      backgroundColor: "#ccc",
    }
  }
}))(ContentsTab)


// data
ContentsTab = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        image {
          id
          node {
            id
            status
            filesystems {
              id
              offset
              extraction
              extraction_context
              node {
                id
                name
                fstype
              }
            }
            otherContents {
              id
              offset
              extraction
              extraction_context
              node {
                id
                name
                desc
              }
            }
            kernels {
              id
              offset
              extraction
              extraction_context
              node {
                id
                name
                desc
              }
            }
            cryptographicData {
              id
              offset
              extraction_context
              node {
                id
                name
                desc
              }
            }
            encryptedContents {
              id
              offset
              extraction_context
              node {
                id
                name
                desc
              }
            }
            baremetalContents {
              id
              offset
              extraction_context
              node {
                id
                name
                desc
              }
            }
          }
        }
      }
    }
  `,
  variables: (props) => {
    // TODO: This is really submissionId:
    return {input: {id: props.match.params.imageId}}
  },
  onData: (data) => data.Submission[0]
})(ContentsTab)

export default ContentsTab
