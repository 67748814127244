/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import React from 'react';
import {
	FlexibleWidthXYPlot,
	XAxis,
	YAxis,
	VerticalGridLines,
	HorizontalGridLines,
	HorizontalBarSeries,
	DiscreteColorLegend
} from 'react-vis';

const Y_BINS = 'All';
const Y_EXE = 'Executable'
const Y_DYLIB = 'Dynamic Lib';
const Y_SLIB = 'Static Lib';


class BinaryHardeningGraph extends React.Component {
	constructor(props) {
		super(props)
		const stats = props.stats;
		// max X value will be stats.binTotal
		this.seriesAllMitigations = [
			{ y: Y_BINS, x: stats.binAll },
			{ y: Y_EXE, x: stats.eAll },
			{ y: Y_DYLIB, x: stats.dAll },
			{ y: Y_SLIB, x: stats.sAll }
		]
		this.seriesSomeMitigations = [
			{ y: Y_BINS, x: stats.binSome },
			{ y: Y_EXE, x: stats.eSome },
			{ y: Y_DYLIB, x: stats.dSome },
			{ y: Y_SLIB, x: stats.sSome }
		]
		this.seriesNoMitigations = [
			{ y: Y_BINS, x: stats.binTotal - stats.binAll - stats.binSome },
			{ y: Y_EXE, x: stats.eTotal - stats.eAll - stats.eSome },
			{ y: Y_DYLIB, x: stats.dTotal - stats.dAll - stats.dSome },
			{ y: Y_SLIB, x: stats.sTotal - stats.sAll - stats.sSome }
		]
	}

	render() {
		// NOTE: Parent MUST have a position: 'relative' on the style of a div above this to allow for legend layout.
		return (
			<FlexibleWidthXYPlot height={this.props.height - 10}
				yType="ordinal" stackBy="x"
				margin={{ left: 80, right: 10 }} style={{ padding: '5px' }}
			>
				<DiscreteColorLegend
					style={{ position: 'absolute', right: '5px', top: '5px' }}
					orientation="horizontal"
					items={[
						{
							title: 'All Mitigations',
							color: '#008450'
						},
						{
							title: 'Some Mitigations',
							color: '#EFB700'
						},
						{
							title: 'No Mitigations',
							color: '#B81D13'
						}
					]}
				/>
				<VerticalGridLines />
				<HorizontalGridLines />
				<XAxis /*title='Binaries'*/ />
				<YAxis tickLabelAngle={-25} />
				<HorizontalBarSeries data={this.seriesAllMitigations} color='#008450' />
				<HorizontalBarSeries data={this.seriesSomeMitigations} color='#EFB700' />
				<HorizontalBarSeries data={this.seriesNoMitigations} color='#B81D13' />
			</FlexibleWidthXYPlot>
		)
	}
}

export default BinaryHardeningGraph