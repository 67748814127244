import React from 'react'
import { Table } from 'semantic-ui-react'

class SimpleTable extends React.Component {
  render() {
    const { order, fields } = this.props
    return (
      <Table definition collapsing compact>
        <Table.Body>
        {order.map((key) => {
          return (
            <Table.Row key={key}>
              <Table.Cell>{key}</Table.Cell>
              <Table.Cell>{fields[key]}</Table.Cell>
            </Table.Row>
          )
        })}
        </Table.Body>
      </Table>
    )
  }
}

export default SimpleTable
