import React from 'react'
import gql from 'graphql-tag';
import { withGraphqlQuery } from '../../../../../graphql/core/connectors';
import PageSection from '../../../../../components/core/page/PageSection'
import GrantAccess from './GrantAccess';
import RevokeAccess from './RevokeAccess';

class ShareFirmware extends React.Component {
    render() {
        const { client, refetch, owned } = this.props;
        const submission = this.props.data;
        return (
            <PageSection title='Sharing' >
                { (owned) ? (
                  <GrantAccess resourceType='Submission' resourceId={submission.id} after={() => refetch() }/>
                ) : null }
                <div className='column' style={{marginTop: 15, gap: 10}}>
                  {submission.sharedWith.filter(r => r.node).map(r => r.node).map(s => {
                      return (
                          <RevokeAccess 
                              key={s.id}
                              client={client} 
                              after={() => refetch()} 
                              submission={submission} 
                              subject={s}
                              owned={owned}
                          />
                      )
                  })}
                </div>
            </PageSection>
        )
    }
}

ShareFirmware = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        sharedWith {
          node {
            ... on User {
              id
              name
            }
            ... on Group {
              id
              name
              users {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({input: {id: props.submissionId}}),
  onData: (data) => data.Submission[0],
})(ShareFirmware)

export default ShareFirmware
