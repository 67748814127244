import {
  SET_USER_PROFILE,
  SET_USER_TOKEN
} from './actionTypes'

export const setUserProfile = (userProfile) => ({
  type: SET_USER_PROFILE,
  payload: userProfile
})

export const setUserToken = (token) => ({
  type: SET_USER_TOKEN,
  payload: token
})
