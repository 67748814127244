import React from 'react'
import gql from 'graphql-tag'
import { Typography } from '@material-ui/core'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../graphql/core/connectors'
import registerUser from '../../../graphql/functions/registerUser'
import deleteUser from '../../../graphql/functions/deleteUser'
import toggleUserEnabled from '../../../graphql/functions/toggleUserEnabled'
import { withRBAC } from '../../../components/core/access/RBAC'
import Page from '../../../components/core/layout/Page'
import ScrollSpace from '../../../components/core/layout/ScrollSpace'
import toggleDisassembly from '../../../graphql/functions/toggleDisassembly'

class UsersTab extends React.Component {

  state = {
    newUserEmail: ''
  }

  register = async () => {
    await registerUser(this.props.client, this.state.newUserEmail)
    this.setState({newUserEmail: ''})
    this.props.refetch()
  }

  render() {
    //console.log('>>>')
    //console.log(this.props)
    const users = this.props.data
    const { client, refetch } = this.props
    return (
        <Page>
          <h2>Users</h2>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10}}>
            <Typography style={{fontSize: '16px'}}>New User:</Typography>
            <Input onChange={(_, d) => {this.setState({newUserEmail: d.value})}} style={{marginLeft: 10}} />
            <Button color='blue' onClick={this.register} style={{marginLeft: 10, marginRight: 10}}>
              <Icon name='edit' />
              Register
            </Button>
          </div>
          <ScrollSpace plain={true}>
            <Table compact collapsing celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>User</Table.HeaderCell>
                  <Table.HeaderCell>Role</Table.HeaderCell>
                  <Table.HeaderCell>Enabled</Table.HeaderCell>
                  <Table.HeaderCell>Disassembly Enabled</Table.HeaderCell>
                  <Table.HeaderCell>Last Active</Table.HeaderCell>
                  <Table.HeaderCell>Manage</Table.HeaderCell>
                  <Table.HeaderCell>Disassembly</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { users.map((user) => {
                  return (
                    <Table.Row key={user.id}>
                      <Table.Cell>{user.name}</Table.Cell>
                      <Table.Cell>{user.role}</Table.Cell>
                      <Table.Cell>{user.enabled ? 'Yes' : 'No'}</Table.Cell>
                      <Table.Cell> {user.disassemblyEnabled ? 'Yes' : 'No'} </Table.Cell>
                      <Table.Cell>{user.lastActiveDate}</Table.Cell>
                      <Table.Cell>
                        <Button
                          color={user.enabled ? 'orange' : 'green'}
                          onClick={async () => {
                            await toggleUserEnabled(client, user.id)
                            refetch()
                          }}
                        >{user.enabled ? 'Disable' : 'Enable'}</Button>
                        <Button
                          color='red'
                          onClick={async () => {
                            await deleteUser(client, user.id)
                            refetch()
                          }}
                        >Delete</Button>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          color={user.disassemblyEnabled ? 'orange' : 'green'}
                          onClick={async () => {
                            await toggleDisassembly(client, user.name)
                            refetch()
                          }}
                        >{user.disassemblyEnabled ? 'Disable' : 'Enable'}</Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <div style={{marginTop: 50}} />
          </ScrollSpace>
        </Page>
    )
  }
}

// data
UsersTab = withGraphqlQuery({
  query: gql`
    query {
      User {
        id
        name
        role
        enabled
        lastActiveDate
        disassemblyEnabled
      }
    }
  `,
  onData: (data) => data.User
})(UsersTab)

// access control
UsersTab = withRBAC('admin')(UsersTab)

export default UsersTab
