import React from 'react'
import { Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class DataNotFoundError extends React.Component {
  render() {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
        <div>Data not found</div>
        <Button onClick={() => {this.props.history.push('/')}}>Home Page</Button>
      </div>
    )
  }
}

DataNotFoundError = withRouter(DataNotFoundError)

export default DataNotFoundError