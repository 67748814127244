import React from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Icon, Table } from 'semantic-ui-react'
import Markdown from '../../../../components/core/media/Markdown'
import FlexSpace from '../../../../components/core/layout/FlexSpace'
import Cvss from '../../../../components/labels/Cvss'

class CweLink extends React.Component {
  render() {
    const id = this.props.data.split('CWE-')[1]
    const url = `https://cwe.mitre.org/data/definitions/${id}`
    return (
      <a target='_blank' rel='noopener noreferrer' href={`${url}`}>{url}</a>
    )
  }
}

class CveLink extends React.Component {
  render() {
    const id = this.props.data
    const url = `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${id}`
    return (
      <a target='_blank' rel='noopener noreferrer' href={`${url}`}>{url}</a>
    )
  }
}

class FindingReportLayout extends React.Component {
  render() {
    let { classes, type, title, icon, cve, cwe, description, impact, recommendation, Details, overallCvss } = this.props
    if (cve === undefined) { cve = null}
    if (cwe === undefined) { cwe = null}
    if (overallCvss === undefined) { overallCvss = null}
    return (
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.header}>
            <div className={classes.headerIcon}>
              <Icon fitted name={icon} size='large' inverted />
            </div>
            <div className={classes.headerBody}>
              <Typography>{type}</Typography>
              <Typography className={classes.titleText}>{title}</Typography>
            </div>
            <FlexSpace />
            { (overallCvss !== null) ? (<div>
              <Cvss score={overallCvss} />
            </div>) : null }
          </div>
          <Typography className={classes.section}>Description</Typography>
          <Markdown data={description} />
          <Typography className={classes.section}>Impact</Typography>
          <Markdown data={impact} />
          <Typography className={classes.section}>Recommendation</Typography>
          <Markdown data={recommendation} />
          { ((cve !== null) || (cwe !== null)) ? (
            <React.Fragment>
              <Typography className={classes.section}>Vulnerability References</Typography>
              <Table compact collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Reference</Table.HeaderCell>
                    <Table.HeaderCell>Details</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                {(cve !== null) ? (
                  <Table.Row>
                    <Table.Cell>{cve}</Table.Cell>
                    <Table.Cell><CveLink data={cve} /></Table.Cell>
                  </Table.Row>
                ) : null}
                {(cwe !== null) ? (
                  <Table.Row>
                    <Table.Cell>{cwe}</Table.Cell>
                    <Table.Cell><CweLink data={cwe} /></Table.Cell>
                  </Table.Row>
                ) : null}
                </Table.Body>
              </Table>
            </React.Fragment>
          ) : null }
          <div></div>
          <Typography className={classes.section}>Technical Details</Typography>
          {<Details />}
        </div>
        <div className={classes.actions}>
        </div>
      </div>
    )
  }
}

// styling
FindingReportLayout = withStyles((theme) => ({
  root: {
    direction: 'flex',
    flexDirection: 'column',
    padding: 20
  },
  main: {
    direction: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    padding: 5,
    marginRight: 10,
    borderRadius: 5,
    height: 40,
    width: 40
  },
  headerBody: {
    display: 'flex',
    flexDirection: 'column'
  },
  entities: {
    display: 'flex',
    flexDirection: 'row'
  },
  actions: {
    direction: 'flex',
    flexDirection: 'column'
  },
  section: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 10
  },
  titleText: {
    fontSize: 16,
    fontWeight: 600
  }
}))(FindingReportLayout)

export default FindingReportLayout