import React from 'react'
import { withStyles } from '@material-ui/core'

class Row extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>{this.props.children}</div>
    )
  }
}

Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Row)

export default Row
