import React, {useState} from 'react';
import gql from 'graphql-tag';
import { Button, Loader, Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/react-hoc'

let CancelPipelineButton = (props) => {
    const { client, submissionId, onClick } = props;
    const [disabled, setDisabled] = useState(false)
    return (
        <Button 
            disabled={disabled}
            icon={disabled}
            size='tiny' 
            color='red' 
            onClick={() => {
                onClick();
                setDisabled(true);
                client.mutate({
                    mutation: gql`
                        mutation CancelPipeline($input: String) {
                            CancelPipeline(input: $input)
                        }
                    `,
                    variables: {
                        input: submissionId
                    }
                })
            }}
        >
            {(disabled) ? (
                <Loader />
            ): (
                <React.Fragment>
                    <Icon name='cancel'/>
                    Cancel Analysis
                </React.Fragment>
            )}
        </Button>
    )
}

CancelPipelineButton = withApollo(CancelPipelineButton);

export default CancelPipelineButton;
