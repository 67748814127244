import gql from 'graphql-tag'

const TOGGLE_DISASSEMBLY_MUTATION = gql`
mutation ToggleDisassemblyEnabled($input: ToggleDisassemblyEnabledInput) {
    ToggleDisassemblyEnabled(input: $input) 
}`

const toggleDisassembly = async (client, userName) => {
    return await client.mutate({
        mutation: TOGGLE_DISASSEMBLY_MUTATION,
        variables: {input: {userName}}
    })
}

export default toggleDisassembly
