import React from 'react'
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../../../../../graphql/core/connectors';
import SerialConsole from './variants/SerialConsole'
import HardcodedPassword from './variants/HardcodedPassword'

class FindingOsConfiguration extends React.Component {
  render() {
    // TODO: handle empty
    const { data, printStyle } = this.props
    switch (data.title) {
      case 'Hardcoded Password':
      case 'Blank Password':
      case 'User with no password may be used for authentication or privilege escalation':
        return <HardcodedPassword data={data} printStyle={printStyle} />
      case 'Potential Serial Console Exposed':
      case 'Potential Bootloader Serial Console Exposed':
        return <SerialConsole data={data} printStyle={printStyle} />
      default:
        console.error("Unknown OS configuration finding type:", data.title);
        return <div>Unknown OS configuration finding: {data.title}</div>
    }
  }
}

// data
FindingOsConfiguration = withGraphqlQuery({
  query: gql`
    query FindingOsConfiguration($input: FindingOsConfigurationMatchInput) {
      FindingOsConfiguration(input: $input) {
        id
        idCwe
        idCve
        scoreCvss
        title
        description
        impact
        recommendation
        details
        source
        vulnerableEntity {
          id
          node {
            __typename
            ... on FileContents {
              id
              type
              value
            }
            ... on ConfigContents {
              id
              name
              desc
              filecontents {
                node {
                  type
                  value    
                }
              }
            }
            ... on SerialShell {
              id
              program
              shell
              tty
              baud
              isLogin
              launchedBy {
                node {
                  path
                }
              }
            }
          }
        }
      }
    }
  `,
  variables: (props) => {
    return {input: {id: props.id}}
  },
  onData: (data) => data.FindingOsConfiguration[0]
})(FindingOsConfiguration)

export default FindingOsConfiguration