import React from 'react'
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../../../../../graphql/core/connectors';
import GenericVersionFinding from './variants/GenericVersionFinding'

class FindingVulnerableVersion extends React.Component {
  render() {
    const finding = this.props.data
    const printStyle = this.props.printStyle || false;
    return <GenericVersionFinding finding={finding} printStyle={printStyle} />
  }
}

// data
FindingVulnerableVersion = withGraphqlQuery({
  query: gql`
    query FindingVulnerableVersion($input: FindingVulnerableVersionMatchInput) {
      FindingVulnerableVersion(input: $input) {
        id
        idCwe
        idCve
        scoreCvss
        title
        description
        impact
        recommendation
        details
        vulnerableEntity {
          id
          node {
            ... on Executable {
              id
              name
              cpe
              foundIn {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on ImageContents {
                    id
                    name
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                  ... on Kernel {
                    id
                    name
                  }
                }
              }
            }
            ... on ExecutableScript {
              id
              name
              cpe
              foundIn {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on ImageContents {
                    id
                    name
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                  ... on Kernel {
                    id
                    name
                  }
                }
              }
            }
            ... on DynamicLib {
              id
              name
              cpe
              foundIn {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on ImageContents {
                    id
                    name
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                  ... on Kernel {
                    id
                    name
                  }
                }
              }
            }
            ... on StaticLib {
              id
              name
              cpe
              foundIn {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on ImageContents {
                    id
                    name
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                  ... on Kernel {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    } 
  `,
  variables: (props) => {
    return {input: {id: props.id}}
  },
  onData: (data) => data.FindingVulnerableVersion[0]
})(FindingVulnerableVersion)

export default FindingVulnerableVersion