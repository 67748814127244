import React from 'react'
import FileSystemSelector from '../../../../components/wrappers/FileSystemSelector'
import Page from '../../../../components/core/layout/Page'
import StartupDisplay from './StartupDisplay'
import EmulationDisplay from './EmulationDisplay'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import { Tab } from 'semantic-ui-react'
import BootloaderInfoDisplay from './BootloaderInfoDisplay'
import ConfigurationData from './ConfigurationData'


class StartupTab extends React.Component {
  render() {
    return (
      <Page>
        <ScrollSpace plain>
          <Tab panes={[
            { 
              menuItem: 'Startup Summary', 
              render: () => (
                <Tab.Pane>
                  <FileSystemSelector submissionId={this.props.match.params.imageId} showOnlyTypes={['File System']}>
                    {({containerId}) => {
                      return (<StartupDisplay filesystemId={containerId} />)
                    }}
                  </FileSystemSelector>
                </Tab.Pane> 
              )
            },
            { 
              menuItem: 'Emulation Log', 
              render: () => (
                <Tab.Pane>
                  <FileSystemSelector submissionId={this.props.match.params.imageId} showOnlyTypes={['File System']}>
                    {({containerId}) => {
                      return (<EmulationDisplay filesystemId={containerId} />)
                    }}
                  </FileSystemSelector>
                </Tab.Pane>
              )
            },
            { 
              menuItem: 'Bootloader Information', 
              render: () => (
                <Tab.Pane>
                  <BootloaderInfoDisplay submissionId={this.props.match.params.imageId} />
                </Tab.Pane>
              )
            },
            { 
              menuItem: 'NVRAM Data', 
              render: () => (
                <Tab.Pane>
                  <ConfigurationData submissionId={this.props.match.params.imageId} />
                </Tab.Pane>
              )
            }
          ]}/>
        </ScrollSpace>
      </Page>
    )
  }
}

export default StartupTab
