import React from 'react'
import gql from 'graphql-tag'
import { Typography, withStyles } from '@material-ui/core'
import { Table } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import SimpleTable from '../../../../components/core/tables/SimpleTable'
import Cvss from '../../../../components/labels/Cvss'
import { toChmodStr } from '../../../../utils/format'
import { shortSha256 } from '../../../../utils/format'


class StaticLibraryDisplay extends React.Component {

  state = {
    analyzeMsg: ''
  }

  render() {
    const executable = this.props.data;
    const { classes, /*containerType, containerId*/ } = this.props;
    const foundInType = executable.foundIn.node.__typename

    // TODO: Show a warning if the disassembly processor hasn't yet finished, as many things in this view depend on it.
    //       Before it is finished, things here will show incorrect data just due to the absence of data.

    return (
      <div className={classes.root}>
        <Typography className={classes.title}>
          {executable.name}
        </Typography>
        <div>
          <SimpleTable 
            order={['Type', 'Version', 'Date']}
            fields={{
              'Type': 'Static Library',
              'Version': executable.version,
              'Date': executable.date
            }}
          />
        </div>

        <Typography className={classes.section}>
          Contained In
        </Typography>
        <div>
          { (foundInType === 'File') ? (<SimpleTable 
            order={['Path', 'Type', 'SHA256', 'Size']}
            fields={{
              'Path': executable.foundIn.node.path,
              'Type': executable.foundIn.node.magic,
              // TODO: Use Reveal or copy button to allow showing entire hash:
              'SHA256': shortSha256(executable.foundIn.node.sha256),
              'Size': `${executable.foundIn.node.size} bytes`,
              'UID': executable.foundIn.node.uid,
              'GID': executable.foundIn.node.gid,
              'Permissions': toChmodStr(executable.foundIn.node.mode)
            }}
          />) : (<SimpleTable 
            order={['Type', 'Description']}
            fields={{
              'Type': foundInType,
              'Description': executable.foundIn.node.desc
            }}
          />)}
        </div>
        
        <Typography className={classes.section}>Findings</Typography>
        { executable.associatedFindings.length === 0 ? <div>No findings for this library have been identified.</div> : (
          <Table compact collapsing celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell style={{width: '50px', minWidth: '50px'}}>Severity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            { executable.associatedFindings.map(r => r.node).map((finding) => {
                return (
                  <Table.Row key={finding.id}>
                    <Table.Cell>{finding.title}</Table.Cell>
                    <Table.Cell><Cvss score={finding.scoreCvss} /></Table.Cell>
                  </Table.Row>
                )
            })}
            </Table.Body>
          </Table>
        )}
        {/*
        // TODO: Enable binary analysis button
        <Typography className={classes.section}>Binary Analysis</Typography>
        <BinaryAnalysis 
          executable={executable}
          setAnalyzeMsg={(msg) => {this.setState({analyzeMsg: msg})}}
          analyzeMsg={analyzeMsg}
          client={client}
          startPolling={startPolling}
          binaryType='DynamicLib'
        /> 
        */}     

        <div style={{marginBottom: '50px'}} />
      </div>
    )
  }
}

// style
StaticLibraryDisplay = withStyles((theme) => ({
  root: {
    display: 'flex', 
    flexDirection: 'column', 
    paddingTop: '20px',
    paddingLeft: '20px', 
    paddingRight: '20px',
    paddingBottom: '50px',
    height: 'calc(100%)',
    maxWidth: '100%'
  },
  title: {
    fontSize: 26,
    fontWeight: 800,
    marginBottom: '10px'
  },
  section: {
    fontSize: 20,
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 10
  },
}))(StaticLibraryDisplay)

// data
StaticLibraryDisplay = withGraphqlQuery({
  query: gql`
    query StaticLib($input: StaticLibMatchInput) {
      StaticLib(input: $input) {
        id
        name
        version
        date
        foundIn {
          id
          node {
            __typename
            ... on File {
              id
              path
              magic
              size
              uid
              gid
              sha256
              mode
            }
            ... on ImageContents {
              id
              name
              desc
            }
            ... on BaremetalContents {
              id
              name
              desc
            }
            ... on ConfigContents {
              id
              name
              desc
            }
            ... on Kernel {
              id
              name
              desc
            }
          }
        }
        associatedFindings {
          id
          node {
            ... on FindingGeneric {
              id
              title
              scoreCvss
            }
            ... on FindingVulnerableVersion {
              id
              title
              scoreCvss
            }
            ... on FindingCryptographicKey {
              id
              title
              scoreCvss
            }
            ... on FindingVulnerableFunctionCall {
              id
              title
              scoreCvss
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.libraryId}
  }),
  onData: (data) => data.StaticLib[0]
})(StaticLibraryDisplay)

export default StaticLibraryDisplay
