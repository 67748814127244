import React from 'react'
import FileSystemSelector from '../../../../components/wrappers/FileSystemSelector'
import Page from '../../../../components/core/layout/Page'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import VerticalSpace from '../../../../components/core/layout/VerticalSpace'
import PageSplit from '../../../../components/core/layout/PageSplit'
import NullSelection from '../../../../components/placeholders/NullSelection'
import FilesystemBrowser from './FilesystemBrowser'
import FileDetails from './FileDetails'

class FilesystemsTab extends React.Component {

  state = {
    fileId: null
  }

  setFileId = (fileId) => {
    this.setState({fileId})
  }

  render() {
    return (
      <Page>
        <FileSystemSelector submissionId={this.props.match.params.imageId} showOnlyTypes={['File System']}>
          {({containerId}) => {
            return (
              <PageSplit>
                <ScrollSpace>
                  <FilesystemBrowser 
                    filesystemId={containerId} 
                    setFileId={this.setFileId}
                  />
                </ScrollSpace>
                <VerticalSpace />
                <ScrollSpace>
                  { (this.state.fileId !== null) ? (
                    <FileDetails fileId={this.state.fileId} />
                  ) : (
                    <NullSelection />
                  )}
                </ScrollSpace>
              </PageSplit>
            )
          }}
        </FileSystemSelector>
      </Page>
    )
  }
}

export default FilesystemsTab
