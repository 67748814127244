import React from 'react'
import FindingLayout from '../../../FindingLayout'
import Markdown from '../../../../../../../components/core/media/Markdown'
import { Table } from 'semantic-ui-react'
import { PrintableCpeLinks } from '../../../FindingsPrintable';


class GenericFunctionFinding extends React.Component {
  render() {
    const { finding, printStyle } = this.props
    // NOTE: This is a hack to handle newlines ruining our details tables in markdown.
    //       As details should just be a table, no newlines are expected.
    const modifiedDetails = finding.details.replace(/([^|])[\n\r]\s*([^|])/gm, '$1 ; $2');
    const vulnerableEntities = finding.vulnerableEntity.map(r => r.node);
    return (
      <FindingLayout
        type='Vulnerable Version' 
        icon='code branch'
        title={finding.title}
        overallCvss={(printStyle === true) ? finding.scoreCvss : null}
        description={finding.description}
        impact={finding.impact}
        recommendation={finding.recommendation}
        Details={() => (
          <div>
            <div>Vulnerable Entities:</div>
            <Table compact collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Found In</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { vulnerableEntities.map((ve) => {
                  return (
                    <Table.Row key={ve.id}>
                      <Table.Cell>{ve.__typename}</Table.Cell>
                      <Table.Cell>{ve.foundIn.node.path || ve.foundIn.node.name}</Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <div>Vulnerabilities:</div>
            { (printStyle) ? <PrintableCpeLinks vulnerableEntities={vulnerableEntities} /> : <Markdown data={modifiedDetails} />}
          </div>
        )}
        Entities={null}
      />
    )
  }
}

export default GenericFunctionFinding