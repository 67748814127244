import React from 'react'
import gql from 'graphql-tag'
import { Message } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { formatHex } from '../../../../utils/format'
import JSONPretty from 'react-json-pretty';
// TODO consider theming JSON pretty:
//import 'react-json-pretty/themes/monikai.css';


class ConfigurationData extends React.Component {
  constructor(props) {
    super()
    // filter to only 'bcm nvram' data, else set this.bootloaderInfo to null
    // if there were sections but they had no data, set it to an empty list
    const imageData = props.data.image.node
    let bcmNvramRows = null
    if (imageData.configContents.length > 0) {
      const nvramContents = imageData.configContents.filter(cc => cc.node.name === 'bcm nvram')
      if (nvramContents.length > 0) {
        nvramContents.sort((a, b) => a.offset - b.offset);
        bcmNvramRows = imageData.configContents.filter(cc => cc.node.filecontents.length > 0)
      }
    }
    if (bcmNvramRows === null || bcmNvramRows.length === 0) {
      // if no data, set so we know that when we render
      this.bcmNvramData = bcmNvramRows
    } else {
      // now reorganize the data so it's region, key, value
      this.bcmNvramData = {}
      bcmNvramRows.forEach((cc) => {
        cc.node.filecontents.forEach((fcRel) => {
          const typeSplit = fcRel.node.type.split('/')
          if (this.bcmNvramData[typeSplit[0]] === undefined) {
            this.bcmNvramData[typeSplit[0]] = []
          }
          this.bcmNvramData[typeSplit[0]].push({
            'key': typeSplit.slice(1).join('/'),
            'value': fcRel.node.value,
            'valueType': (fcRel.node.value.length > 2 && fcRel.node.value.startsWith('{') && fcRel.node.value.endsWith('}')) ? 'json' : 'str',
            'regionOffset': cc.offset  // also have cc.node.desc
          })
        });
      });
    }
  }

  render() {
    if (this.bcmNvramData === null) {
      return (
        <Message>
          <Message.Header>No NVRAM configuration data was found in this firmware</Message.Header>
          <p>
            This will happen if the submitted firmware does not contain NVRAM contents.
            This is usually not included in the firmware image, and instead must be obtained by dumping the memory of the flash chip.
          </p>
          <p>
            If you believe the firmware is from a device which uses NVRAM specific regions, and is a flash dump which includes the NVRAM regions, please contact us for assistance.
          </p>
        </Message>
      )
    }
    if (this.bcmNvramData.length === 0) {
      return (
        <Message>
          <Message.Header>No NVRAM configuration data was found in this firmware</Message.Header>
          <p>
            However, potential NVRAM configuration regions were identified.
            This may happen if the submitted image was not from a device which uses NVRAM specific regions, or if the submitted firmware was not obtained by dumping the memory of a flash chip from a used device.
          </p>
          <p>
            If you believe the firmware is from a device which uses NVRAM specific regions, and is a flash dump which includes the NVRAM regions, please contact us for assistance.
          </p>
        </Message>
      )
    }
    
    return (
      <Table size="small">
        <TableBody>
        {Object.entries(this.bcmNvramData).map(([section, sectionData]) => {
          // Sort so same keys are next to eachother (often will see repeats from different NVRAM sections):
          //console.log('BEFORE', sectionData)
          sectionData.sort((a, b) => ((a.key < b.key) ? -1 : ((a.key > b.key) ? 1 : 0)))
          //console.log('AFTER ', sectionData.map((sd) => sd.key))
          return (
            <React.Fragment key={section}>
              <TableRow>
                <TableCell colSpan={3} component="th" style={{backgroundColor: '#2d2f3d', color: 'white'}}>{section}</TableCell>
              </TableRow>
              {
                sectionData.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{item.key}</TableCell>
                      {(item.valueType !== 'json') ? (
                        <TableCell style={{ wordWrap: 'break-word', maxWidth: '800px', fontFamily: 'monospace' }}>
                          {item.value}
                        </TableCell>
                      ) : (
                        <TableCell>
                          <JSONPretty data={item.value} onJSONPrettyError={e => console.error(e)}></JSONPretty>
                        </TableCell>
                      )}
                      <TableCell>{formatHex(item.regionOffset)}</TableCell>
                    </TableRow>
                  )
                })
              }
            </React.Fragment>
          )
        })}
        </TableBody>
      </Table>
    )
  }
}

// data
// NOTE: this will return both 'u-boot config section' data which we don't want to show here,
//       as well as our 'bcm nvram' names that we do want to display
ConfigurationData = withGraphqlQuery({
  query: gql`
  query Submission($input: SubmissionMatchInput) {
    Submission(input: $input) {
      id
      image {
        id
        node {
          id
          status
          configContents {
            id
            offset
            node {
              id
              name
              desc
              filecontents {
                id
                node {
                  id
                  type
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  `,
  variables: (props) => ({
    input: {id: props.submissionId}
  }),
  onData: (data) => data.Submission[0]
})(ConfigurationData)

export default ConfigurationData
