import React from 'react'

class PageToolbar extends React.Component {
  render() {
    return (
      <div style={{ 
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        alignItems: 'center'
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default PageToolbar
