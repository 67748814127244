import React from 'react';
import { Divider } from 'semantic-ui-react';
import StatusLabel from '../../../components/labels/StatusLabel'
import SidebarMenu from '../../../components/core/layout/SidebarMenu'
import { adminFilter } from '../../../utils/access'

const analysisFinishedFilter = (analysisTerminated) => {
  return (route) => {
    if (!analysisTerminated) {
      return (route.showDuringAnalysisRunning === true)
    }
    return true;
  }
};

const getMenuItems = (submission, userProfile, analysisTerminated) => {
    return (
    [
      {
        type: 'item',
        link: `/firmware/${submission.id}/summary`,
        title: 'Summary',
        icon: 'home',
        showDuringAnalysisRunning: true
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/findings`,
        title: 'Findings',
        icon: 'bug',
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/contents`,
        title: 'Contents',
        icon: 'archive'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/software-composition`,
        title: 'Software Composition',
        icon: 'list alternate'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/binary-hardening`,
        title: 'Binary Hardening',
        icon: 'lock'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/executables`,
        title: 'Executables',
        icon: 'cube'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/executable-scripts`,
        title: 'Executable Scripts',
        icon: 'file code'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/libraries`,
        title: 'Libraries',
        icon: 'code'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/startup`,
        title: 'Startup',
        icon: 'power'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/network`,
        title: 'Network',
        icon: 'globe'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/certificates`,
        title: 'Certificates & Keys',
        icon: 'address card'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/filesystems`,
        title: 'Filesystems',
        icon: 'folder'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/strings`,
        title: 'Strings',
        icon: 'at'
      },
      {
        type: 'item',
        link: `/firmware/${submission.id}/tasks`,
        title: 'Tasks',
        icon: 'cogs',
        admin: true,
        showDuringAnalysisRunning: true
      }
    ]
    .filter(adminFilter(userProfile))
    .filter(analysisFinishedFilter(analysisTerminated)))
}

let Sidebar = (props) => {
    const submission = props.data;
    const { userProfile, analysisTerminated } = props;
    const disabled = (submission.image.node.status === 'NOT SUPPORTED');
    const menuItems = getMenuItems(submission, userProfile, analysisTerminated);
    return (
        <div style={{background: '#F0F0F0', height: '100%', overflowY: 'scroll', borderRightColor: '#F0F0F0', width: 270}}>
            <div style={{padding: 10}}>
                <div style={{fontSize: 20, fontWeight: 550}}>{submission.productName}</div>
                <div style={{fontSize: 12, fontColor:'gray'}}>Version: {submission.productVersion}</div>
                <StatusLabel text={submission.image.node.status} />
            </div>
            <Divider />
            {(!disabled) ? <SidebarMenu items={menuItems} /> : null }
        </div>
    )
}

export default Sidebar;