import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  content: {
    height: '100%',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  }
})

class ViewContent extends React.Component {
  render() {
    const { classes, children } = this.props
    return (
      <div className={classes.content}>
        {children}
      </div>
    )
  }
}

ViewContent = withStyles(styles)(ViewContent)

export default ViewContent 
