import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

class PageSection extends React.Component {
  render() {
    const { classes, title, linkUrl, printableBreakBefore } = this.props
    const divStyle = {
      paddingBottom: 30
    }
    if (printableBreakBefore) {
      divStyle['breakBefore'] = 'page'
    }
    return (
      <div className={classes.root} style={divStyle}>
        <div>
          <div className={classes.main}>
            <Typography variant='h5' className={classes.textTitle}>{title}</Typography>
            {(linkUrl !== undefined) ? (
              <Link to={linkUrl}>
                <Typography variant='h5' className={classes.textLink}>[ View All ]</Typography>
              </Link>
            ) : null}
          </div>
          <div style={{height: 5}} />
          <Divider />
          <div style={{height: 15}} />
          {this.props.children}
        </div>
      </div>
    )
  }
}

// types


// styling
PageSection = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textTitle: {
    fontSize: 24
  },
  textLink: {
    fontSize: 14,
    paddingLeft: 10
  }
}))(PageSection)

export default PageSection