import React from 'react'
import { Table, Divider } from 'semantic-ui-react'
import FindingLayout from '../../../FindingLayout'
import EntityLabel from '../../../../../../../components/labels/EntityLabel';

const hashTypeMap = {
  '1': {name: "MD5", secure: false},
  '2': {name: "Blowfish", secure: false},  // TODO: Set blowfish variants and security
  '2a': {name: "Blowfish", secure: false},
  '2y': {name: "Blowfish", secure: false},
  '5': {name: "SHA-256", secure: false},
  '6': {name: "SHA-512", secure: true}
};

const notRealHashes = ['x', '*', '', '!', '!!'];

const parsePasswdEntry = (entry) => {
  // TODO: Put root account first in table!
  const entryFields = entry.value.split(':')
  let rowData = [entryFields[0], 'Unknown', 'Unknown', 'N/A', 'N/A', '']
  const hashFields = entryFields[1].split('$'); //first field is empty string, then the 3 expected parts
  
  // Handling for when passwords are $ separated (new style format):
  if (hashFields.length === 4) {
    const hashType = hashTypeMap[hashFields[1]];
    rowData[1] = hashType.secure ? 'Yes' : 'No'
    rowData[2] = hashType.name
    rowData[3] = hashFields[3]
    if (hashFields[2] !== '') {
      rowData[4] = hashFields[2]
    }

  // Handling for crypt() style passwords:
  } else if (entryFields[1] !== undefined && entryFields[1].length === 13 && entryFields[1][0] !== '$') {
    rowData[1] = 'No'
    rowData[2] = 'crypt()'
    rowData[3] = entryFields[1].substr(2)  // remainder is the hash
    rowData[4] = entryFields[1].substr(0, 2)  // first 2 chars are the salt

  // No known password format found, either no login or some unknown format.
  } else {
    if (entryFields[1] === '!' || entryFields[1] === '!!') {
      rowData[1] = 'N/A'
      rowData[2] = 'No login.'
    } else if (entryFields[1] === '') {
      rowData[1] = 'N/A';
      rowData[2] = 'N/A';
      rowData[3] = '';
      rowData[4] = '';
    } else if (notRealHashes.includes(entryFields[1])) {
      // Case for Blank Password
      rowData[1] = 'N/A';
      rowData[2] = 'N/A';
    } else {
      rowData[2] = 'Needs further analysis.'
    }
    if (entryFields[1] !== '') {
      rowData[3] = entryFields[1]
    }
  }
  if (entryFields.length >= 7) {
    rowData[5] = entryFields[6]; // include shell into table
  }

  return rowData
}

/*
 * This function filters a list of objects representing the nodes for shadow and password entries,
 * and filters it to remove duplicates (e.g., a passwd entry when the hash is in the shadow entry).
 */
function filterToMeaningfulPasswdEntries(passwdEntries) {
  const lookup = {}; // index by acct name
  for (let entry of passwdEntries) {
    const entryFields = entry.value.split(':')  //[0] username, [1] hash
    const acctName = entryFields[0];
    console.log('filterToMeaningfulPasswdEntries', acctName, entryFields[1], entry);
    if (lookup[acctName] === undefined) {
      // Store it if it wasn't seen before.
      lookup[acctName] = entry;
    } else {
      // If already there, we only overwrite it if we have a hash and it doesn't.
      const existingHash = lookup[acctName].value.split(':')[1]
      if (notRealHashes.includes(existingHash) && !notRealHashes.includes(entryFields[1])) {
        lookup[acctName] = entry;
      }
    }
  }
  return Object.values(lookup);
}

class HardcodedPassword extends React.Component {
  render() {
    const finding = this.props.data
    let passwdEntries = finding.vulnerableEntity.map(r => r.node)
    passwdEntries = filterToMeaningfulPasswdEntries(passwdEntries);
    const printStyle = this.props.printStyle || false;
    return (
      <FindingLayout
        type='OS Configuration' 
        icon='wrench'
        title={finding.title}
        overallCvss={(printStyle === true) ? finding.scoreCvss : null}
        description={finding.description}
        impact={finding.impact}
        recommendation={finding.recommendation}     
        cve={finding.idCve}
        cwe={finding.idCwe}
        Details={() => (
          <div>
            <div>Password File:</div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 5, marginTop: '10px', alignItems: 'start'}}>
              { finding.source.map((source, i) => {return (<EntityLabel typename='File' details={source} key={i} />)}) }
              <div style={{flex: '1'}} />
            </div>
            <Divider hidden />
            <div>Accounts of interest are:</div>
            <Table compact collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Account Name</Table.HeaderCell>
                  <Table.HeaderCell>Secure Hash?</Table.HeaderCell>
                  <Table.HeaderCell>Hash Type</Table.HeaderCell>
                  <Table.HeaderCell>Hash</Table.HeaderCell>
                  <Table.HeaderCell>Salt</Table.HeaderCell>
                  <Table.HeaderCell>Login Shell</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              { passwdEntries.map((entry) => {
                // At this point, filterToMeaningfulPasswdEntries already ran, so if we don't have a hash,
                // then no shadow/passwd entry we saw should have a hash, otherwise it would have been selected
                // as the one to remain in the list:
                const row = parsePasswdEntry(entry)
                if (row === null) {
                  // To allow parsePasswdEntry to return null to suppress a row.
                  return null
                }
                return (
                  <Table.Row key={entry.id} >
                    <Table.Cell style={{fontFamily: 'monospace'}}>{row[0]}</Table.Cell>
                    <Table.Cell>{row[1]}</Table.Cell>
                    <Table.Cell>{row[2]}</Table.Cell>
                    <Table.Cell style={{fontFamily: 'monospace'}}>{row[3]}</Table.Cell>
                    <Table.Cell style={{fontFamily: 'monospace'}}>{row[4]}</Table.Cell>
                    <Table.Cell style={{fontFamily: 'monospace'}}>{row[5]}</Table.Cell>
                  </Table.Row>
                )
              })}
              </Table.Body>
            </Table>
          </div>
        )}
      />
    )
  }
}

export default HardcodedPassword
