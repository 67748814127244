/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Popper from '@material-ui/core/Popper';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import Paper from '@material-ui/core/Paper';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { showFeatureStatusIcon, getIconForLibraryType,
	DESC_NX, DESC_PIC, DESC_RELRO, DESC_CANARY, DESC_STRIPPED } from '../binary-hardening/utils'

class TableCellPopover extends React.Component {
	render() {
		const { headerText, hoverInfo } = this.props;
		return (<PopupState variant="popover">
			{(popupState) => (
				<React.Fragment>
					<TableCell align="right" {...bindHover(popupState)}>
						{headerText}
						<HelpOutlineIcon fontSize='small' />
					</TableCell>
					<Popper
						{...bindPopper(popupState)}
						style={{
							width: '100%',
							maxWidth: '300px'
						}}
						placement='bottom-start'
					>
						<Paper style={{ padding: '10px', backgroundColor: "#ddd" }} elevation={10}>
							{hoverInfo}
						</Paper>
					</Popper>
				</React.Fragment>
			)}
		</PopupState>)
	}
}

class BinaryHardeningTable extends React.Component {
	constructor(props) {
		super()
		this.state = {
			column: null,
			direction: null,
		}
	}

	render() {
		//const { column, direction } = this.state
		const { pathFilter, typeFilter } = this.props
		let rows = this.props.rows;
		if (pathFilter !== undefined && pathFilter !== '') {
			rows = rows.filter(f => (f.path.toLowerCase().includes(pathFilter.toLowerCase())))
		}
		if (typeFilter !== undefined && typeFilter.length !== 0) {
			rows = rows.filter(f => (typeFilter.includes(f.binaryNodeType)))
		}

		// TODO: Add Foritfy once in DB
		return (
			<TableContainer style={{ maxHeight: 'calc(100% - 200px)' }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Binary</TableCell>
							<TableCell>Path</TableCell>
							<TableCellPopover headerText='NX' hoverInfo={DESC_NX} />
							<TableCellPopover headerText='PIC' hoverInfo={DESC_PIC} />
							<TableCellPopover headerText='Canary' hoverInfo={DESC_CANARY} />
							<TableCellPopover headerText='RELRO' hoverInfo={DESC_RELRO} />
							<TableCellPopover headerText='Stripped' hoverInfo={DESC_STRIPPED} />
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, i) => (
							<TableRow key={i}>
								<TableCell>
									{getIconForLibraryType(row.binaryNodeType)}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
								<TableCell>{row.path || null}</TableCell>
								<TableCell align="right">{showFeatureStatusIcon(row.hardeningNx)}</TableCell>
								<TableCell align="right">{showFeatureStatusIcon(row.hardeningPic)}</TableCell>
								<TableCell align="right">{showFeatureStatusIcon(row.hardeningCanary)}</TableCell>
								<TableCell align="right">{showFeatureStatusIcon(row.hardeningRelro)}</TableCell>
								<TableCell align="right">{showFeatureStatusIcon(row.hardeningStripped)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}
}

export default BinaryHardeningTable