import React from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'

function DeleteGroup(props) {
    const { groupId } = props;
    const [open, setOpen] = React.useState(false)
    return (
        <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button color='red'><Icon name='trash'/>Delete</Button>}
        >
        <Modal.Header>Delete Group</Modal.Header>
        <Modal.Content image>
            <Modal.Description>
            <p>Are you sure you want to delete:</p>
            <pre>Group: {groupId}</pre>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setOpen(false)}>
            Cancel
            </Button>
            <Button
                color='red'
                content="Confirm"
                labelPosition='right'
                icon='trash'
                onClick={() => {
                    setOpen(false);
                    props.onDelete();
                }}
            />
        </Modal.Actions>
        </Modal>
    )
}

export default DeleteGroup
