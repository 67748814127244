import gql from 'graphql-tag'

const DELETE_PIPELINE_MUTATION = gql`
mutation DeletePipeline($input: DeletePipelineInput) {
    DeletePipeline(input: $input) 
}
`

const deletePipeline =  async (client, submissionId) => {
  console.log(`delete pipeline ${submissionId}`);
  const response = await client.mutate({
    mutation: DELETE_PIPELINE_MUTATION,
    variables: {input: {submissionId}}
  })
  //console.log(response)
  return response
}

export default deletePipeline;