import React from 'react'
import gql from 'graphql-tag'
import { Typography, withStyles } from '@material-ui/core'
import { Table } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import SimpleTable from '../../../../components/core/tables/SimpleTable'
import Cvss from '../../../../components/labels/Cvss'
import { toChmodStr } from '../../../../utils/format'
import ReachabilityGraph from './ReachabilityGraph'
import BinaryAnalysis from '../../../../components/composite/BinaryAnalysis'
import { shortSha256 } from '../../../../utils/format'
import { hardeningAsListFromNode, showFeatureStatusIcon } from '../binary-hardening/utils'

class DynamicLibraryDisplay extends React.Component {
  render() {
    const executable = this.props.data;
    const { classes, client, startPolling } = this.props;
    const foundInType = executable.foundIn.node.__typename

    // TODO: Show a warning if the disassembly processor hasn't yet finished, as many things in this view depend on it.
    //       Before it is finished, things here will show incorrect data just due to the absence of data.

    return (
      <div className={classes.root}>
        <Typography className={classes.title}>
          {executable.name}
        </Typography>
        <div>
        { (foundInType === 'File') ? (<SimpleTable 
            order={['Type', 'Path', 'Description', 'Version', 'Date', 'SHA256', 'Size']}
            fields={{
              'Type': 'Dynamic Library',
              'Path': executable.foundIn.node.path,
              'Description': executable.foundIn.node.magic,
              // TODO: Use Reveal or copy button to allow showing entire hash:
              'SHA256': shortSha256(executable.foundIn.node.sha256),
              'Size': `${executable.foundIn.node.size} bytes`,
              'UID': executable.foundIn.uid,
              'GID': executable.foundIn.gid,
              'Permissions': toChmodStr(executable.foundIn.mode),
              'Version': executable.version,
              'Date': executable.date
            }}
          />) : (<SimpleTable 
            order={['Type', 'Description']}
            fields={{
              'Type': foundInType,
              'Description': executable.foundIn.node.desc
            }}
          />)}
        </div>
        
        <Typography className={classes.section}>Findings</Typography>
        { executable.associatedFindings.length === 0 ? <div>No findings for this library have been identified.</div> : (
          <Table compact collapsing celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell style={{width: '50px', minWidth: '50px'}}>Severity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            { executable.associatedFindings.map(r => r.node).map((finding) => {
                return (
                  <Table.Row key={finding.id}>
                    <Table.Cell>{finding.title}</Table.Cell>
                    <Table.Cell><Cvss score={finding.scoreCvss} /></Table.Cell>
                  </Table.Row>
                )
            })}
            </Table.Body>
          </Table>
        )}

        <Typography className={classes.section}>Hardening</Typography>
        <div>
          <Table compact collapsing celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Feature</Table.HeaderCell>
                <Table.HeaderCell>Enabled</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { hardeningAsListFromNode(executable).map((feature) => {
                return (
                  <Table.Row key={feature.name}>
                    <Table.Cell>{feature.name}</Table.Cell>
                    <Table.Cell>
                      {showFeatureStatusIcon(feature.enabled)}
                    </Table.Cell>
                    <Table.Cell>{feature.description}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </div>
        
        <Typography className={classes.section}>Dynamic Libraries</Typography>
        { (executable.dynamicLibs.length === 0) ? (<div>This library does not load other dynamic libraries.</div>) : (
          <div>
            <div>This library loads the following libraries dynamically.</div>
            <Table compact collapsing celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Library</Table.HeaderCell>
                  <Table.HeaderCell>Loaded As</Table.HeaderCell>
                  <Table.HeaderCell>Path</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              {executable.dynamicLibs.map((lib) => {
                return (
                  <Table.Row key={lib.node.id}>
                    <Table.Cell>{lib.node.name}</Table.Cell>
                    <Table.Cell>{lib.loadName}</Table.Cell>
                    <Table.Cell>{(lib.node.foundIn.node.path === undefined) ? lib.node.foundIn.node.path : lib.node.foundIn.node.name}</Table.Cell>
                  </Table.Row>
                )
              })}
              </Table.Body>
            </Table>
          </div>
        )}
        
        <Typography className={classes.section}>Static Libraries</Typography>
        { (executable.staticLibs.length === 0) ? (<div>This library does not have statically linked components within it.</div>) : (
          <div>
            <div>This library contains the following statically linked components.</div>
            <Table compact collapsing celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Library</Table.HeaderCell>
                  <Table.HeaderCell>Path</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              {executable.staticLibs.map((lib) => {
                return (
                  <Table.Row key={lib.node.id}>
                    <Table.Cell>{lib.node.name}</Table.Cell>
                    <Table.Cell>{(lib.node.foundIn.node.path === undefined) ? lib.node.foundIn.node.path : lib.node.foundIn.node.name}</Table.Cell>
                  </Table.Row>
                )
              })}
              </Table.Body>
            </Table>
          </div>
        )}

        { (this.props.containerType === 'File System') ? (
          <React.Fragment>
            <Typography className={classes.section}>Library Usage Graph</Typography>
            <ReachabilityGraph key={`${this.props.containerId}/${this.props.libraryId}`}
              fsId={this.props.containerId}
              binaryId={this.props.libraryId} 
              binaryType='DynamicLib'
            />
          </React.Fragment> ) : null }

        { (executable.location.filter(r => (r.type === 'BNDB')).length > 0) ? (
        <React.Fragment>
          <Typography className={classes.section}>Advanced Actions</Typography>
          <BinaryAnalysis
            key={executable.id}
            executable={executable}
            client={client}
            startPolling={startPolling}
            binaryType='DynamicLib'
          />
        </React.Fragment>) : null }

        <div style={{marginBottom: '50px'}} />
      </div>
    )
  }
}

// style
DynamicLibraryDisplay = withStyles((theme) => ({
  root: {
    display: 'flex', 
    flexDirection: 'column', 
    paddingTop: '20px',
    paddingLeft: '20px', 
    paddingRight: '20px',
    paddingBottom: '50px',
    height: 'calc(100%)',
    maxWidth: '100%'
  },
  title: {
    fontSize: 26,
    fontWeight: 800,
    marginBottom: '10px'
  },
  section: {
    fontSize: 20,
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 10
  },
}))(DynamicLibraryDisplay)

// data
DynamicLibraryDisplay = withGraphqlQuery({
  query: gql`
    query DynamicLib($input: DynamicLibMatchInput) {
      DynamicLib(input: $input) {
        id
        name
        version
        date
        hardeningNx
        hardeningPic
        hardeningRelro
        hardeningCanary
        hardeningStripped
        foundIn {
          id
          node {
            __typename
            ... on File {
              id
              path
              magic
              size
              uid
              gid
              sha256
              mode
            }
            ... on ImageContents {
              id
              name
              desc
            }
            ... on BaremetalContents {
              id
              name
              desc
            }
            ... on Kernel {
              id
              name
              desc
            }
          }
        }
        staticLibs {
          node {
            id
            name
            foundIn {
              id
              node {
                __typename
                ... on File {
                  id
                  path
                }
                ... on ImageContents {
                  id
                  name
                }
                ... on BaremetalContents {
                  id
                  name
                }
                ... on Kernel {
                  id
                  name
                }
              }
            }
          }
        }
        dynamicLibs {
          id
          loadName
          node {
            id
            name
            foundIn {
              id
              node {
                __typename
                ... on File {
                  id
                  path
                }
                ... on ImageContents {
                  id
                  name
                }
                ... on BaremetalContents {
                  id
                  name
                }
                ... on Kernel {
                  id
                  name
                }
              }
            }
          }
        }
        location {
          id
          type
        }
        analysisTask {
          id
          node {
            id
            status
          }
        }
        associatedFindings {
          id
          node {
            ... on FindingGeneric {
              id
              title
              scoreCvss
            }
            ... on FindingVulnerableVersion {
              id
              title
              scoreCvss
            }
            ... on FindingCryptographicKey {
              id
              title
              scoreCvss
            }
            ... on FindingVulnerableFunctionCall {
              id
              title
              scoreCvss
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.libraryId}
  }),
  onData: (data) => data.DynamicLib[0]
})(DynamicLibraryDisplay)

export default DynamicLibraryDisplay
