import React from 'react';
import { Divider } from '@material-ui/core';

class PrintableDivider extends React.Component {
    render() {
        return (
            <Divider style={{ backgroundColor: 'black', height: '2px', margin: '5px' }} />
        )
    }
}

export default PrintableDivider