import gql from 'graphql-tag'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import CytoscapeComponent from 'react-cytoscapejs';
import { withGraphqlQuery } from '../../../../graphql/core/connectors'

function graphNodeToCytoscapeElement(node) {
  return {
    data: {
      id: node.id,
      label: node.label
    },
    classes: node.types
  }
}

function graphEdgeToCytoscapeElement(node) {
  return {
    data: {
      source: node.source,
      target: node.target,
      label: node.label
    }
  }
}


const layout = { name: 'breadthfirst' };  // try breadthfirst, circle, concentric, cose
const stylesheet = [
  {
    selector: 'node',
    style: {
      shape: 'round-rectangle',
      'background-color': 'blue',
      'label': 'data(label)'
    }
  },
  {
    selector: 'node.QueriedNode',
    style: {
      shape: 'round-hexagon'
    }
  },
  {
    selector: 'node.DynamicLib',
    style: {
      'background-color': 'black',
    }
  },
  {
    selector: 'node.Executable',
    style: {
      'background-color': '#2185d0',
    }
  },
  {
    selector: 'edge',
    style: {
      'curve-style': 'straight',
      'target-arrow-shape': 'triangle-backcurve',
      'target-arrow-color': 'grey',  // rgb(171, 218, 65) is pilot light green
      'target-endpoint': 'outside-to-line-or-label'
    }
  }
];

/*
 * Must provide:
 *  fsId
 *  binId
 *  binType
 */
class ReachabilityGraph extends Component {
  constructor(props) {
    super(props)
    //console.log(`In constructor, called with new data for ${this.props.fsId}/${this.props.binaryId}`);
    const rNodes = props.data.nodes;
    this.noData = (rNodes === undefined);
    if (!this.noData) {
      const rEdges = props.data.edges;
      const tmpElements = rNodes.map(graphNodeToCytoscapeElement)
      this.elements = tmpElements.concat(rEdges.map(graphEdgeToCytoscapeElement))  
    }
  }
  
  render() {
    const { classes } = this.props

    // If data does not exist, display message
    if (this.noData || this.props.data.edges.length === 0) {
      return (
        <div>
          Unable to render reachability graph. 
        </div>
      )
    }

    //console.log(`Rendering for ${this.props.fsId}/${this.props.binaryId} with ${this.elements.length} elements.`)
    return (
      <div className={classes.root}>
        <CytoscapeComponent
          elements={this.elements}
          style={{width: '100%', height: '150px'}}
          stylesheet={stylesheet}
          layout={layout}
          zoomingEnabled={true} userZoomingEnabled={true} panningEnabled={true} userPanningEnabled={true} 
        />
      </div>
    )
  }
}

// routing
ReachabilityGraph = withRouter(ReachabilityGraph)

// data
ReachabilityGraph = withGraphqlQuery({
  query: gql`
    query FindBinaryUsages($input: FindBinaryUsagesInput) {
      FindBinaryUsages(input: $input) {
        nodes {
          id
          label
          types
        }
        edges {
          source
          target
          label
        }
      }
    }
  `,
  variables: (props) => {
    return {
      input: {
        fsId: props.fsId, 
        binId: props.binaryId, 
        binType: props.binaryType
      }
    }
  },
  onData: (data) => data.FindBinaryUsages
})(ReachabilityGraph)

// style 
ReachabilityGraph = withStyles((theme) => ({
  root: {
    display: 'flex', 
    flexDirection: 'column', 
    padding: 5, 
    borderStyle: 'solid', 
    borderWidth: '2px', 
    borderColor: 'darkgrey', 
    borderRadius: '5px',
    backgroundColor: 'white'
  }
}))(ReachabilityGraph)

export default ReachabilityGraph