import React from 'react'
import { connect } from 'react-redux'

export class RBAC extends React.Component {
  render() {
    const { roles, userProfile, silent } = this.props
    if (roles === userProfile.role) {
      return this.props.children
    } else {
      if (silent) {
        return ''
      } else {
        return <div>Access Denied (Access requires {roles})</div>
      }
    }
  }
}

// state
RBAC = connect(
  (state) => {
    const { userProfile } = state
    return { userProfile: userProfile }
  },
)(RBAC)

export const withRBAC = (roles) => {
  return (Component) => {
    return (props) => (
      <RBAC roles={roles}>
        <Component {...props} />
      </RBAC>
    )
  }
}