import React from 'react'
import { Message } from 'semantic-ui-react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log('Caught error:')
    console.error({error, info});
  }

  render() {
    const banner = this.props.banner
    if (this.state.hasError) {
      return (
        <div style={{display: 'flex', flexDirection: 'column', padding: 20}}>
          <img alt='' width='200px' height='50px' src={banner} />
          <Message
            icon='bug'
            header='Oops...'
            content='We encountered an unexpected error, sorry about that. Please refresh the page. If the error persists, please contact the Pilot team. '
          />
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary