import React from 'react'
import FileSystemSelector from '../../../../components/wrappers/FileSystemSelector'
import ExecutablesTable from './ExecutablesTable'
import ExecutableDisplay from './ExecutableDisplay'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import VerticalSpace from '../../../../components/core/layout/VerticalSpace'
import PageSplit from '../../../../components/core/layout/PageSplit'
import NullSelection from '../../../../components/placeholders/NullSelection'
import Page from '../../../../components/core/layout/Page'

class ExecutablesTab extends React.Component {

  state = {
    executableId: null
  }

  setExecutableId = (executableId) => {
    this.setState({executableId})
  }

  render() {
    return (
      <Page>
        <FileSystemSelector submissionId={this.props.match.params.imageId}
         showOnlyTypes={['File System']}
         requestedStageDoneBeforeData={{stage: 2, module: 'strings', all: false}}
        >
          {({containerId}) => {
            return (
              <PageSplit>
                <ScrollSpace>
                  <ExecutablesTable 
                    filesystemId={containerId}
                    selectedExecutableId={this.state.executableId}
                    setExecutableId={this.setExecutableId}
                  />
                </ScrollSpace>
                <VerticalSpace />
                <ScrollSpace>
                  { (this.state.executableId !== null) ? (
                    <ExecutableDisplay executableId={this.state.executableId} />
                  ) : (
                    <NullSelection />
                  )}
                </ScrollSpace>
              </PageSplit>
            )
          }}
        </FileSystemSelector>
      </Page>
    )
  }
}

export default ExecutablesTab
