import React from 'react'
import { withStyles } from '@material-ui/core/styles';


class AppLayout extends React.Component {
  render() {
    const { classes, children } = this.props
    return (
      <div className={classes.root}>
        {children}
      </div>
    )
  }
}

const style = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }
}
AppLayout = withStyles(style)(AppLayout)

export default AppLayout