import React from 'react'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import { Button, Input, Dropdown, Table } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import ScrollSpace from '../../../../components/core/layout/ScrollSpace';
import VerticalSpace from '../../../../components/core/layout/VerticalSpace'
import Page from '../../../../components/core/layout/Page';
import PageMain from '../../../../components/core/layout/PageMain';
import PageToolbar from '../../../../components/core/layout/PageToolbar'
import DataNoResults from '../../../../components/messages/DataNoResults'


// Insert new strings types here so that they are translated to pretty-text strings in filters and table:
const typeTranslationTable = {
  url: 'URL',
  url_path: 'URL Path',
  email: 'Email',
  ipv4: 'IPv4',
  ipv6: 'IPv6',
  domain: 'Domain Name',
  username: 'Username'
};


class StringsTab extends React.Component {

  constructor(props) {
    super()
    const uniqueIds = new Set()
    const rows = []
    const types = []
    for (let c of props.data) {
      if (!types.includes(c.fcType)) {
        types.push(c.fcType)
      }
      if (!uniqueIds.has(c.fcId)) {
        uniqueIds.add(c.fcId)
        rows.push({
          id: c.fcId,
          // NOTE: may not have containerItemPath on BaremetalContents, etc - use containerName instead:
          itemPath: c.containerItemPath ? c.containerItemPath : c.containerName,
          extraction: c.containerExtraction,
          type: typeTranslationTable[c.fcType] || c.fcType,
          value: c.fcValue
        })
      }
    }
    this.state = {
      rows,
      valueFilter: '',
      typeFilter: [],
      typeFilterOptions: types.map((t) => {
        return {
          value: typeTranslationTable[t] || t,
          key: t,
          text: typeTranslationTable[t] || t
        }
      })
    }
  }

  render() {
    let { rows, typeFilterOptions, typeFilter, valueFilter} = this.state

    if (rows.length === 0) {
      return <DataNoResults type='Strings' />
    }
    if (typeFilter.length > 0) {
      rows = rows.filter(r => typeFilter.includes(r.type))
    }
    if (valueFilter !== '') {
      rows = rows.filter(r => r.value.includes(valueFilter))
    }
    return (
      <Page>
        <PageToolbar>
          <Input 
            icon='search' 
            placeholder='Value' 
            onChange={(_, data) => { this.setState({valueFilter: data.value}) }} 
          />
          <VerticalSpace />
          <Dropdown 
            placeholder='Filter String Types' 
            multiple 
            selection 
            options={typeFilterOptions} 
            value={typeFilter}
            onChange={(_, data) => { this.setState({typeFilter: data.value}) }}
          />
          <VerticalSpace />
          <Button size='tiny' color='grey' onClick={() => {this.setState({typeFilter: []})}}>
            Clear Filters
          </Button>
        </PageToolbar>
        <PageMain>
          <ScrollSpace>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Context or File Location</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { rows.map((row) => {
                  // TODO: Determine why fcId is duplicated sometimes, thus invalidating unique key. Should be unique.
                  return (
                    <Table.Row key={row.fcId}>
                      <Table.Cell style={{ wordWrap: 'break-word', maxWidth: '300px', fontFamily: 'monospace' }}>{row.itemPath}</Table.Cell>
                      <Table.Cell>{row.type}</Table.Cell>
                      <Table.Cell style={{ wordWrap: 'break-word', maxWidth: '500px', fontFamily: 'monospace' }}>{row.value}</Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </ScrollSpace>
        </PageMain>
      </Page>
    )
  }
}

// routing
StringsTab = withRouter(StringsTab)

// data
/* NOTE: Can query these but not all may be populated, check resolver:
        fcId
        fcType
        fcValue
        findingId
        containerRelOffset
        containerName
        containerType
        containerItemPath
        containerExtraction
*/
// NOTE: The resolver FindImageFileContents only gets these for BaremetalContents and Files.
//  Intentionally, it does NOT return FileContents attached to ConfigContents.
StringsTab = withGraphqlQuery({
  query: gql`
    query FindImageFileContents($input: ImageMatchInput) {
      FindImageFileContents(input: $input) {
        fcId
        fcType
        fcValue
        findingId
        containerName
        containerItemPath
        containerExtraction
      }
    }
  `,
  onData: (data) => data.FindImageFileContents,
  variables: (props) => ({input: {id: props.match.params.imageId}})
})(StringsTab)

export default StringsTab