import _ from 'lodash'
import { isFunction } from 'lodash'
import React from 'react'
import { Query } from "@apollo/react-components"
import Loading from '../../components/core/widgets/Loading'
//import GenericError from '../../components/core/errors/GenericError'
import ApiCallError from '../../components/core/errors/ApiCallError'
import DataUnavailable from '../../components/messages/DataUnavailable'

export const withGraphqlQuery = (options) => {
  return (Component) => {
    return (props) => {

      // variables
      let variablesFunc = () => ({})
      if (_.isObject(options.variables)) {
        variablesFunc = () => (options.variables)
      }
      if (_.isFunction(options.variables)) {
        variablesFunc = (props) => {
          return options.variables(props)
        }
      }
      const variables = variablesFunc(props)

      // poll interval
      let pollInterval = 0
      if (options.pollInterval !== undefined) {
        pollInterval = options.pollInterval
      }

      // poll check
      let pollStopCheck = () => false
      if (_.isFunction(options.pollStopCheck)) {
        pollStopCheck = (props) => {
          return options.pollStopCheck(props)
        }
      }
     
      // fetch policy
      let fetchPolicy = undefined
      if (options.fetchPolicy !== undefined) {
        fetchPolicy = options.fetchPolicy
      }

      // loading component
      let LoadingComponent = Loading
      if (options.Loading !== undefined) {
        LoadingComponent = options.Loading
      }
      
      // error component
      let ErrorComponent = ApiCallError
      if (options.Error !== undefined) {
        ErrorComponent = options.Error
      }

      // We support the argument to 'query' to be a function which takes a single argument, props. Otherwise should be a gql string.
      const queryString = (_.isFunction(options.query)) ? options.query(props) : options.query;

      return (
        <Query query={queryString} variables={variables} pollInterval={pollInterval} fetchPolicy={fetchPolicy} >
        {({ loading, error, data, refetch, startPolling, stopPolling, client }) => {
          
          // encountered error, display error
          if (error) {
            return <ErrorComponent error={error} />
          }

          if (data) {
            // format data
            let returnData = data;
            if (isFunction(options.onData)) {
              returnData = options.onData(returnData)
              if (returnData === undefined || returnData === null) {
                return <DataUnavailable />
              }
            }

            // run poll stop check
            if (pollStopCheck({data: returnData, props})) {
              stopPolling()
              refetch()
            }

            return (
              <Component 
                refetch={refetch} 
                startPolling={startPolling} 
                stopPolling={stopPolling} 
                data={returnData} 
                client={client}
                {...props} 
              />
            )
          }
          
          // waiting on data, display loading 
          if (loading) {
            return <LoadingComponent />
          }
        }}
        </Query>
      )
    }
  }
}
