/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import gql from 'graphql-tag';
import React from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Button, Divider } from 'semantic-ui-react';
import DropdownMenu from '../../../../components/core/dropdown/DropdownMenu';
import Page from '../../../../components/core/layout/Page';
import PageMain from '../../../../components/core/layout/PageMain';
import PageToolbar from '../../../../components/core/layout/PageToolbar';
import ScrollSpace from '../../../../components/core/layout/ScrollSpace';
import DataNotReady from '../../../../components/messages/DataNotReady';
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import SbomPrintable from './SbomPrintable';
import SbomTable from './SbomTable';
import FlexSpace from '../../../../components/core/layout/FlexSpace'
import ExportSPDXButton from './ExportSPDX'
import { Typography } from '@material-ui/core';
import { formatHex } from '../../../../utils/format';
import DocumentExportButton from '../../../../components/RunDocumentExportButton'


class SbomTab extends React.Component {
  render() {
    const { data } = this.props
    const submission = data
    // Collect FileSystems and BaremetalContents and ConfigContents
    let rows = this.props.data.image.node.filesystems.map(r => { return {'type': 'Filesystem', 'item': r};})
    rows = rows.concat(this.props.data.image.node.baremetalContents.map(r => { return {'type': 'Other', 'item': r};}))
    rows = rows.concat(this.props.data.image.node.configContents.map(r => { return {'type': 'Other', 'item': r};}))
    // NODE: Add more types of containers here!

    if (rows.length === 0) {
      return <DataNotReady />
    }

    return (
      <Page>
        <PageToolbar>
          <FlexSpace />
          <DropdownMenu name='findingexportmenu'
            buttonText='Export'
            color='white' size='tiny'
            buttonStyleObject={{ position: 'relative', marginRight: '10px' }}
          >
            <DocumentExportButton
              submissionId={submission.id}
              inDropdown={true}
              showPdf={false}
            />
            <ReactToPrint content={() => this.componentRef}
              copyStyles={true}
              documentTitle={'Pilot SBOM Export'}
              /* Set page margin to 0mm to hide printing info date/url/pageno/etc */
              pageStyle={'@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }'}
              removeAfterPrint={true}
            >
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button size='tiny' color='grey' onClick={handlePrint}>Printable</Button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
            <ExportSPDXButton
                productName={this.props.data.productName}
                productVersion={this.props.data.productVersion}
                dateSubmitted={this.props.data.dateSubmitted}
                userName={this.props.data.user.node ? this.props.data.user.node.name : ''}
                inputFilename={this.props.data.image.node.inputFilename}
                sha256={this.props.data.image.node.sha256}
                size={this.props.data.image.node.size}
                rows={rows}
            />
          </DropdownMenu>
        </PageToolbar>
        <PageMain>
          <ScrollSpace>
            {
              // obj.type is also available and would be 'Filesystem', 'Other', etc
              rows.map((obj, i) => (
                <React.Fragment key={i}>
                    <Typography variant="h5" style={{margin: '10px'}}>
                      {obj.item.node.name} (at {formatHex(obj.item.offset)})
                    </Typography>
                    <Divider style={{marginTop: '0px'}} />
                    <SbomTable data={{ data: obj.item, open: false }} />
                </React.Fragment>
              ))
            }
          </ScrollSpace>
        </PageMain>
        { /* NOTE: This is always computed and just rendered hidden, even if the user doesn't request printing. */}
        <div style={{ display: "none" }}>
          <SbomPrintable childRef={el => (this.componentRef = el)}
            productName={this.props.data.productName}
            productVersion={this.props.data.productVersion}
            dateSubmitted={this.props.data.dateSubmitted}
            userName={this.props.data.user.node ? this.props.data.user.node.name : ''}
            inputFilename={this.props.data.image.node.inputFilename}
            sha256={this.props.data.image.node.sha256}
            size={this.props.data.image.node.size}
            rows={rows}
          />
        </div>
      </Page>
    )
  }
}

// data
SbomTab = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        productName
        productVersion
        dateSubmitted
        user {
            id
            node {
                id
                name
            }
        }
        image {
          id
          node {
            id
            status
            inputFilename
            sha256
            size
            baremetalContents {
              id
              offset
              node {
                id
                name
                staticLibs {
                  id
                  node {
                    id
                    name
                    version
                  }
                }
              }
            }
            configContents {
              id
              offset
              node {
                id
                name
                staticLibs {
                  id
                  node {
                    id
                    name
                    version
                  }
                }
              }
            }
            filesystems {
              id
              offset
              node {
                id
                name
                executables {
                  id
                  node {
                    id
                    name
                    version
                    foundIn {
                      id
                      node {
                        ... on File {
                          id
                          path
                          sha256
                        }
                      }
                    }
                  }
                }
                dynamicLibs {
                  id
                  node {
                    id
                    name
                    version
                    foundIn {
                      id 
                      node {
                        ... on File {
                          id
                          path
                          sha256
                        }
                      }
                    }
                  }
                }
                staticLibs {
                  id
                  node {
                    id
                    name
                    version
                    foundIn {
                      id
                      node {
                        ... on File {
                            id
                            path
                            sha256
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({ input: { id: props.match.params.imageId } }),
  onData: (data) => (data.Submission[0])
})(SbomTab)


export default SbomTab
