import React from 'react'
import { Sankey, Hint } from 'react-vis';
import Typography from '@material-ui/core/Typography'
import { formatHex } from '../../../../utils/format'

const BLURRED_LINK_OPACITY = 0.3;
const FOCUSED_LINK_OPACITY = 0.6;

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

/* This aims to, given an object that is of the following form, determine the depth of the object recursively:
 * { offset: [ {offset: [ ... ], offset: [ ... ] } ] }
 */
function getObjectMaximumDepth(obj) {
  let depth = 0;
  if (obj.id !== undefined) {
    // Base case, have an end object that has id as one of the attributes.
    return 0;
  }
  if (Array.isArray(obj)) {
    // If we get an array, inside of it should be objects to recurse down
    for (let j of obj) {
      const tmpDepth = getObjectMaximumDepth(j);
      if (tmpDepth > depth) {
          depth = tmpDepth;
      }
    }
  } else {
    // We got an array, e.g. { offset: [ list of other objects ] }, dive into it:
    for (let k of Object.keys(obj)) {
      const tmpDepth = getObjectMaximumDepth(obj[k]);
      if (tmpDepth > depth) {
          depth = tmpDepth;
      }
    }
  }
  return 1 + depth;
}

class ComponentHierarchy extends React.Component {
  constructor(props) {
    super()
    const data = props.data
    this.nodes = []
    this.links = []
    this.endItem = null
    //console.log('Hierarchy:', JSON.stringify(data, null, 2))
    Object.entries(data).map((c) => {
      return this.recursiveNodeLinkGenerator(c, getObjectMaximumDepth(data))
    })
    this.state = {
      activeLink: null,
      width: props.width
    };
  }

  _renderHint() {
    // Heavily from https://github.com/uber/react-vis/blob/master/showcase/sankey/link-hint.js
    const {activeLink} = this.state;
    // calculate center x,y position of link for positioning of hint
    const x = activeLink.source.x1; // + (activeLink.target.x0 - activeLink.source.x1) / 2;
    const y = activeLink.y0 - (activeLink.y0 - activeLink.y1) / 2;
    const hintValue = { [`unpacked at offset`]: formatHex(activeLink.source.offset) };
    return <Hint x={x} y={y} value={hintValue} style={{
      content: {
        backgroundColor: '#eee',
        padding: '5px',
        border: '1px solid #012552',
        borderRadius: '5px'
      }
    }} />;
  }

  // This function makes the sub-rows via recursion through the constructed object hierarchy:
  recursiveNodeLinkGenerator(tuple, dataMaxDepth) {
    const innerOffset = tuple[0];
    const nextHierarchy = tuple[1];
    this.nodes.push({
      name: (nextHierarchy.id !== undefined) ? truncateString((nextHierarchy.fstype !== undefined) ? nextHierarchy.fstype : nextHierarchy.name, 25) : null,
      offset: innerOffset
    })
    if (this.nodes.length > 1) {
      this.links.push({
        source: this.nodes.length-2,
        target: this.nodes.length-1,
        value: ((dataMaxDepth*20)-(this.nodes.length*20)),
        color: '#012552'
      })
    }
    if (nextHierarchy.id !== undefined) {
      const nameStr = (nextHierarchy.fstype !== undefined) ? nextHierarchy.fstype : nextHierarchy.name;
      this.endItem = nameStr;
    } else {
      Object.entries(nextHierarchy).map((c) => {return this.recursiveNodeLinkGenerator(c, dataMaxDepth)})
    }
  }

  render() {
    const {activeLink, width} = this.state;
    if (this.nodes.length === 1) {
      // In this case we have only a single item nested, no Sankey graph will be shown
      return (
        <Typography>{this.endItem}</Typography>
      )
    }
    // In this case we have more than one node, meaning also have at least one link, so we can draw the Sankey:
    return (
      <div style={{position: 'relative'}}>
        <Sankey
          nodes={this.nodes}
          //nodes={this.nodes.map(d => ({...d}))}
          links={this.links.map((d, i) => ({
              ...d,
              opacity:
                activeLink && i === activeLink.index
                  ? FOCUSED_LINK_OPACITY
                  : BLURRED_LINK_OPACITY
            }))}
          width={width}
          height={75}
          labelRotation={0}
          align={"left"}
          style={{
            links: {
              opacity: 0.3
            },
            labels: {
              fontSize: '14px'
            },
            rects: {
              strokeWidth: 1,
              stroke: '#012552',
              fill: '#000'
            }
          }}
          hasVoronoi={false}
          onLinkMouseOver={node => this.setState({activeLink: node})}
          //onLinkMouseOut={() => this.setState({activeLink: null})}
        >
          {activeLink && this._renderHint()}
        </Sankey>
        <Typography>{this.endItem}</Typography>
      </div>
    )
  }
}


export default ComponentHierarchy