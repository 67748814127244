import React from 'react'

export const Cvss = (props) => {
  const value = (props.score !== 10) ? parseFloat(props.score).toFixed(1) : '10'
  const color = (props.score > 7) ? 'red' : (props.score > 4) ? 'orange' : 'grey';
  return (
    <div style={{
      width: 30, 
      height: 30, 
      borderRadius: 3, 
      borderStyle: 'solid', 
      borderWidth: 2, 
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
      fontSize: 12,
      background: color,
      borderColor: color,
      color: 'white',
      }}
    >{value}</div>
  )
}

export default Cvss