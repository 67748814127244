import gql from 'graphql-tag'

const deleteBndb = async (client, binaryId, binaryType) => {
  const results = await client.query({
    query: gql`
      query ${binaryType}($input: ${binaryType}MatchInput) {
        ${binaryType}(input: $input) {
          id
          name
          bndbUrl
        }
      }
    `,
    variables: { input: {id: binaryId} }
  });
  console.log('Data:', results);
  if (results.data[binaryType].length === 0) {
    // TODO: propagate error up to UI
    console.error(`Binary (for ${binaryType} ${binaryId}) not found`)
    return false
  }

  let { bndbUrl } = results.data[binaryType][0]
  const link = document.createElement('a');
  link.href = bndbUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return true
}

export default deleteBndb;