import React from 'react'
import { Message } from 'semantic-ui-react'

class DataNoResults extends React.Component {
  render() {
    let { msg, type } = this.props
    if (msg === undefined) {
      if (type === undefined) {
        msg = 'No data of this type found.'
      } else {
        msg = `No ${type} data found.`
      }
    }
    return (
      <Message>
        <Message.Header>No Data</Message.Header>
        <p>{msg}</p>
      </Message>
    )
  }
}

export default DataNoResults