import gql from 'graphql-tag'

export const openLink = (url) => {
    const link = document.createElement('a');
    link.href = url
    link.target = '_blank'
    link.rel='noopener noreferrer'
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const openSubmissionLogsViewer = async (client, submissionId) => {
  const query = gql`
    query SubmissionLogsUrl($input: String) {
      SubmissionLogsUrl(input: $input)
    } 
  `
  const variables = {
    input: submissionId
  }
  const response = await client.query({query, variables})
  const url = response.data.SubmissionLogsUrl
  openLink(url)
}

export const openTaskLogsViewer = async (client, taskId) => {
  const query = gql`
    query TaskLogsUrl($input: String) {
      TaskLogsUrl(input: $input)
    } 
  `
  const variables = {
    input: taskId
  }
  const response = await client.query({query, variables})
  const url = response.data.TaskLogsUrl
  openLink(url)
}