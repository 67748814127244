import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Typography } from '@material-ui/core'

class NullSelection extends React.Component {
  render() {
    return (
      <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Icon size='huge' name='search' />
        <Typography style={{fontSize: '16px', marginTop: '15px'}}>Select Item to View Details</Typography>
      </div>
    )
  }
}

export default NullSelection