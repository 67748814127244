/*
  Copyright 2019-2021 Pilot Security Inc. All Rights Reserved.
  NOTE: Due to current ACL permissions in api-service, this can only be rendered to admins.
*/

import React, {useState } from 'react'
import gql from 'graphql-tag'
import { sortBy } from 'lodash'
import { withStyles } from '@material-ui/core'
import { Button, Icon, Segment, Table } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import { withRouter } from 'react-router-dom';
import PageSection from '../../../../components/core/page/PageSection'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import Page from '../../../../components/core/layout/Page'
import { withRBAC } from '../../../../components/core/access/RBAC'
import { computeFirmwareVariables } from '../../../../utils/firmware'
import { taskText } from './utils';
import StatusLabel from '../../../../components/labels/StatusLabel';
import { withApollo } from '@apollo/react-hoc'
import { openLink } from '../../../../utils/logs'
import { computeFirmwarePollStopCheckForSubmissionSingle } from '../../../../utils/firmware';
import { icons } from './ComponentTasksBlock';


let LogsLinkButton = (props) => {
    return (
        <Button color='blue' onClick={async () => {
            const response = await props.client.query({
                query: gql`
                    query ProcessingTask($input: ProcessingTaskMatchInput) {
                        ProcessingTask(input: $input) {
                            logs
                        }
                    } 
                `,
                variables: {
                    input: {id: props.id}
                },
                fetchPolicy: 'network-only'
            })
            // TODO: handle errors
            const url = response.data.ProcessingTask[0].logs;
            openLink(url)
        }}><Icon name='search' />Logs</Button>
    )
}

// eslint-disable-next-line
LogsLinkButton = withApollo(LogsLinkButton)

let TraceLinkButton = (props) => {
    return (
        <Button color='blue' onClick={async () => {
            const response = await props.client.query({
                query: gql`
                    query ProcessingTask($input: ProcessingTaskMatchInput) {
                        ProcessingTask(input: $input) {
                            traces
                        }
                    } 
                `,
                variables: {
                    input: {id: props.id}
                },
                fetchPolicy: 'network-only'
            })
            // TODO: handle errors
            const url = response.data.ProcessingTask[0].traces;
            openLink(url)
        }}><Icon name='align left' />Traces</Button>
    )
}

// eslint-disable-next-line
TraceLinkButton = withApollo(TraceLinkButton)

/*
function StatusIcon(props) {
    const status = statuses[props.status];
    return <Button size='small' compact color={status.background} icon={status.icon} />
}
*/

function Workflow(props) {
    const [open, setOpen] = useState(true);
    const { targetType, targetId, tasks } = props
    return (
        <Segment compact style={{padding: 0, width: 500}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{padding: 10, display: 'flex', flexDirection: 'row', background: '#2d2f3d'}} onClick={() => {setOpen(!open)} } >
                    <Icon size='large' inverted name={icons[targetType]} />
                    <div style={{marginLeft: 5, color: 'white', fontSize: 18, fontWeight: 500}}>{targetType}</div>
                    <div style={{flex: 1}} />
                    <Icon inverted name={(open) ? 'chevron up' : 'chevron down'} />
                </div>
                {(open) ? (
                    <div style={{padding: 10}}>
                        <div>{targetId}</div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                        { tasks.map(task => {
                            return <Task key={task.id} data={task} />
                        })}
                        </div>
                    </div>
                ): null}
            </div>
        </Segment>
    )
}

function Message(props) {
    if (!props.data) {
      return <div />
    }
    if (props.data === '') {
      return <div />
    }
    try {
      const data = JSON.parse(props.data);
      return (
        <div>
          <pre>{data.stack}</pre>
        </div>
      )
    } catch (e) {
      return <pre>{props.data}</pre>
    }
}

function Task(props) {
    const [open, setOpen] = useState(false);
    const { id, module, status, image, userMessage, dateStart, dateEnd } = props.data;
    const duration = (dateStart && dateEnd) ? `${parseInt((dateEnd - dateStart) / 1000)} s` : 'UNKNOWN';

    return (
        <div style={{display: 'flex', flexDirection: 'column', marginBottom: 10, border: '1px solid #F0F0F0'}}>
            <div style={{padding: 10, alignItems: 'center', display: 'flex', flexDirection: 'row', background: '#F0F0F0'}} onClick={() => {setOpen(!open)} } >
                <StatusLabel text={status} />
                <div style={{marginLeft: 15}}>{taskText(module)}</div>
                <div style={{flex: 1}} />
                <Icon name={(open) ? 'chevron up' : 'chevron down'} />
            </div>
            {(open) ? (
                <div style={{padding: 10, display: 'flex', flexDirection: 'column'}}>
                  <Table compact celled>
                    <Table.Body>
                      <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell>{id}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>IMAGE</Table.HeaderCell>
                        <Table.Cell>{image}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>TIME</Table.HeaderCell>
                        <Table.Cell>{duration}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                    <Message data={userMessage} />
                    <div>
                        <LogsLinkButton id={id} />
                        <TraceLinkButton id={id} />
                        <Button disabled color='red'><Icon name='cancel' />Kill</Button>
                    </div>
                </div>
            ): null}
        </div>
    )
}

function triggerEvaluateState(client, submissionId) {
  const mutation = gql`
    mutation EvaluatePipeline($input: String) {
      EvaluatePipeline(input: $input)
    }
  `
  const result = client.mutate({
    mutation,
    variables: {
      input: submissionId
    }
  })
  // TODO: Actually do something:
  console.log(result)
}

class TasksTab extends React.Component {
  render() {
    const client = this.props.client;
    const submission = this.props.data
    const stages = sortBy(submission.stages.map(r => r.node), ['index'])
    return (
      <Page>
        <ScrollSpace plain={true}>
          <div style={{height: 20}} />
          { stages.map((stage) => {
            return (
              <PageSection key={stage.id} title={`Stage ${stage.index}`}>
                { stage.workflows.map(r => r.node).map((workflow) => {
                    const { targetType, targetId } = workflow
                    const tasks = workflow.tasks.map(r => r.node)
                    return (
                      <Workflow
                        key={targetId}
                        targetType={targetType}
                        targetId={targetId}
                        tasks={tasks}
                      />
                    )
                })}
              </PageSection>
            )
          })}
          <Button onClick={() => triggerEvaluateState(client, submission.id)}>Evaluate State</Button>
          <Button icon onClick={() => this.props.refetch()}><Icon name='refresh' /></Button>
        </ScrollSpace>
      </Page>
    )
  }
}

// routing
TasksTab = withRouter(TasksTab)

// style 
TasksTab = withStyles((theme) => ({
  space: {
    height: '20px'
  }
}))(TasksTab)

// data
TasksTab = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        image {
          id
          node {
            id
            status
          }
        }
        manualTasks {
          id
          node {
            id
            desc
            module
            status
            userMessage
            version
          }
        }
        stages {
          id
          node {
            id
            index
            workflows {
              id
              node {
                id
                targetType
                targetId
                tasks {
                  id
                  node {
                    id
                    desc
                    module
                    image
                    status
                    userMessage
                    version
                    image
                    dateStart
                    dateEnd
                  }
                }
              }
            }
          }
        }
      }
    } 
  `,
  onData: (data) => data.Submission[0],
  variables: computeFirmwareVariables,
  pollStopCheck: computeFirmwarePollStopCheckForSubmissionSingle,
  pollInterval: 1000 * 30,
})(TasksTab)

// access control
TasksTab = withRBAC('admin')(TasksTab)

export default TasksTab
