import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  spacer: {
    flexGrow: 1
  }
})

class FlexSpace extends React.Component {
  render() {
    const { classes } = this.props
    return <div className={classes.spacer} />
  }
}

FlexSpace = withStyles(styles)(FlexSpace)

export default FlexSpace
