import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ImageSearch from './search/ImageSearch'
import ImageSubmit from './submit/ImageSubmit'
import ImageExplorer from './explorer'

class FirmwareRoute extends React.Component {
  render() {
    return (
      <Switch>
        <Route path='/firmware/search' exact={true} component={ImageSearch} />
        <Route path='/firmware/submit' exact={true} component={ImageSubmit} />
        <Route path='/firmware/:imageId' exact={false} component={ImageExplorer} />
        <Redirect to='/firmware/search' />
      </Switch>
    )
  }
}

export default FirmwareRoute
