import React from 'react'

class Centered extends React.Component {
  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{flex: '1'}} />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {this.props.children}
        </div>
        <div style={{flex: '1'}} />
      </div>
    )
  }
}

export default Centered