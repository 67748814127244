import React from 'react';
import gql from 'graphql-tag'
import { connect } from 'react-redux';
import { withStyles, Typography } from '@material-ui/core'
import { Button, Icon, Message, Table } from 'semantic-ui-react';
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import PageSection from '../../../../components/core/page/PageSection'
import ContentsSummary from './contents/ContentsSummary'
import FindingsSummary from './findings/FindingsSummary'
import Page from '../../../../components/core/layout/Page'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import { computeFirmwareVariables, computeFirmwarePollStopCheckForSubmissionSingle, isAnalysisFinished } from '../../../../utils/firmware'
import moment from 'moment';
import { formatBytes } from '../../../../utils/format';
import downloadImage from '../../../../graphql/functions/downloadImage';
import DeleteButton from '../../../../components/DeleteButton'
import deletePipeline from '../../../../graphql/functions/deletePipeline';
import CancelPipelineButton from '../../../../components/CancelPipelineButton';
import ProgressSummary from './progress/ProgressSummary';
import ShareFirmware from './share';
  
let FirmwareSummary = (props) => {
  const submission = props.data;
  const image = submission.image.node
  const { classes } = props

  return (
    <Table definition collapsing celled>
    <Table.Body>
      <Table.Row>
        <Table.Cell>
          <Typography className={classes.tableHeaderText}>Uploaded By</Typography>
        </Table.Cell>
        <Table.Cell>
          <Typography>{(submission.user.node === null) ? 'Other User' : submission.user.node.name}</Typography>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>
          <Typography className={classes.tableHeaderText}>Uploaded On </Typography>
        </Table.Cell>
        <Table.Cell>
          <Typography>
            {moment(submission.dateSubmitted).format('lll')} ({moment(submission.dateSubmitted).fromNow()})
          </Typography>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>
          <Typography className={classes.tableHeaderText}>Filename</Typography>
        </Table.Cell>
        <Table.Cell>
          <Typography>{image.inputFilename}</Typography>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>
          <Typography className={classes.tableHeaderText}>Size</Typography>
        </Table.Cell>
        <Table.Cell>
          <Typography>{formatBytes(parseInt(image.size))}</Typography>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
  )
}

// styling
FirmwareSummary = withStyles((theme) => ({
  tableHeaderText: {
    fontWeight: 600
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 100, 
    height: 30, 
    borderStyle: 'solid',
    borderColor: 'gray',
    borderWidth: 1,
    borderRightStyle: 'none',
    paddingLeft: 10
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 30, 
    width: 400,
    borderStyle: 'solid',
    borderColor: 'gray',
    borderWidth: 1,
    paddingLeft: 10
  }
}))(FirmwareSummary)

class SummaryTab extends React.Component {
  state = {
    canceling: false,
  }

  setCanceling = () => {
    this.setState({canceling: true})
  }

  render() {
    const submission = this.props.data
    const { client, userProfile } = this.props;
    const { canceling } = this.state
    const terminated = isAnalysisFinished(submission.image.node.status);
    let owned = false;
    if (submission.user.node) {
      if (submission.user.node.id === userProfile.id) {
        owned = true;
      }
    }
    if (terminated && canceling) {
      // Once cancelling finishes, we should be in terminated state, unset the canceling state flag!
      this.setState({canceling: false})
    }
    return (
      <Page>
        <ScrollSpace plain={true}>
          { (canceling) ? (
            <Message negative
              icon='exclamation triangle'
              header='Canceling Analysis'
              content="Analysis is being cancelled at the user's request."
            />
          ) : (!terminated) ? (
            // NOTE: Adding className=icon here is a hack for it to render right:
            <Message positive className='icon'>
              <Message.Header className="blink_me">Analysis in Progress</Message.Header>
              <div style={{flex: 1}} />
              <CancelPipelineButton 
                submissionId={submission.id} 
                onClick={() => {
                  this.setCanceling();
                }}
              />
            </Message>
          ) : (submission.image.node.status === 'NOT SUPPORTED') ? (
            <Message
              negative
              icon='exclamation triangle'
              header='Firmware Not Supported'
              content='Analysis of this type of firmware is not yet supported.'
            />
          ) : (
            <React.Fragment>
              {
                (submission.image.node.status === 'CANCELED') ? (
                  <Message
                    negative
                    icon='exclamation triangle'
                    header='Analysis Canceled'
                    content='Results will not be complete.'
                  />
                ) : null
              }
              <PageSection title='Findings Summary' linkUrl={`/firmware/${submission.id}/findings`}>
                <FindingsSummary data={submission} />
              </PageSection>
              <div style={{paddingTop: 20}} />
              <ContentsSummary data={submission} />
            </React.Fragment>
          )}
          { (!terminated) ? (
            <ProgressSummary data={submission} />
          ) : (
            <PageSection title='Firmware Summary'>
              <FirmwareSummary data={submission} />
            </PageSection>
          )}
          <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
            <Button 
                color='blue'
                onClick={() => {
                  downloadImage(client, submission.image.node.id);
                }}
              >
                <Icon name='download'/>
                Download Original Firmware
            </Button>
            { ((terminated && owned) || (terminated && userProfile.role === 'admin')) ? (
              <DeleteButton 
                  onDelete={() => {
                      deletePipeline(client, submission.id);
                      this.props.history.push('/firmware');
                  }}
              />
            ) : null }
          </div>
          <div style={{marginTop: 20, marginBottom: 200}}>
            <ShareFirmware submissionId={submission.id} owned={owned} />
          </div>
        </ScrollSpace>
      </Page>
    )
  }
}

// state
SummaryTab = connect(
  (state) => {
    const { userProfile } = state
    return { userProfile: userProfile }
  }
)(SummaryTab)

// data
SummaryTab = withGraphqlQuery({
  // TODO: Figure out why with fetchPolicy set as below, the stopPolling doesn't work:
  //fetchPolicy: 'cache-and-network',
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        productName
        productVersion
        dateSubmitted
        activeStage
        errors
        warnings
        user {
          id
          node {
            id
            name
          }
        }
        image {
          id
          node {
            id
            status
            size
            inputFilename
            sha256
            filesystems {
              id
              offset
              extraction
              node {
                id
                name
                fstype
              }
            }
            otherContents {
              id
              offset
              extraction
              node {
                id
                name
                desc
              }
            }
            kernels {
              id
              offset
              extraction
              node {
                id
                name
                desc
              }
            }
            cryptographicData {
              id
            }
            encryptedContents {
              id
            }
            baremetalContents {
              id
            }
            configContents {
              id
            }
            findings {
              id 
              node {
                __typename
                ... on FindingGeneric {
                  id
                }
                ... on FindingVulnerableVersion {
                  id
                }
                ... on FindingCryptographicKey {
                  id
                }
                ... on FindingVulnerableFunctionCall {
                  id
                }
                ... on FindingOsConfiguration {
                  id
                }
              }
            }
          }
        }
      }
    } 
  `,
  variables: computeFirmwareVariables,
  pollStopCheck: computeFirmwarePollStopCheckForSubmissionSingle,
  pollInterval: 1000 * 30,
  onData: (data) => data.Submission[0],
})(SummaryTab)

// styling
SummaryTab = withStyles((theme) => ({
  tableHeaderText: {
    fontWeight: 600
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 100, 
    height: 30, 
    borderStyle: 'solid',
    borderColor: 'gray',
    borderWidth: 1,
    borderRightStyle: 'none',
    paddingLeft: 10
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 30, 
    width: 400,
    borderStyle: 'solid',
    borderColor: 'gray',
    borderWidth: 1,
    paddingLeft: 10
  }
}))(SummaryTab)

export default SummaryTab


