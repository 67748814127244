
export const taskText = (taskModule) => {
  switch (taskModule) {
    case 'extraction':
      return 'Component Extraction'
    case 'strings':
      return 'Version Inspection'
    case 'startup':
      return 'Startup Inspection'
    case 'disassembly':
      return 'Binary Inspection'
    case 'webserver':
      return 'Code Inspection (Webserver)'
    case 'configdata':
      return 'Configuration Data'
    case 'findings':
      return 'Security Analysis'
    case 'versiongraphbb':
      return 'Advanced Version Analysis'
    default:
      return 'UNKNOWN'
  }
}

export const sortTasks = (tasks) => {
  const order = ['strings', 'startup', 'disassembly', 'webserver']
  return order.map((processor) => {
    return tasks.find(t => (t.module === processor))
  })
  .filter(x => (x !== undefined))
}

export const orderTasks = (tasks, processors) => {
  let orderedTasks = []
  for (let p of processors) {
    let t = tasks.find(t => (t.module === p))
    if (t !== undefined) {
      orderedTasks.push(t)
    }
  }
  return orderedTasks
}

export const computeTaskDuration = (task) => {
  if (task.dateEnd === undefined || task.dateEnd === null || task.dateEnd === '') {
    return 'NA'
  }
  const delta = ( new Date(task.dateEnd) - new Date(task.dateStart) ) / 1000
  return `${delta} s`
}

