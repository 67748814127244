import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    paddingBottom: '10px'
  }
})

class Separator extends React.Component {
  render() {
    const { classes } = this.props
    return <div className={classes.root} />
  }
}

Separator = withStyles(styles)(Separator)

export { Separator }
