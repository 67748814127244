import gql from 'graphql-tag'

const createGroup = async (client, name) => {
  const results = await client.mutate({
    mutation: gql`mutation GroupCreate($input: [GroupCreateInput]) {
      GroupCreate(input: $input) {
        id
        name
      }
    }`,
    variables: {input: {name}}
  })
  return results
}

export default createGroup;
