import React from 'react';
import { withStyles } from '@material-ui/core';
//import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = (theme) => ({
  appbar: {
    height: theme.appbar.height,
    width: '100%',
    background: '#2d2f3d'
  },
  toolbar: {
    marginLeft: -16,
  }
})

class Appbar extends React.Component {

  render() {
    const { classes, children } = this.props
    return (
      <div className={classes.appbar} >
        <Toolbar className={classes.toolbar} variant='dense'>
          {children}
        </Toolbar>
      </div>
    )
  }
}

Appbar = withStyles(styles)(Appbar)

export default Appbar
