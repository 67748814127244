import gql from 'graphql-tag'

const toggleUserEnabled = async (client, userId) => {
  const results = await client.mutate({
    mutation: gql`mutation ToggleUserEnabled($input: ToggleUserEnabledInput) {
      ToggleUserEnabled(input: $input)
    }`,
    variables: {input: {userId}}
  })
  return results
}

export default toggleUserEnabled;