import React from 'react'
import ReactMarkdown from "react-markdown";

class Markdown extends React.Component {
  render() {
    const markdownSrc = this.props.data;
    return (
      <ReactMarkdown escapeHtml={true} skipHtml={true}>
        {markdownSrc}
      </ReactMarkdown>
    )
  }
}

export default Markdown