import gql from 'graphql-tag'

const revokeMembership = async (client, groupId, userId) => {
  const results = await client.mutate({
    mutation: gql`mutation RevokeMembership($input: RevokeMembershipInput) {
      RevokeMembership(input: $input)
    }`,
    variables: {input: {userId, groupId}}
  })
  return results
}

export default revokeMembership;
