import React from 'react'
import { Table } from 'semantic-ui-react'
import FindingLayout from '../../../FindingLayout'

class SensitiveInfo extends React.Component {
  render() {
    const finding = this.props.data
    const printStyle = this.props.printStyle || false;
    const items = finding.vulnerableEntity.map(r => r.node).filter(n => (n.__typename === 'FileContents'))
    return (
      <FindingLayout
        type='Information Disclosure' 
        icon='eye'
        title={finding.title}
        overallCvss={(printStyle === true) ? finding.scoreCvss : null}
        description={finding.description}
        impact={finding.impact}
        recommendation={finding.recommendation}     
        Details={() => (
          <div>
            <div>Sensitive Information</div>
            <Table collapsing compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              { items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>{item.type}</Table.Cell>
                    <Table.Cell>{item.value}</Table.Cell>
                  </Table.Row>
                )
              })}
              </Table.Body>
            </Table>
          </div>
        )}
      />
    )
  }
}

export default SensitiveInfo