import React from 'react'
import gql from 'graphql-tag';
import { Button, Dropdown, Loader } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../../graphql/core/connectors';
import grantAccess from '../../../../../graphql/functions/grantAccess';

class GrantAccess extends React.Component {

    state = {
        selected: null,
        active: false,
    }

    render() {
        const { resourceType, resourceId } = this.props;
        const groupinfo = this.props.data.GroupInfo;
        const shareOptions = [];
        for (let group of groupinfo) {
            shareOptions.push({
                text: `Group: ${group.name}`, 
                key: group.id,
                value: `Group:${group.id}`
            })
            for (let user of group.users) {
                shareOptions.push({
                    text: `User: ${user.name}`,
                    key: user.id,
                    value: `User:${user.id}`
                })
            }
        }
        return (
            <div style={{display: 'flex', flexDirection: 'row', gap: 5}}>
                <Dropdown
                    style={{width: 400}}
                    placeholder='Select User or Group'
                    selection
                    value={this.state.selected}
                    onChange={(_, {value}) => {
                        this.setState({selected: value})
                    }}
                    options={shareOptions}
                />
                <Button
                    color="green"
                    content="Grant Access"
                    disabled={(this.state.selected === null) || this.state.active}
                    onClick={() => {
                        this.setState({active: true})
                        const [subjectType, subjectId] = this.state.selected.split(":");
                        grantAccess(this.props.client, resourceType, resourceId, subjectType, subjectId)
                        .catch(e => {
                            this.setState({active: false, selected: null})
                            console.error(e)
                        })
                        .then(_ => {
                            this.setState({active: false, selected: null})
                            this.props.after();
                        })
                    }}
                />
                <Loader inline active={this.state.active} />
            </div>
        )
    }
}

GrantAccess = withGraphqlQuery({
    query: gql`
      query { GroupInfo }
    `
})(GrantAccess)

export default GrantAccess;
