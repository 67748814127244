import React from 'react';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  button: {
    color: 'white',
    marginLeft: 10,
    fontSize: 12
  },
  text: {
    fontSize: 12,
    color: 'white'
  }
})

class AppbarNavLinks extends React.Component {

  render() {
    const { classes, links } = this.props
    return (
      <React.Fragment>
        { links.map((link) => {
          return (
            <Button key={link.text} color='inherit' className={classes.button} component={Link} to={link.path}>
              <Typography variant='body1' className={classes.text}>
                {link.text}
              </Typography>
            </Button>
          )
        })}
      </React.Fragment>
    )
  }
}

AppbarNavLinks = withStyles(styles)(AppbarNavLinks)

export default AppbarNavLinks 
