/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import defaults from 'json-schema-defaults'
import spdx_schema_file from './spdx_schema.json'

/*
 * Fills in an Object based off of the spdx_schema.json file, using the defined 'default' parameters.
 */
export const generateDefaultJsonSpdx = () => {
	var temp = defaults(
		spdx_schema_file
	)
	return temp
}

export const extractDataForLibraryType = (binaryNodeType, binaryNodeList) => {
	if (binaryNodeList === undefined) {
		// This could be as something like BaremetalContents doesn't query for executables.
		return [];
	}
	// NOTE: We could update the DB query to do all of this sorting and grouping for us and return rows, which
	//		 would be better, but for now, we're dedupp-ing here:
	// Otherwise remap things into an list of objects and return.
	let binaryNodeObj = {}
	binaryNodeList.forEach((e) => {
		// This string defines how we categorize binaries as 'different'.
		// Note this is all within the context of the binary type this code is being run on from an input perspective.
		const binaryUniqueId = `${e.node.name}/${e.node.version}`;
		if (binaryNodeObj[binaryUniqueId] === undefined) {
			binaryNodeObj[binaryUniqueId] = {
				'name': e.node.name,
				'version': e.node.version,
				// items in BaremetalContents don't have a foundIn set of file paths
				'paths': (e.node.foundIn === undefined) ? [] : [e.node.foundIn.node.path]
			}
		} else {
			// For subsequent matches, append paths to group them:
			if (e.node.foundIn !== undefined) {
				binaryNodeObj[binaryUniqueId].paths.push(e.node.foundIn.node.path)
			}
		}
	});
	return Object.values(binaryNodeObj);
}

export const tableHeaders = ["Executables", "Libraries"]
export const tableColumns = ["Name", "Version", "Path(s)"]