import React from 'react'
import LinkButton from '../core/buttons/LinkButton'

class NewImageButton extends React.Component {
  render() {
   return (
     <LinkButton 
        icon='add'
        text='Submit Firmware'
        color='blue'
        path='/firmware/submit'
      />
   )
  }
}

export default NewImageButton
