import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core'

class DropdownMenu extends React.Component {

  state = {
    anchorEl: null
  }

  updateAnchorEl = (element) => {
    this.setState({anchorEl: element})
  }

  close = () => {
    this.setState({anchorEl: null})
  }

  render() {
    const { classes, name, icon, buttonText, color, buttonStyleObject, size } = this.props
    const open = Boolean(this.state.anchorEl)
    return (
      <React.Fragment>
        <Button 
          icon
          compact
          onClick={(event) => this.updateAnchorEl(event.currentTarget)}
          aria-owns={open ? name : undefined}
          aria-haspopup="true"
          style={(buttonStyleObject === undefined) ? {backgroundColor: 'transparent'} : buttonStyleObject}
        >
          { (icon !== undefined) ? <Icon 
            name={icon} 
            size={(size === undefined) ? null : size}
            inverted={(color === 'white') ? true : false}
            color={(color === 'white') ? null : color}
          /> : null }
          { (buttonText !== undefined) ? buttonText : null }
        </Button>
        <Menu 
          anchorOrigin={{vertical:'bottom', horizontal:'center'}} 
          id={name}
          disableAutoFocusItem 
          getContentAnchorEl={null} 
          anchorEl={this.state.anchorEl} 
          open={open} 
          onClose={this.close}
          className={classes.menu}
        >
          <div className={classes.menuBody}>
            {this.props.children}
          </div>
        </Menu>
      </React.Fragment>
    )
  }
}

// style
DropdownMenu = withStyles((theme) => ({
  menu: {
    marginTop: '10px',
    zIndex: 0,
  },
  menuBody: {
    minWidth: '200px', 
    display: 'flex', 
    flexDirection: 'column', 
    padding: '10px',
    alignItems: 'center'
  }
}))(DropdownMenu)

export default DropdownMenu