export default {
"__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "CertificateExtractedfromUnion",
        "possibleTypes": [
          {
            "name": "File"
          },
          {
            "name": "ImageContents"
          },
          {
            "name": "Kernel"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CryptographicKeyExtractedfromUnion",
        "possibleTypes": [
          {
            "name": "File"
          },
          {
            "name": "ImageContents"
          },
          {
            "name": "Kernel"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DirectoryContentsUnion",
        "possibleTypes": [
          {
            "name": "Directory"
          },
          {
            "name": "File"
          },
          {
            "name": "Link"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DynamicLibExecutableUnion",
        "possibleTypes": [
          {
            "name": "Executable"
          },
          {
            "name": "ExecutableScript"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DynamicLibAssociatedfindingsUnion",
        "possibleTypes": [
          {
            "name": "FindingGeneric"
          },
          {
            "name": "FindingVulnerableVersion"
          },
          {
            "name": "FindingCryptographicKey"
          },
          {
            "name": "FindingVulnerableFunctionCall"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ExecutableExecutableUnion",
        "possibleTypes": [
          {
            "name": "Executable"
          },
          {
            "name": "ExecutableScript"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ExecutableAssociatedfindingsUnion",
        "possibleTypes": [
          {
            "name": "FindingGeneric"
          },
          {
            "name": "FindingVulnerableVersion"
          },
          {
            "name": "FindingCryptographicKey"
          },
          {
            "name": "FindingVulnerableFunctionCall"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ExecutableScriptExecutableUnion",
        "possibleTypes": [
          {
            "name": "Executable"
          },
          {
            "name": "ExecutableScript"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ExecutableScriptAssociatedfindingsUnion",
        "possibleTypes": [
          {
            "name": "FindingGeneric"
          },
          {
            "name": "FindingVulnerableVersion"
          },
          {
            "name": "FindingCryptographicKey"
          },
          {
            "name": "FindingVulnerableFunctionCall"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FileSystemStartupcommandsUnion",
        "possibleTypes": [
          {
            "name": "ExecutableScript"
          },
          {
            "name": "Executable"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FindingCryptographicKeyVulnerableentityUnion",
        "possibleTypes": [
          {
            "name": "Certificate"
          },
          {
            "name": "CryptographicKey"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FindingGenericVulnerableentityUnion",
        "possibleTypes": [
          {
            "name": "Executable"
          },
          {
            "name": "DynamicLib"
          },
          {
            "name": "StaticLib"
          },
          {
            "name": "Kernel"
          },
          {
            "name": "FileContents"
          },
          {
            "name": "File"
          },
          {
            "name": "FileSystem"
          },
          {
            "name": "Certificate"
          },
          {
            "name": "CryptographicKey"
          },
          {
            "name": "ImageContents"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FindingVulnerableFunctionCallVulnerableentityUnion",
        "possibleTypes": [
          {
            "name": "Executable"
          },
          {
            "name": "ExecutableScript"
          },
          {
            "name": "DynamicLib"
          },
          {
            "name": "StaticLib"
          },
          {
            "name": "Kernel"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FindingVulnerableVersionVulnerableentityUnion",
        "possibleTypes": [
          {
            "name": "Executable"
          },
          {
            "name": "ExecutableScript"
          },
          {
            "name": "DynamicLib"
          },
          {
            "name": "StaticLib"
          },
          {
            "name": "Kernel"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ImageFindingsUnion",
        "possibleTypes": [
          {
            "name": "FindingGeneric"
          },
          {
            "name": "FindingVulnerableVersion"
          },
          {
            "name": "FindingCryptographicKey"
          },
          {
            "name": "FindingVulnerableFunctionCall"
          },
          {
            "name": "FindingOsConfiguration"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "LinkLinkstoUnion",
        "possibleTypes": [
          {
            "name": "File"
          },
          {
            "name": "Directory"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "StaticLibExecutableUnion",
        "possibleTypes": [
          {
            "name": "Executable"
          },
          {
            "name": "ExecutableScript"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "StaticLibAssociatedfindingsUnion",
        "possibleTypes": [
          {
            "name": "FindingGeneric"
          },
          {
            "name": "FindingVulnerableVersion"
          },
          {
            "name": "FindingCryptographicKey"
          },
          {
            "name": "FindingVulnerableFunctionCall"
          }
        ]
      }
    ]
  }
}