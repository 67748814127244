import _ from 'lodash'
import React from 'react'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import { Divider } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../graphql/core/connectors'
import SubmissionsTable from '../../../components/composite/SubmissionsTable'
import { withRBAC } from '../../../components/core/access/RBAC'
import Page from '../../../components/core/layout/Page'
import ScrollSpace from '../../../components/core/layout/ScrollSpace'

class FirmwareTab extends React.Component {

  goto = (firmwareId) => {
    this.props.history.push(`/firmware/${firmwareId}`)
  }

  render() {
    const submissions = this.props.data
    const rows = _.orderBy(submissions, [s => s.dateSubmitted], ['desc'])
    return (
      <Page>
        <h2>Firmware</h2>
        <Divider hidden />
        <ScrollSpace plain={true}>
          <SubmissionsTable data={rows} />
          <div style={{marginTop: 50}} />
        </ScrollSpace>
      </Page>
    )
  }
}

// routing
FirmwareTab = withRouter(FirmwareTab)

// data
FirmwareTab = withGraphqlQuery({
  query: gql`
    query {
      Submission {
        id
        errors
        warnings
        productName
        productVersion
        dateSubmitted
        user {
          id
          node {
            id
            name
          }
        }
        image {
          id
          node {
            id
            status
            inputFilename
            size
          }
        }
      }
    }
  `,
  onData: (data) => data.Submission
})(FirmwareTab)

// access control
FirmwareTab = withRBAC('admin')(FirmwareTab)

export default FirmwareTab
