import React from 'react'
import FileSystemSelector from '../../../../components/wrappers/FileSystemSelector'
import Page from '../../../../components/core/layout/Page'
import NetworkDisplay from './NetworkDisplay'

class NetworkTab extends React.Component {

  state = {
    executableId: null
  }

  setExecutableId = (executableId) => {
    this.setState({executableId})
  }

  render() {
    return (
      <Page>
        <FileSystemSelector submissionId={this.props.match.params.imageId} showOnlyTypes={['File System']}>
          {({containerId}) => {
            return (
              <NetworkDisplay key={containerId} filesystemId={containerId} />
            )
          }}
        </FileSystemSelector>
      </Page>
    )
  }
}

export default NetworkTab
