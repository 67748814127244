import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import { withStyles } from '@material-ui/core'
import PageRoot from '../../../components/core/layout/PageRoot'
import { withGraphqlQuery } from '../../../graphql/core/connectors';
import SummaryTab from './summary'
import FindingsTab from './findings'
import ContentsTab from './contents'
import LibrariesTab from './libraries'
import ExecutablesTab from './executables'
import SbomTab from './sbom'
import NetworkTab from './network'
import ExecutableScripts from './executable-scripts'
import FilesystemsTab from './filesystems'
import StringsTab from './strings'
import CertificatesTab from './certificates'
import StartupTab from './startup'
import BinaryHardeningTab from './binary-hardening'
import TasksTab from './tasks'
import PageNotFound from '../../../components/core/errors/PageNotFound'
import { 
  isAnalysisFinished,
  computeFirmwareVariables,
  computeFirmwarePollStopCheckForSubmissionSingle
} from '../../../utils/firmware'
import Sidebar from './sidebar';


class ImageExplorer extends React.Component {

  render() {
    const { data, userProfile } = this.props
    const submission = data
    if (data === undefined || data === null) {
      return <PageNotFound />
    }

    // Note until the analysis has finished (e.g., 'terminated === true'), the Sidebar will display only
    // a few pages for the users to access as most pages require a stable, completed, status.
    const terminated = isAnalysisFinished(submission.image.node.status);

    // TODO: Remove other routes from switch statement till they are supposed to be available!
    return (
      <div style={{display: 'flex', flexDirection: 'row', flexGrow: 0}}>
        <div style={{height: '100vh', paddingBottom: 50 }}>
          <Sidebar data={submission} userProfile={userProfile} analysisTerminated={terminated} />
        </div>
        <PageRoot>
          <Switch>
            <Route path='/firmware/:imageId/summary' exact={true} component={SummaryTab} />
            <Route path='/firmware/:imageId/findings' exact={true} component={FindingsTab} />
            <Route path='/firmware/:imageId/contents' exact={true} component={ContentsTab} />
            <Route path='/firmware/:imageId/software-composition' exact={true} component={SbomTab} />
            <Route path='/firmware/:imageId/binary-hardening' exact={true} component={BinaryHardeningTab} />
            <Route path='/firmware/:imageId/executables' exact={true} component={ExecutablesTab} />
            <Route path='/firmware/:imageId/executable-scripts' exact={true} component={ExecutableScripts} />
            <Route path='/firmware/:imageId/libraries' exact={true} component={LibrariesTab} />
            <Route path='/firmware/:imageId/startup' exact={true} component={StartupTab} />
            <Route path='/firmware/:imageId/network' exact={true} component={NetworkTab} />
            <Route path='/firmware/:imageId/filesystems' exact={true} component={FilesystemsTab} />
            <Route path='/firmware/:imageId/strings' exact={true} component={StringsTab} />
            <Route path='/firmware/:imageId/certificates' exact={true} component={CertificatesTab} />
            <Route path='/firmware/:imageId/tasks' exact={true} component={TasksTab} />
            <Redirect to={`/firmware/${submission.id}/summary`} />
          </Switch>
        </PageRoot>
      </div>
    )
  }
}

// routing
ImageExplorer = withRouter(ImageExplorer)

// state
ImageExplorer = connect(
  (state) => {
    const { userProfile } = state
    return { userProfile: userProfile }
  },
)(ImageExplorer)

// data
ImageExplorer = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        productName
        productVersion
        user {
          id
          node {
            id
            name
          }
        }
        image {
          id
          node {
            id
            status
            inputFilename
          }
        }
      }
    } 
  `,
  variables: computeFirmwareVariables,
  pollStopCheck: computeFirmwarePollStopCheckForSubmissionSingle,
  pollInterval: 1000 * 30,
  onData: (data) => { return data.Submission[0] }
})(ImageExplorer)

// state
ImageExplorer = connect(
  (state) => {
    const { userProfile } = state
    return { userProfile: userProfile }
  }
)(ImageExplorer)

// styling
ImageExplorer = withStyles((theme) => ({
  header: {
    minHeight: '48px', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
  },
  status: {
    width: '120px'
  },
  backButton: {
    minWidth: 30,
    width: 30,
    padding: 0,
    marginRight: 10
  }
}))(ImageExplorer)

export default ImageExplorer
