import React from 'react'
import gql from 'graphql-tag'
import { withStyles, Typography, Box, List, ListItem } from '@material-ui/core'
import { Icon } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import VerticalSpace from '../../../../components/core/layout/VerticalSpace'
import PageSplit from '../../../../components/core/layout/PageSplit'
import SimpleTable from '../../../../components/core/tables/SimpleTable'
import DataNoResults from '../../../../components/messages/DataNoResults'
import PopupCodeDialog from '../../../../components/messages/PopupCodeDialog'


class KeysPane extends React.Component {
  constructor(props) {
    super()
    this.cryptoKeysInSubmission = props.data.image.node.cryptokeys.map((ckrel) => ckrel.node);
    this.state = {
      selectedKeyId: undefined
    }
  }

  render() {
    const { classes } = this.props
    const { selectedKeyId } = this.state

    if (Object.values(this.cryptoKeysInSubmission).length === 0) {
      return <DataNoResults type='Keys' />
    }

    // find selected key
    const selectedKey = (selectedKeyId !== undefined) ? this.cryptoKeysInSubmission.find(c => (c.id === selectedKeyId)) : null;
    
    return (
      <Box className={classes.section}>
        <Typography className={classes.text}><Icon name='key' /> Keys:</Typography>
        <div>
          <PageSplit>
            <ScrollSpace>
              <List>
                {
                  this.cryptoKeysInSubmission.map((ck) => {
                    let bitsString = (ck.bits !== null) ? `${ck.bits}-bits, ` : '';
                    if (ck.encrypted !== null) {
                      bitsString += 'encrypted, ';
                    }
                    return (<ListItem key={ck.id} button
                      selected={selectedKeyId === ck.id}
                      onClick={(event) => this.setState({selectedKeyId: ck.id})}
                    >
                      {`${ck.type.toUpperCase()} ${(ck.private) ? 'Private' : 'Public'} Key, ${bitsString}from ${ck.extractedFrom.node.path || ck.extractedFrom.node.name}`}
                    </ListItem>)
                  })
                }
              </List>
            </ScrollSpace>
            <VerticalSpace />
            <ScrollSpace>
              <div style={{padding: 10}}>
              { (selectedKey === null) ? (
                <div>Please Select Key</div>
              ) : (
                <SimpleTable
                  order={['Type', 'Component', 'Bit Length', 'Validated', 'Encrypted', 'Found In', 'Key']}
                  fields={{
                    'Type': selectedKey.type.toUpperCase(),
                    'Component': (selectedKey.private) ? 'Private' : 'Public',
                    'Bit Length': selectedKey.bits,
                    'Validated': (selectedKey.validated) ? 'Yes' : 'No',
                    'Encrypted': (selectedKey.encrypted) ? 'Yes' : 'No',
                    // TODO: Improve display when populated
                    //'Related Certificate': (selectedKey.associatedCertificate.length === 0) ? 'Not found' : selectedKey.associatedCertificate.map((c) => c.node.subject),
                    // TODO: Could use typename also and render in a pillbox
                    'Found In': selectedKey.extractedFrom.node.path || selectedKey.extractedFrom.node.name,
                    'Key': (selectedKey.pemKey !== null && selectedKey.pemKey !== '') ? <PopupCodeDialog openButtonText='View' title='Key in PEM Encoding' codeText={selectedKey.pemKey} /> : 'Not extracted.'
                  }}
                />
              )}
              </div>
            </ScrollSpace>
          </PageSplit>
        </div>
      </Box>
    )
  }
}

// style
KeysPane = withStyles((theme) => ({
  section : {
    marginTop: '10px',
    padding: '10px',
    maxHeight: '50%',
    height: '50%',
    marginBottom: '10px',
  },
  text: {
    fontSize: '18px',
    marginRight: '10px'
  },
}))(KeysPane)

// data
KeysPane = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        image {
          id
          node {
            id
            cryptokeys {
              node {
                id
                sha256
                private
                pemKey
                type
                bits
                valid
                encrypted
                comment
                options
                associatedCertificate {
                  id
                  node {
                    id
                    subject
                  }
                }
                extractedFrom {
                  id
                  node {
                    __typename
                    ... on File {
                      id
                      path
                    }
                    ... on ImageContents {
                      id
                      name
                    }
                    ... on BaremetalContents {
                      id
                      name
                    }
                    ... on Kernel {
                      id
                      name
                    }
                    ... on ConfigContents {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  variables: (props) => ({
    input: {id: props.submissionId}
  }),
  onData: (data) => data.Submission[0]
})(KeysPane)

export default KeysPane
