import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Step, Icon, Loader } from 'semantic-ui-react'
import PageSection from '../../../../../components/core/page/PageSection'
import { RBAC } from '../../../../../components/core/access/RBAC'
import { isAnalysisFinished } from '../../../../../utils/firmware'

class StageStatus extends React.Component {
  render() {
    const { status } = this.props
    switch (status) {
      case 'RUNNING':
        return (
          <div style={{paddingRight: 10}}>
            <Loader active inline style={{paddingRight: 10}} />
          </div>
        )
      case 'COMPLETE':
        return <Icon name='checkmark' color='green' />
      case 'NOT STARTED':
        return <Icon name='hourglass start' color='grey' />
      case 'IN PROGRESS':
        return <Icon name='spinner' color='grey' loading />
      default:
        return <Icon name='question' color='grey' />
    }
  }
}

const stageTitles = {
  1: "Extracting Firmware",
  2: "Analyzing Contents",
  3: "Creating Findings"
}

class CountLabel extends React.Component {
  render() {
    const {icon, text, count, color} = this.props
    return (
      <div style={{
        background: 'rgb(248,248,249)', 
        borderRadius: 5, 
        borderStyle: 'solid', 
        borderWidth: '1px', 
        borderColor: 'rgba(201,202,202)', 
        display: 'flex', 
        padding: 10,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        margin: 5
        }}>
        <Icon name={icon} color={color} />
        <div>{text}: {count}</div>
      </div>
    )
  }
}

class ProgressSummary extends React.Component {
  /*
   * This component should never be rendered in a canceled or completed state, as otherwise the logic won't 
   * reflect accurately.
   */
  render() {
    const submission = this.props.data
    const activeStage = submission.activeStage;
    const terminated = isAnalysisFinished(submission.image.node.status);
    const stages = [1,2,3].map(index => {
      const status = ((activeStage > index) || (index === 3 && activeStage === 3 && terminated)) ? 'COMPLETE' :
                     (activeStage < index) ? 'NOT STARTED' : 'IN PROGRESS';
      return {status, title: stageTitles[index]}
    })

    return (
      <PageSection title='Analysis Progress' >
        <div style={{display: 'flex', flexDirection: 'column', maxWidth: 800}}>
          <Step.Group size='tiny' >
          { stages.map((stage) => {
            return (
              <Step key={stage.title}>
                <StageStatus status={stage.status} />
                <Step.Content>
                  <Step.Title>{stage.title}</Step.Title>
                  <Step.Description>
                    <Typography>{stage.status}</Typography>
                  </Step.Description>
                </Step.Content>
              </Step>
            )
          })}
          </Step.Group>
        </div>
        <RBAC roles='admin' silent={true}>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            {(submission.errors !== null) ?
              <CountLabel count={submission.errors} color='red' icon='exclamation triangle' text='Errors' /> : ''}
            {(submission.warnings !== null) ? 
              <CountLabel count={submission.warnings} color='orange' icon='bell' text='Warnings' /> : ''}
          </div>
        </RBAC>
      </PageSection>
    )
  }
}

export default ProgressSummary
