import React from 'react'
import SummaryTile from '../../../../../components/tiles/SummaryTile'
import PageSection from '../../../../../components/core/page/PageSection'
import DataNoResults from '../../../../../components/messages/DataNoResults'

class ContentsSummary extends React.Component {
  render() {
    const submission = this.props.data
    const image = submission.image.node
    const filesystemCount = image.filesystems.length
    const kernelsCount = image.kernels.length
    const cryptographicDataCount = image.cryptographicData.length
    const baremetalContentsCount = image.baremetalContents.length
    const configContentsCount = image.configContents.length
    const totalCount = filesystemCount + kernelsCount + cryptographicDataCount + baremetalContentsCount + configContentsCount
    return (
      <PageSection title='Contents Summary' linkUrl={`/firmware/${submission.id}/contents`} >
        {(totalCount === 0) ? (
          <DataNoResults />
        ) : (
          <div style={{display: 'flex', flexDirection: 'row'}} >
            { (filesystemCount > 0) ? (
              <SummaryTile
                text='Filesystems'
                count={filesystemCount.toString()}
                icon='folder'
              />
            ) : null}
            { (kernelsCount > 0) ? (
              <SummaryTile
                text='Kernels'
                count={kernelsCount.toString()}
                icon='microchip'
              />
            ) : null}
            { (cryptographicDataCount > 0) ? (
              <SummaryTile
                text='Cryptographic Data'
                count={cryptographicDataCount.toString()}
                icon='unlock alternate'
              />
            ) : null}
            { (baremetalContentsCount > 0) ? (
              <SummaryTile
                text='Baremetal Contents'
                count={baremetalContentsCount.toString()}
                icon='microchip'
              />
            ) : null}
            { (configContentsCount > 0) ? (
              <SummaryTile
                text='Config Contents'
                count={configContentsCount.toString()}
                icon='file alternate outline'
              />
            ) : null}
          </div>
        )}
      </PageSection>
    )
  }
}

export default ContentsSummary
