import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'

class LinkButton extends React.Component {
  render() {
    const { path, color, icon, text } = this.props
   return (
    <Link to={path}>
      <Button color={color}>
        <Icon name={icon}/>
        {text}
      </Button>
    </Link>
   )
  }
}

export default LinkButton
