import _ from 'lodash'
import React from 'react'
import gql from 'graphql-tag'
import { basename } from 'path'
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import { Icon, Table } from 'semantic-ui-react'
import DataNoResults from '../../../../components/messages/DataNoResults'

class ExecutablesTable extends React.Component {
  render() {
    const { selectedExecutableId, setExecutableId } = this.props
    const filesystem = this.props.data
    const rows = filesystem.executables.map(r => r.node)

    if (rows.length === 0) {
      return <DataNoResults msg='No executables found in this file system.' />
    }

    const sortFunctionList = [
      (x) => {return -x.associatedFindings.length},
      (x) => {return x.version},
      (x) => {return (x.foundIn.node.path === undefined) ? -1 : x.foundIn.node.path}
    ];

    return (
      <Table singleLine={false}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Executable
            </Table.HeaderCell>
            <Table.HeaderCell>
              Version
            </Table.HeaderCell>
            <Table.HeaderCell style={{width: '80px', maxWidth: '80px'}}>
              Analyzed
            </Table.HeaderCell>
            <Table.HeaderCell style={{width: '80px', maxWidth: '80px'}}>
              Findings
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { _.sortBy(rows, sortFunctionList).map(row => {
            const active = (selectedExecutableId === row.id)
            const findingsCount = row.associatedFindings.length
            const foundInType = row.foundIn.node.__typename
            return (
              <Table.Row key={row.id} active={active} onClick={() => setExecutableId(row.id)}>
                <Table.Cell collapsing style={{fontFamily: 'monospace'}}>
                  {(foundInType === 'File') ? basename(row.foundIn.node.path) : row.foundIn.node.name}
                </Table.Cell>
                <Table.Cell>
                  {row.version}
                </Table.Cell>
                <Table.Cell>
                  { (row.location.filter(r => (r.type === 'BNDB')).length !== 0)
                    ? <Icon name='checkmark' color='green'/> 
                    : null
                  }
                </Table.Cell>
                <Table.Cell>
                  {(findingsCount !== 0) ? (
                    <React.Fragment>
                      <Icon name='warning sign' color='red' />
                      {findingsCount}
                    </React.Fragment>
                  ) : null}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }
}

// data
ExecutablesTable = withGraphqlQuery({
  query: gql`
    query FileSystem($input: FileSystemMatchInput) {
      FileSystem(input: $input) {
        id
        executables {
          id
          node {
            id
            name
            version
            date
            location {
              id
              type
            }
            foundIn {
              id
              node {
                __typename
                ... on File {
                  id
                  path
                }
                ... on ImageContents {
                  id
                  name
                }
                ... on BaremetalContents {
                  id
                  name
                }
                ... on Kernel {
                  id
                  name
                }
              }
            }           
            associatedFindings {
              id
            }
          }
        }
      }
    } 
  `,
  //pollInterval: 5000,
  variables: (props) => ({input: {id: props.filesystemId}}),
  onData: (data) => data.FileSystem[0]
})(ExecutablesTable)

export default ExecutablesTable