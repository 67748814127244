import gql from 'graphql-tag'

const findUser = async (client, name) => {
  const results = await client.query({
    query: gql`query User($input: UserMatchInput) {
      User(input: $input) {id name}
    }`,
    variables: {input: {name}}
  })
  return results
}

export default findUser;
