import React from 'react'
import gql from 'graphql-tag'
import { Message } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
//import { formatHex } from '../../../../utils/format'


class BootloaderFileContentsRows extends React.Component {
  render() {
    return (
      this.props.fileContents.map((fcRel, i) => {
        // also have fcRel.extraction we could use
        return (
          <TableRow key={i}>
            <TableCell>{fcRel.node.type}</TableCell>
            <TableCell style={{ wordWrap: 'break-word', maxWidth: '800px', fontFamily: 'monospace' }}>{fcRel.node.value}</TableCell>
          </TableRow>
        )
      })
    )
  }
}

class BootloaderInfoDisplay extends React.Component {
  constructor(props) {
    super()
    // filter to only 'u-boot config section' data, else set this.bootloaderInfo to null
    const imageData = props.data.image.node
    this.bootloaderInfo = null
    if (imageData.configContents.length > 0) {
      const bootloaderContents = imageData.configContents.filter(cc => cc.node.name === 'u-boot config section')
      if (bootloaderContents.length > 0) {
        bootloaderContents.sort((a, b) => a.offset - b.offset);
        this.bootloaderInfo = bootloaderContents
      }
    }
  }

  render() {
    if (this.bootloaderInfo === null) {
      return (
        <Message>
          <Message.Header>No bootloader configuration was found in this firmware</Message.Header>
          <p>
            This may happen if the submitted firmware does not include a supported bootloader.
          </p>
          <p>
            If you believe the firmware contains a common bootloader, please contact us for assistance.
          </p>
        </Message>
      )
    }
    
    return (
      <Table size="small">
        <TableBody>
        {this.bootloaderInfo.map((cc) => {
          // we don't include at formatHex(cc.offset) in the heading cell as uboot desc includes it
          return (
            <React.Fragment key={cc.id}>
              <TableRow>
                <TableCell colSpan={2} component="th">{cc.node.desc}</TableCell>
              </TableRow>
              <BootloaderFileContentsRows fileContents={cc.node.filecontents} />
            </React.Fragment>
          )
        })}
        </TableBody>
      </Table>
    )
  }
}

// data
// NOTE: this will return both 'bcm nvram' data which we don't want to show here,
//       as well as our 'u-boot config section' names that we do want to display
BootloaderInfoDisplay = withGraphqlQuery({
  query: gql`
  query Submission($input: SubmissionMatchInput) {
    Submission(input: $input) {
      id
      image {
        id
        node {
          id
          status
          configContents {
            id
            offset
            node {
              id
              name
              desc
              filecontents {
                id
                node {
                  id
                  type
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  `,
  variables: (props) => ({
    input: {id: props.submissionId}
  }),
  onData: (data) => data.Submission[0]
})(BootloaderInfoDisplay)

export default BootloaderInfoDisplay
