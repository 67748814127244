import gql from 'graphql-tag'

const downloadImage =  async (client, imageId) => {
  //console.log('>>>>')
  const results = await client.query({
    query: gql`
      query Image($input: ImageMatchInput) {
        Image(input: $input) {
          id
          downloadUrl
        }
      }
    `,
    variables: { input: {id: imageId} }
  }).catch((error) => {
    console.error(error)
    return null
  })

  if (results === null) {
    // TODO: propagate error up to UI
    return
  }

  const imageUrl = results.data.Image[0].downloadUrl;
  const link = document.createElement('a');
  link.href = imageUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default downloadImage;