import React from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';

class Loading extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <CircularProgress />
        <Typography className={classes.text}>Loading</Typography>
      </div>
    )
  }
}

// styling
Loading = withStyles((theme) => ({
  root: {
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  text: {
    fontSize: 16,
    fontWeight: 700,
  }
}))(Loading)


export default Loading