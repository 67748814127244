import React from 'react'
import { withRouter } from 'react-router-dom'
import { ApolloConsumer } from '@apollo/react-common'
import { Modal, Button } from 'semantic-ui-react';
import gql from 'graphql-tag'
import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import MButton from '@material-ui/core/Button';
import View from '../../../components/core/layout/View'
import ViewContent from '../../../components/core/layout/ViewContent'
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Row from '../../../components/core/layout/Row'
import {connect} from 'react-redux'

const SUBMIT_IMAGE_MUTATION = gql`
mutation SubmitImage($input: SubmitImageToStorageInput) {
  SubmitImageToStorage(input: $input) {
    postUrl
    submission {
      id
      productName
      productType
      productVersion
      image {
        id
        node {
          id
          inputFilename
          size
          sha256
          status
        }
      }
    }
  }
}
`

const RUN_PIPELINE_MUTATION = gql`
mutation RunPipeline($input: RunPipelineInput) {
  RunPipeline(input: $input) {
    id
    productName
    productType
    productVersion
    image {
      id
      node {
        id
        inputFilename
        size
        sha256
        status
      }
    }
  }
}
`

const DELETE_PIPELINE_MUTATION = gql`
mutation DeletePipeline($input: DeletePipelineInput) {
  DeletePipeline(input: $input)
}`


const PROGRESS_SUBMIT_IMAGE = 5;
const PROGRESS_UPLOAD_DONE = 95;


let SubmitDialog = (props: any) => {
  const { uploading, classes } = props;
  return (
    <Modal
      open={uploading !== false}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Header>Uploading Firmware</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <p>Please wait until the firmware upload is complete.</p>
          <LinearProgress className={classes.progress} variant="determinate" value={(uploading === true) ? 100 : uploading} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
      </Modal.Actions>
    </Modal>
  )
}


class ImageSubmit extends React.Component {
  state = {
    file: null,
    product: '',
    version: '',
    message: null,
    size: '',
    debounce: false,
    uploading: false,  // false, and then during upload, int showing progress, true on completed upload
    submissionNode: null,  // set to the node object once Submission put into graph
    runWithDisassembly: true,
  }

  handleFileSelected = (event) => {
    const file = event.target.files[0]
    this.setState({ size: file.size, file })
  }

  handleChange = (name) => {
    return (event) => {
      this.setState({ [name]: event.target.value })
    }
  }

  toggle = (name) => {
    return (event) => {
      this.setState({ [name]: !this.state[name] })
    }
  }

  clearForm = () => {
    this.setState({
      file: null,
      product: '',
      version: '',
      size: ''
    })
  }

  handleFailedFileUpload = async (client, err) => {
    // Remove navigation prompt
    window.onbeforeunload = null;
    if (err !== undefined && err.message.startsWith('NetworkError')) {
      this.setState({ message: 'Upload failed due to a network error.', uploading: false })
    } else {
      this.setState({ message: 'Upload failed.', uploading: false })
    }
    // In this state, there is a submission that is always in the uploading state, here we kill it:
    if (this.state.submissionNode !== null) {
      const result = await client.mutate({
        mutation: DELETE_PIPELINE_MUTATION,
        variables: {
          input: {
            submissionId: this.state.submissionNode.id
          }
        }
      })
      console.log(`Delete pipeline (Submission ${this.state.submissionNode.id}) result ${result.data.DeletePipeline}`)
      // NOTE: setting debounce to false allows us to use the submit button again:
      this.setState({ submissionNode: null, debounce: false })
    } else {
      console.error("Unexpected state, submissionNode should always be set at this point.")
    }
  }

  handleXhrProgressUpdate = (event) => {
    const barPosition = (event.loaded/event.total)*(PROGRESS_UPLOAD_DONE-PROGRESS_SUBMIT_IMAGE);
    console.info(`Uploaded ${event.loaded} of ${event.total} bytes: ${barPosition}`);
    this.setState({ uploading: PROGRESS_SUBMIT_IMAGE+barPosition })
  }

  uploadImageFile = async (postUrl, file, client) => {
    console.log(`uploading image to ${postUrl}`)
    // Enable navigation prompt
    window.onbeforeunload = function () {
      return true;
    };
    try {
      // NOTE: XHR is used to be able to get upload status updates
      let xhr = new XMLHttpRequest();
      xhr.upload.onprogress = this.handleXhrProgressUpdate
      //xhr.upload.onload = function() {
      //  console.info(`Upload finished successfully.`);
      //};
      // NOTE: As not awaited on, this may call after other code in submit is
      xhr.upload.onerror = async () => {
        console.info(`Error during upload.`);
        await this.handleFailedFileUpload(client);
      };
      xhr.open('PUT', postUrl)
      xhr.setRequestHeader('Content-Type', 'octet/stream');
      //xhr.setRequestHeader('Access-Control-Request-Method', 'PUT');
      xhr.send(file)
      xhr.onload = async () => {
        if (xhr.status !== 200) {
          console.error(`Error ${xhr.status}: ${xhr.statusText}`);
          await this.handleFailedFileUpload(client);
        } else { // response to the PUT received
          console.info("Completed upload successfully.");
          await this.completedUploadHandler(client);
        }
      };
      //xhr.onerror = this.handleXhrOnError
    } catch (err) {
      console.error('Upload exception:', err);
      await this.handleFailedFileUpload(client, err);
      return
    }
  }

  /*
   * This is called after the XHR upload has finished, to cause the pipeline to start
   * and the UI to update accordingly.
   */
  completedUploadHandler = async (client) => {
    // Remove navigation prompt
    window.onbeforeunload = null;
    if (this.state.submissionNode !== null) {
      this.setState({ uploading: PROGRESS_UPLOAD_DONE })
      // run pipeline
      console.info("Sending request for starting pipeline", this.state.submissionNode.id)
      await client.mutate({
        mutation: RUN_PIPELINE_MUTATION,
        variables: {
          input: {
            submissionId: this.state.submissionNode.id,
            disassemblyEnabled: this.state.runWithDisassembly
          }
        }
      })
      this.setState({ uploading: true })  // triggers UI to load to dest page
      this.clearForm()
    } else {
      console.error("Unexpected state, submissionNode should always be set at this point.")
    }
  }

  submit = async (client) => {
    // If already clicked, and in progress, ignore other clicks:
    if (this.state.debounce) {
      return
    }

    this.setState({ message: null, debounce: true, uploading: 0 })
    const { file, product, version } = this.state
    if (file === null) {
      this.setState({ message: 'Must select firmware file', uploading: false })
      return
    }
    if (product === '' || version === '') {
      this.setState({ message: 'Missing required fields', uploading: false })
      return
    }
    // TODO: Client side size validation on file.size, if server side also implements

    // create submission
    const results = await client.mutate({
      mutation: SUBMIT_IMAGE_MUTATION,
      variables: {
        input: {
          productName: product,
          productVersion: version,
          imageFilename: file.name,
          imageSize: file.size,
          // TODO: Deprecate the below:
          productType: 'DEPRECATED'
        }
      }
    })
    const submissionNode = results.data.SubmitImageToStorage.submission
    this.setState({ uploading: PROGRESS_SUBMIT_IMAGE, submissionNode })

    // upload image to storage
    const { postUrl } = results.data.SubmitImageToStorage
    // NOTE: Despite the await, this doesn't block on the upload finishing, as there is async inside:
    await this.uploadImageFile(postUrl, file, client);
  }

  render() {
    const { classes, history, userProfile } = this.props
    const { file, product, version, uploading, message, submissionNode, runWithDisassembly } = this.state

    let valid = true
    if (file === null) valid = false
    if (product === '') valid = false
    if (version === '') valid = false

    if (message === null && uploading === true && submissionNode !== null) {
      // redirect to the submitted image page
      history.push(`/firmware/${submissionNode.id}`)
    }

    const showDisassemblyToggle = (
      userProfile
      && userProfile.disassemblyEnabled
      && (
          userProfile.role === 'admin'
          || userProfile.name.endsWith('@riverloopsecurity.com')
          || userProfile.name.endsWith('@twosixtech.com')
    ));

    return (
      <ApolloConsumer>
        { (client) => (
          <View>
            <ViewContent>
              <Typography variant='h4'>Submit Firmware for Analysis</Typography>
              <Divider style={{ marginBottom: 30 }} />
              <Paper className={classes.paper}>
                <MButton variant="outlined" fullWidth color="primary" component="label">
                  {(file === null) ? 'Select File' : file.name}
                  <input type='file' onChange={this.handleFileSelected} hidden />
                </MButton>
                <div className={classes.form}>
                  <TextField
                    label="Product"
                    className={classes.textField}
                    value={product}
                    onChange={this.handleChange('product')}
                    margin="dense"
                    required={true}
                  />
                  <div style={{ width: 20 }} />
                  <TextField
                    label="Version"
                    className={classes.textField}
                    value={version}
                    onChange={this.handleChange('version')}
                    margin="dense"
                    required={true}
                  />
                  <TextField
                    label="Size"
                    className={classes.textField}
                    value={this.state.size}
                    placeholder=''
                    margin="dense"
                    disabled
                  />
                  {(showDisassemblyToggle) ? (
                      <Row>
                        <Checkbox
                            id="runWithDisassembly"
                            checked={runWithDisassembly}
                            onChange={(e) => {
                              this.setState({runWithDisassembly: e.target.checked})
                            }}
                            value="checked"
                        />
                        <InputLabel htmlFor="runWithDisassembly"> Perform Advanced Disassembly </InputLabel>
                      </Row>
                  ) : <></>}
                </div>
              </Paper>
              {(message !== null) ? (
                <Paper className={classes.errorMessageBox}>
                  {message}
                </Paper>
              ) : <div style={{ height: 20 }} />}



              {(uploading === false) ? (
                <Button className={classes.button}
                  onClick={() => this.submit(client)}
                  disabled={!valid}
                  primary
                  variant={'contained'}>
                  Submit for Analysis
                </Button>
              ) : (
                  <Button className={classes.button}
                    disabled
                    variant={'contained'}>
                    Uploading
                  </Button>
                )}
              {(uploading !== false) ? (
                <SubmitDialog uploading={uploading} classes={classes} />
              ) : null}
            </ViewContent>
          </View>
        )}
      </ApolloConsumer>
    )
  }
}

// styling
ImageSubmit = withStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    fontSize: 12,
  },
  menu: {
    width: 200,
  },
  column: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  expandableHeader: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer'
  },
  paper: {
    marginTop: 10,
    padding: 20,
    maxWidth: 500
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    maxWidth: 500
  },
  progress: {
    marginTop: 20,
    maxWidth: 500
  },
  errorMessageBox: {
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    backgroundColor: '#B81D13',
    color: 'white',
    maxWidth: 500
  }
}))(ImageSubmit)

// state
ImageSubmit = connect(
  (state) => {
    const { userProfile } = state
    return { userProfile: userProfile }
  },
)(ImageSubmit)

// routing
ImageSubmit = withRouter(ImageSubmit)

export default ImageSubmit
