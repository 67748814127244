import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal';


export function TermsOfServiceModal() {
  const style = {
    position: 'absolute',
    width: '80%',
    backgroundColor: '#ddd',
    border: '2px solid #000',
    padding: '5px',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <a href="#" onClick={handleOpen}>
        Terms of Service
      </a>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div style={style}>
          <TermsOfService />
        </div>
      </Modal>
    </React.Fragment>
  );
}


class TermsOfService extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Typography variant='h3'>Pilot Security Terms of Service</Typography>
        <Typography variant='subtitle2'>Last updated: February 29, 2021</Typography>
        <Typography variant="body1" gutterBottom>
          Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the http://pilot-security.com website (the "Service") operated by Pilot Security Inc ("us", "we", or "our"). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Other than content you own, which you may have opted to include or upload on this Website, under these Terms, Pilot Security Inc and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website. We ask that you do not upload personally identifiable information (PII) or protected health information (PHI).
        </Typography>
        <Typography variant="body1" gutterBottom>
          In these Terms And Conditions, “Your Content” shall mean any text, data, or other files or materials you choose to upload on this Website. With respect to Your Content, by uploading it, you grant Pilot Security Inc a non-exclusive, worldwide, irrevocable license to use, decompile, reverse engineer, and analyze it for the purpose of discovering cybersecurity vulnerabilities. Your Content must not be infringing on any third party’s rights. Pilot Security Inc reserves the right to delete any of Your Content from this Website at any time, and for any reason, without notice.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our Service may contain links to third-party web sites or services that are not owned or controlled by Pilot Security Inc. Pilot Security Inc has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Pilot Security Inc shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
        </Typography>
        <Typography variant="body1" gutterBottom>
          This Website is provided “as is,” with all faults, and Pilot Security Inc makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.
        </Typography>
        <Typography variant="body1" gutterBottom>
          In no event shall Pilot Security Inc, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Pilot Security Inc, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 15 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Pilot Security Inc shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Pilot Security Inc and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms shall be governed and construed in accordance with the laws of Maryland, United States, without regard to its conflict of law provisions.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions about these Terms, please contact us.
        </Typography>
      </div>
    )
  }
}

// style
TermsOfService = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}))(TermsOfService)

export default TermsOfService