import React from 'react'
import { Paper } from '@material-ui/core'

class ScrollSpace extends React.Component {
  render() {
    if (this.props.plain) {
      return (
        <div style={{ flex: 1, overflowY: 'scroll', marginBottom: 50}}>
          {this.props.children}
        </div>
      )
    } else {
      return (
        <Paper style={{ flex: 1, overflowY: 'scroll', marginBottom: 50}}>
          {this.props.children}
        </Paper>
      )

    }
  }
}

export default ScrollSpace