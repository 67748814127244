import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import firebase from 'firebase/app'
import 'firebase/auth'

// TODO: pass function to select domain
export const createClient = (schema) => {

  if (schema === undefined) {
    schema = {"__schema": []}
  }

  // determine api url
  let apiUrl
  if (process.env.NODE_ENV === 'development') {
    apiUrl = process.env.REACT_APP_PILOT_API_URL
    if (!apiUrl) {
      apiUrl = 'http://localhost:80/api/graphql'
    }

  } else {
    const projectId = firebase.app().options.projectId
    let apiDomain
    switch (projectId) {
      case 'pilot-security-staging':
        apiDomain = 'https://api.staging.pilot-security.com/api/graphql'
        break
      case 'pilot-security-preview':
        apiDomain = 'https://api.preview.pilot-security.com/api/graphql'
        break
      case 'pilot-security-prod':
        apiDomain = 'https://api.app.pilot-security.com/api/graphql'
        break
      default:
        apiUrl = null
        console.error(`Unable to locate API URL for ${projectId}`)
        // TODO: display this somehow in the UI
        break
    }
    apiUrl = apiDomain
  }

  // build apollo auth link 
  const authLink = setContext( async (request, previousContext) => {
    const token = await firebase.auth().currentUser.getIdToken()
    return {
      headers: { ...previousContext.headers, authentication: token, client: 'pilot-webapp' }
    }
  });

  // build apollo fragment matcher to differentiate union types
  const introspectionQueryResultData = schema
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })

  // return apollo client
  return new ApolloClient({
    cache: new InMemoryCache({fragmentMatcher}),
    link: authLink.concat(createUploadLink({fetch, uri: apiUrl})),
  })
}
