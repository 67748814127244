/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import { Container, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import PageSection from '../../../../components/core/page/PageSection';
import SimpleTable from '../../../../components/core/tables/SimpleTable';
import PrintableDivider from '../../../../components/misc/PrintableDivider';
import { formatBytes, formatHex } from '../../../../utils/format';
import SbomTable from './SbomTable';


class SbomPrintable extends React.Component {
	componentDidMount() {
		const { childRef } = this.props;
		childRef(this);
	}
	componentWillUnmount() {
		const { childRef } = this.props;
		childRef(undefined);
	}

	render() {
		const { rows, productName, productVersion, userName, dateSubmitted, inputFilename, size, /*sha256*/ } = this.props

		return (
			<div style={{ margin: '0cm' }}>
				<Typography variant='h2'>{productName}</Typography>
				<SimpleTable
					order={['Version', 'Filename', 'Size', 'Uploaded By', 'Uploaded On', 'Export Time']}
					fields={{
						'Version': productVersion,
						'Uploaded By': userName,
						'Uploaded On': `${moment(dateSubmitted).format('lll')} (${moment(dateSubmitted).fromNow()})`,
						'Filename': inputFilename,
                        'Size': formatBytes(parseInt(size)),
                        // TODO: Show sha256
						'Export Time': `Exported ${(new Date()).toUTCString()}.`
					}}
				/>
				<Container>
					To view full information on Software Compositions and explore this firmware, log into <a href={window.location.href}>{window.location.href}</a>.
                </Container>
				<PrintableDivider />
				<PageSection title='Software Composition'>
					{
                        rows.map((obj, i) => (
                            <React.Fragment key={i}>
                                <Typography variant="h5">{obj.item.node.name} (at {formatHex(obj.item.offset)})</Typography>
                                <SbomTable data={{ data: obj.item }} />
                            </React.Fragment>
                        ))
					}
				</PageSection>
			</div>
		)
	}
}

export default SbomPrintable