import React from 'react'
import ExtractedContainerSelector from '../../../../components/wrappers/FileSystemSelector'
import LibraryTable from './LibraryTable'
import DynamicLibraryDisplay from './DynamicLibraryDisplay'
import StaticLibraryDisplay from './StaticLibraryDisplay'
import NullSelection from '../../../../components/placeholders/NullSelection'
import Page from '../../../../components/core/layout/Page'

class LibrariesTab extends React.Component {

  state = {
    libraryId: null,
    libraryIsDylib: null
  }

  setLibraryId = (libraryId, libraryIsDylib) => {
    this.setState({libraryId, libraryIsDylib})
  }

  render() {
    return (
      <Page>
        <ExtractedContainerSelector
          submissionId={this.props.match.params.imageId}
          requestedStageDoneBeforeData={{stage: 2, module: 'strings', all: false}}
        >        
          {({containerId, containerType}) => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', height: '100%', width: '100%', maxWidth: '100%'}}>
                <div style={{flexBasis: '50%', flex: 1, maxWidth: '50%', overflow: 'scroll'}}>
                    <LibraryTable 
                      containerId={containerId}
                      containerType={containerType}
                      selectedLibraryId={this.state.libraryId}
                      setLibraryId={this.setLibraryId}
                    />
                </div>
                <div style={{flexBasis: '50%', flex: 1, overflow: 'scroll', maxWidth: '50%'}}>
                    { (this.state.libraryId !== null) ? (
                      (this.state.libraryIsDylib) ? (
                        <DynamicLibraryDisplay  
                          containerId={containerId}
                          containerType={containerType}
                          libraryId={this.state.libraryId}
                        />
                      ) : (
                        <StaticLibraryDisplay  
                          containerId={containerId}
                          containerType={containerType}
                          libraryId={this.state.libraryId}
                        />
                      )
                    ) : (
                      <NullSelection />
                    )}
                </div>
              </div>
            )
          }}
        </ExtractedContainerSelector>
      </Page>
    )
  }
}


export default LibrariesTab
