import React from 'react'
import gql from 'graphql-tag'
//import { Button, Icon } from 'semantic-ui-react'
import { Typography } from '@material-ui/core'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import SimpleTable from '../../../../components/core/tables/SimpleTable'
import { toChmodStr } from '../../../../utils/format'


// NOTE: This component is hidden per https://gitlab.com/pilot-security/frontend/webapp/-/issues/102
//       due to slowness on the API call it makes.
/*
class FileDownloadButton extends React.Component {
  state = {
    active: false
  }

  async download() {
    const { client, fileId } = this.props
    this.setState({active: true})
    console.log(`download file: ${fileId}`)
    const result = await client.mutate({
      mutation: gql`
        query FileDownloadUrl($input: String) {
          FileDownloadUrl(input: $input)
        }
      `,
      variables: {
        input: fileId
      }
    })
    const url = result.data.FileDownloadUrl
    if (url == null) {
      return
    }
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link)
    this.setState({active: false})
  }

  render() {
    return (
      <Button loading={this.state.active} color='blue' onClick={() => this.download()}>
        <Icon name='download' />
        Download
      </Button>

    )
  }
}
*/

class FileDetails extends React.Component {
  render() {
    const file = this.props.data
    return (
      <div style={{padding: '20px'}}>
        <Typography style={{fontSize: '26px', fontWeight: 800}}>{file.path}</Typography>
        <div>
          <SimpleTable 
            order={['Type', 'SHA256', 'Size', 'UID', 'GID', 'Permissions']}
            fields={{
              'Type': file.magic,
              'SHA256': file.sha256,
              'Size': `${file.size} bytes`,
              'UID': file.uid,
              'GID': file.gid,
              'Permissions': toChmodStr(file.mode),
            }}
          />
          { /* <FileDownloadButton client={this.props.client} fileId={file.id} /> */ }
        </div>
      </div>
    )
  }
}

// data
FileDetails = withGraphqlQuery({
  query: gql`
    query File($input: FileMatchInput) {
      File(input: $input) {
        id
        path
        magic
        size
        sha256
        uid
        gid
        mode
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.fileId}
  }),
  onData: (data) => data.File[0]
})(FileDetails)

export default FileDetails
