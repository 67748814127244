import gql from 'graphql-tag'

const grantMembership = async (client, groupId, userId) => {
  const results = await client.mutate({
    mutation: gql`mutation GrantMembership($input: GrantMembershipInput) {
      GrantMembership(input: $input)
    }`,
    variables: {input: {userId, groupId}}
  })
  return results
}

export default grantMembership;
