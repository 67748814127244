import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Sidebar from '../../components/core/layout/Sidebar'
import SidebarMenu from '../../components/core/layout/SidebarMenu'
import PageRoot from '../../components/core/layout/PageRoot'
import GroupsTab from './groups'
import UsersTab from './users'
import FirmwareTab from './firmware'
import { withRBAC } from '../../components/core/access/RBAC'

class AdminRoute extends React.Component {
  render() {
    const menuItems = [
      {
        type: 'item',
        link: `/admin/users`,
        title: 'Users',
        icon: 'user'
      },
      {
        type: 'item',
        link: `/admin/groups`,
        title: 'Groups',
        icon: 'group'
      },
      {
        type: 'item',
        link: `/admin/firmware`,
        title: 'Firmware',
        icon: 'archive',
      },
    ]
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <Sidebar>
          <SidebarMenu items={menuItems} />
        </Sidebar>
        <PageRoot>
          <Switch>
            <Route path='/admin/users' exact={true} component={UsersTab} />
            <Route path='/admin/firmware' exact={true} component={FirmwareTab} />
            <Route path='/admin/groups' exact={true} component={GroupsTab} />
            <Redirect to={`/admin/users`} />
          </Switch>
        </PageRoot>
      </div>
    )
  }
}

AdminRoute = withRBAC('admin')(AdminRoute)

export default AdminRoute