import React from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'

function DeleteButton(props) {
    const { onDelete } = props;
    const [open, setOpen] = React.useState(false)
    return (
        <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button color='red'><Icon name='trash'/>Delete</Button>}
        >
        <Modal.Header>Delete Firmware</Modal.Header>
        <Modal.Content image>
            <Modal.Description>
            <p>This will permanently delete the firmware and all the results. Are you sure?</p>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setOpen(false)}>
            Cancel
            </Button>
            <Button
                color='red'
                content="Confirm"
                labelPosition='right'
                icon='trash'
                onClick={() => {
                    setOpen(false);
                    onDelete();
                }}
            />
        </Modal.Actions>
        </Modal>
    )
}

export default DeleteButton
