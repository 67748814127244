import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import StatusLabel from '../labels/StatusLabel'
import { formatBytes, prettyDate } from '../../utils/format'
import { patchImageStatus } from '../../utils/firmware'

class SubmissionTable extends React.Component {

    goto = (firmwareId) => {
      this.props.history.push(`/firmware/${firmwareId}`)
    }
  
    render() {
      const { filterUploading } = this.props;
      const rows = this.props.data
        .filter(row => {
          if (row.image.node.status === 'UPLOADING' && filterUploading === true) {
            return false;
          } else {
            return true;
          }
        })
      const customStyle = {
        "marginBottom": "3%"
      }
      return (
        <Table stackable selectable compact collapsing style={customStyle}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Firmware</Table.HeaderCell>
              <Table.HeaderCell>Version</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Uploaded By</Table.HeaderCell>
              <Table.HeaderCell>Uploaded On</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { rows.map((submission) => {
              return (
                <Table.Row key={submission.id} onClick={() => {this.goto(submission.id)}}>
                  <Table.Cell><StatusLabel text={patchImageStatus(submission.image.node.status)} /></Table.Cell>
                  <Table.Cell>{submission.productName}</Table.Cell>
                  <Table.Cell>{submission.productVersion}</Table.Cell>
                  <Table.Cell>{formatBytes(submission.image.node.size)}</Table.Cell>
                  <Table.Cell>{(!submission.user || !submission.user.node) ? '' : submission.user.node.name}</Table.Cell>
                  <Table.Cell>{prettyDate(submission.dateSubmitted)}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      )
    }
  }

// routing
SubmissionTable = withRouter(SubmissionTable)

export default SubmissionTable