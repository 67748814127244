/**
 * Returns true if the route is allowed for all users, or is allowed for this user as they are an admin and it
 * is a route for only admins.
 * @param userProfile
 * @returns {boolean}
 */
export const adminFilter = (userProfile) => {
  return (route) => {
    if (route.admin === true) {
      if (userProfile.role === 'admin') {
        return true
      } else {
        return false
      }
    }
    return true
  }
};
