import _ from 'lodash'
import React from 'react'
import gql from 'graphql-tag'
import { basename} from 'path'
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import { Icon, Table } from 'semantic-ui-react'
import DataNoResults from '../../../../components/messages/DataNoResults'


class LibraryTable extends React.Component {
  render() {
    const { selectedLibraryId, setLibraryId, /*containerType*/ } = this.props;
    const retrievedData = this.props.data;
    let rows = [];
    if (retrievedData.dynamicLibs) {
      rows = retrievedData.dynamicLibs.map(r => Object.assign(r.node, {dynamic: true}));
    }
    if (retrievedData.staticLibs) {
      rows.push(...retrievedData.staticLibs.map(r => Object.assign(r.node, {dynamic: false})));
    }

    if (rows.length === 0) {
      return <DataNoResults msg='No libraries were found within this item.' />
    }

    // Choose the sort function, which varies by data type:
    const sortFuncArray = [
      (x) => {return -x.associatedFindings.length},
      (x) => {return x.version},
      (x) => {return (x.foundIn.node.path === undefined) ? -1 : x.foundIn.node.path}
    ];

    return (
      <Table compact collapsing style={{width: "100%"}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Library
            </Table.HeaderCell>
            <Table.HeaderCell>
              Version
            </Table.HeaderCell>
            <Table.HeaderCell style={{width: '80px', maxWidth: '80px'}}>
              Findings
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { _.sortBy(rows, sortFuncArray).map(row => {
            const active = (selectedLibraryId === row.id);
            const findingsCount = row.associatedFindings.length;
            return (
              <Table.Row key={row.id} active={active} onClick={() => setLibraryId(row.id, row.dynamic)}>
                <Table.Cell collapsing style={{fontFamily: 'monospace'}}>
                  {row.name || ((row.foundIn.node.path !== undefined) ? basename(row.foundIn.node.path) : '')}
                </Table.Cell>
                <Table.Cell>
                  {row.version}
                </Table.Cell>
                <Table.Cell>
                  {(findingsCount !== 0) ? (
                    <React.Fragment>
                      <Icon name='warning sign' color='red' />
                      {findingsCount}
                    </React.Fragment>
                  ) : null}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }
}

const queryFileSystemContainer = gql`
query FileSystem($input: FileSystemMatchInput) {
  FileSystem(input: $input) {
    id
    dynamicLibs {
      id
      node {
        id
        name
        version
        foundIn {
          id
          node {
            __typename
            ... on File {
              id
              path
            }
            ... on ImageContents {
              id
              name
            }
            ... on BaremetalContents {
              id
              name
            }
            ... on Kernel {
              id
              name
            }
          }
        }
        associatedFindings {
          id
        }
      }
    }
    staticLibs {
      id
      node {
        id
        name
        version
        foundIn {
          id
          node {
            __typename
            ... on File {
              id
              path
            }
            ... on ImageContents {
              id
              name
            }
            ... on BaremetalContents {
              id
              name
            }
            ... on ConfigContents {
              id
              name
            }
            ... on Kernel {
              id
              name
            }
          }
        }
        associatedFindings {
          id
        }
      }
    }
  }
} 
`

const queryBaremetalContentsContainer = gql`
query BaremetalContents($input: BaremetalContentsMatchInput) {
  BaremetalContents(input: $input) {
    id
    staticLibs {
      id
      node {
        id
        name
        version
        foundIn {
          id
          node {
            __typename
            ... on File {
              id
              path
            }
            ... on ImageContents {
              id
              name
            }
            ... on BaremetalContents {
              id
              name
            }
            ... on Kernel {
              id
              name
            }
          }
        }
        associatedFindings {
          id
        }
      }
    }
  }
} 
`

const queryConfigContentsContainer = gql`
query ConfigContents($input: ConfigContentsMatchInput) {
  ConfigContents(input: $input) {
    id
    staticLibs {
      id
      node {
        id
        name
        version
        foundIn {
          id
          node {
            __typename
            ... on ConfigContents {
              id
              name
            }
          }
        }
        associatedFindings {
          id
        }
      }
    }
  }
} 
`

// data
LibraryTable = withGraphqlQuery({
  query: (props) => {
    if (props.containerType === 'File System') {
      return queryFileSystemContainer;
    } else if (props.containerType === 'Baremetal Contents') {
      return queryBaremetalContentsContainer;
    } else if (props.containerType === 'Configuration Contents') {
      return queryConfigContentsContainer;
    } else {
      console.error("Unexpected container type:", props.containerType);
      return null;
    }
  },
  //pollInterval: 5000,
  variables: (props) => ({input: {id: props.containerId}}),
  onData: (data) => 
  {
    if (data.FileSystem !== undefined) {
      return data.FileSystem[0];
    } else if (data.BaremetalContents !== undefined) {
      return data.BaremetalContents[0];
    } else if (data.ConfigContents !== undefined) {
      return data.ConfigContents[0];
    } else {
      console.error("Unexpected container query result:", data);
      return null;
    }
  }
})(LibraryTable)

export default LibraryTable