/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import gql from 'graphql-tag';
import React from 'react';
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BinaryHardeningGraph from './BinaryHardeningGraph';


class BinaryHardeningSummary extends React.Component {
	constructor(props) {
		super(props)
		this.stats = props.data;
		this.stats['binTotal'] = this.stats.eTotal + this.stats.dTotal + this.stats.sTotal;
		this.stats['binAll'] = this.stats.eAll + this.stats.dAll + this.stats.sAll;
		this.stats['binSome'] = this.stats.eSome + this.stats.dSome + this.stats.sSome;
		this.stats['percentAll'] = Math.floor(this.stats['binAll']/this.stats['binTotal']*100)
		this.stats['percentSome'] = Math.floor(this.stats['binSome']/this.stats['binTotal']*100)
	}

	render() {
		return (
			<Grid container spacing={3} style={{height: '150px', overflow: 'hidden'}}>
				<Grid item xs={3}>
					<Paper style={{padding: 10, textAlign: 'center', height: 'calc(100% - 3px)'}}>
						<Typography variant="button" display="block" gutterBottom>
							<strong>{this.stats['binTotal']}</strong> Binaries
						</Typography>
						<Divider />
						<Typography variant="button" display="block" gutterBottom>
							<strong>{this.stats['percentSome']}%</strong> Have Some Protections</Typography>
						<Divider />
						<Typography variant="button" display="block" gutterBottom>
							<strong>{this.stats['percentAll']}%</strong> Have Full Protection
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={5}>
					<Paper style={{height: 'calc(100% - 3px)', overflow: 'hidden', position: 'relative'}}>
						<BinaryHardeningGraph stats={this.stats} height={150} />
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper style={{padding: '5px', height: 'calc(100% - 3px)', overflow: 'hidden'}}>
						Binary hardening is a security technique applied in the build chain in which binary files
						are modified to protect against common exploits. In general, all applicable binary hardening measures
						should be applied to all binaries. A lack of certain hardening measures may make it easier
						for an attacker to exploit weaknesses in the binary.
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

BinaryHardeningSummary = withGraphqlQuery({
	query: gql`
	  query Image($input: ImageMatchInput) {
		SummarizeImageBinaryHardening(input: $input) {
		  eTotal
		  eAll
		  eSome
		  dTotal
		  dAll
		  dSome
		  sTotal
		  sAll
		  sSome
		}
	  }
	`,
	variables: (props) => ({ input: { id: props.imageId } }),
	onData: (data) => (data.SummarizeImageBinaryHardening[0])
  })(BinaryHardeningSummary)
  
  
export default BinaryHardeningSummary