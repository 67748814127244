import React, { Component } from 'react'
import gql from 'graphql-tag'
import FileBrowser from 'react-keyed-file-browser'
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import { Icon } from 'semantic-ui-react'

class FilesystemBrowser extends Component {
  render() {
    const { setFileId } = this.props
    const filesystem = this.props.data
    const browserFiles = filesystem.files.map(r => r.node).map((file) => {
      return {
        key: file.path,
        size: file.size,
        id: file.id
      }
    })
    return (
      <div>
        <FileBrowser
          detailRenderer={() => ''}
          files={browserFiles}
          onSelectFile={(file) => {setFileId(file.id)}}
          headerRenderer={null}
          icons={{
            Folder: <Icon name='folder outline' />,
            FolderOpen: <Icon name='folder open outline' />
          }}
        />
      </div>
    )
  }
}

// data
FilesystemBrowser = withGraphqlQuery({
  query: gql`
    query FileSystem($input: FileSystemMatchInput) {
      FileSystem(input: $input) {
        id
        files {
          id
          node {
            id
            size
            path
          }
        }
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.filesystemId}
  }),
  onData: (data) => data.FileSystem[0]
})(FilesystemBrowser)

export default FilesystemBrowser
