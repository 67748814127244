import React from 'react'
import sortBy from 'lodash/sortBy'
import { formatHex } from '../../../../utils/format'
import Popper from '@material-ui/core/Popper';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


class PortCellContents extends React.Component {
  render() {
    const { portNumber } = this.props;
    if (portNumber !== 0) {
      return portNumber
    } else {
      // https://eklitzke.org/binding-on-port-zero
      return (<PopupState variant="popover">
        {(popupState) => (
          <div>
            <HelpOutlineIcon {...bindHover(popupState)} />
            <Popper
              {...bindPopper(popupState)}
              style={{
                width: '100%',
                maxWidth: '300px'
              }}
              placement='bottom-start'
            >
              <Paper style={{padding: '10px', backgroundColor: "#ddd"}} elevation={10}>
                Automated analysis found the provided port number was "0" (zero), which
                is used by a developer to allow the kernel to assign an available port.
                Developers will use a call to <span style={{fontFamily: 'monospace'}}>getsockname</span> to determine the port
                actually chosen.
                Manual analysis of this section of disassembled code is recommended to confirm the behavior.
              </Paper>
            </Popper>
          </div>
        )}
      </PopupState>)
    }
  }
}

function groupRows(array) {
  const map = new Map();
  for (const port of array) {
      const uniqKey = `${port.protocol}/${port.portNumber}/${port.method}/${port.foundIn[0].node.name}`
      if (!map.has(uniqKey)){
          map.set(uniqKey, {
              protocol: port.protocol,
              portNumber: port.portNumber,
              method: port.method,
              foundInBinaryName: port.foundIn[0].node.name,
              foundIn: [port.foundIn[0]]
          });
      } else {
        map.get(uniqKey).foundIn.push(port.foundIn[0])
        //console.log("Now have map:", map)
      }
  }
  return map;
}


function Row(props) {
  const { port } = props;
  const [open, setOpen] = React.useState(false);
  const hasChildRow = port.foundIn.length > 0;
  const isBinaryAnalysisResult = (port.method === 'disassemblySocket')
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{port.protocol.toUpperCase()}</TableCell>
        <TableCell align="right"><PortCellContents portNumber={port.portNumber} /></TableCell>
        { (port.foundIn.length > 0) ? (
          <TableCell>{port.foundInBinaryName}</TableCell>
        ) : (
          <TableCell></TableCell>
        )}
        <TableCell>
          { (port.method === 'startupInetBind') ? 'Emulation' :
            (isBinaryAnalysisResult) ? 'Binary Analysis' : ''
          }
        </TableCell>
        <TableCell>
          { (hasChildRow && isBinaryAnalysisResult) ?
            (<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>) : null }
        </TableCell>
      </TableRow>
      { (hasChildRow && isBinaryAnalysisResult) ? (<TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography gutterBottom component="div">
                There {(port.foundIn.length > 1) ? 'were multiple references' : 'was a reference'} found to this network port in the binary:
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Method</TableCell>
                    <TableCell align="right">Address</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {port.foundIn.map((foundInItem) => (
                    <TableRow key={foundInItem.usageAddress}>
                      <TableCell>{foundInItem.usageMethod}</TableCell>
                      <TableCell align="right">{formatHex(foundInItem.usageAddress)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>) : null }
    </React.Fragment>
  );
}


class BoundPortsList extends React.Component {
  
  render() {
    const boundPorts = this.props.data.map(r => r.node)
    const sortedBoundPorts = sortBy(boundPorts, ['portNumber', 'protocol'])
    const boundPortsRowsMap = groupRows(sortedBoundPorts);
    const boundPortsRowElements = []
    boundPortsRowsMap.forEach(function(port, uniqKey) {
      boundPortsRowElements.push(<Row key={uniqKey} port={port} />)
    })

    // Not displaying address as currently is blank in all observed cases:
    // {(port.boundAddress === null) ? '' : port.boundAddress}
    return (
      <TableContainer component={Paper} style={{minWidth: '400px', width: '50%'}}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Protocol</TableCell>
              <TableCell align="right">Port</TableCell>
              <TableCell>Bound By</TableCell>
              <TableCell>Identified via</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            { boundPortsRowElements }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default BoundPortsList