import gql from 'graphql-tag'

const deleteUser =  async (client, userId) => {
  const results = await client.mutate({
      mutation: gql`mutation UserDelete($input: [UserMatchInput]) {
        UserDelete(input: $input) {
         id
       }
     }
     `,
      variables: {input: {id: userId}}
  })
  return results
}

export default deleteUser;
