/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import exportFromJSON from 'export-from-json';
import React from 'react';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { generateDefaultJsonSpdx } from './utils';


class ExportSPDXButton extends React.Component {

	handleClick = () => {
		// get default SPDX JSON payload
		let d = generateDefaultJsonSpdx()
    d.Document.packages[0].packageFileName = this.props.inputFilename
    d.Document.creationInfo.creators = ["Organization: Pilot Security", "Tool: Pilot Security Platform"]
    d.Document.creationInfo.created = moment(this.props.dateSubmitted).utc().format()  // format 'YYYY-MM-DDTHH:mm:ss[Z]'
    d.Document.name = `${this.props.productName}-${this.props.productVersion}`
    const overallFirmwareSha256 = (this.props.sha256 === "unknown" || !this.props.sha256) ? "NOASSERTION" : this.props.sha256
    d.Document.creationInfo.comment = "This SPDX file was created by Pilot Security through automated analysis of binary firmware." +
      ` The firmware ${this.props.inputFilename} (SHA256 ${overallFirmwareSha256}) was analyzed.`
    d.Document.files = []
    d.Document.packages = []

    const packageMap = {};

    // add file elements
    this.props.rows.forEach((obj) => {
      if (obj.item.node.executables !== undefined) {
        obj.item.node.executables.forEach(exe => {
          //TODO eliminate repeated code
          const { packageId, packageInfo, fileRecord } = this.GetSpdxFileRecord(exe, ['BINARY', 'APPLICATION'])
          d.Document.files.push(fileRecord)
          if (packageId !== null) {
            if (packageMap[packageId] === undefined) {
              packageMap[packageId] = packageInfo
            } else {
              packageMap[packageId].spdxFileId.push(packageInfo.spdxFileId[0])
            }
          }
        })
      }
      if (obj.item.node.dynamicLibs !== undefined) {
        obj.item.node.dynamicLibs.forEach(lib => {
          const { packageId, packageInfo, fileRecord } = this.GetSpdxFileRecord(lib, ['BINARY'])
          d.Document.files.push(fileRecord)
          if (packageId !== null) {
            if (packageMap[packageId] === undefined) {
              packageMap[packageId] = packageInfo
            } else {
              packageMap[packageId].spdxFileId.push(packageInfo.spdxFileId[0])
            }
          }
        })
      }
      if (obj.item.node.staticLibs !== undefined) {
        obj.item.node.staticLibs.forEach(sLib => {
          const { packageId, packageInfo, fileRecord } = this.GetSpdxFileRecord(sLib, ['BINARY'])
          d.Document.files.push(fileRecord)
          if (packageId !== null) {
            if (packageMap[packageId] === undefined) {
              packageMap[packageId] = packageInfo
            } else {
              packageMap[packageId].spdxFileId.push(packageInfo.spdxFileId[0])
            }
          }
        })
      }
      // TODO: Consider adding images, source files (scripts), etc.

      // add packages that group based on CPEs for now
      for (const [packageId, packageInfo] of Object.entries(packageMap)) {
        d.Document.packages.push({
          'name': `SPDXRef-${packageId}`,
          //'homepage': TODO (optional field)
          'copyrightText': 'NOASSERTION',
          'versionInfo': `v${packageInfo.version}`,
          'hasFiles': packageInfo.spdxFileId,
          'downloadLocation': 'NOASSERTION',
          'filesAnalyzed': true,
          // TODO: required, take ordered sequence of SHA1 of each file and then SHA1 them - but we don't collect this data today:
          //'packageVerificationCode': {}
          'source': 'Analysis of associated files.',
          'concludedLicense': 'NOASSERTION',
          //TODO licenseInfoFromFiles is marked mandatory, but is not so in examples, could set to ['NOASSERTION'] also prob
          'licenseDeclared': 'NOASSERTION',
          //TODO add CPE
          //'externalRefs': [{"referenceCategory" : "SECURITY", "referenceLocator" : "cpe:2.3:a:pivotal_software:spring_framework:4.1.0:*:*:*:*:*:*:*", "referenceType" : "http://spdx.org/rdf/references/cpe23Type"}]
        })
      }
    })

		var fileName = `${this.props.productName}_${this.props.productVersion}.spdx`  // .json will be added by export
		return exportFromJSON({ data: d, fileName: fileName, exportType: exportFromJSON.types.json })
  }

	render() {
		return (
			<Button size='tiny' color='grey' style={{ marginTop: '10px' }} onClick={this.handleClick}>SPDX&copy; (JSON)</Button>
		);
	}

	GetSpdxFileRecord(row, binaryFiletypes) {
    // unique ID for this element within the document
    const spdxFileId = (row.node.foundIn)
        ? `SPDXRef-${row.node.foundIn.node.sha256}`
        : `SPDXRef-${row.node.name.replace(/\s/g, '')}-${row.node.version.replace(/\s/g, '')}`;
    let packageId = null, packageInfo = null;  // will only be set now below if we know version info
		const fileRecord = {
      "name": spdxFileId,
      // we prefix with a . to match the 'relative path' expectation in the spec:
			"fileName": (row.node.foundIn !== undefined) ? '.'+row.node.foundIn.node.path : "NOASSERTION",
			"fileTypes": binaryFiletypes,
			"checksums": [
				{
					"algorithm": "SHA256",
					"checksumValue": (row.node.foundIn) ? row.node.foundIn.node.sha256 : ""
				}
      ],
      "licenseInfoInFiles": ['NOASSERTION'],
      "copyrightText": 'NOASSERTION',
      "comment": row.node.name,  // binary name, sometimes just the file's base name
    }
    if (row.node.version !== null) {
      packageId = `${row.node.name}-${row.node.version}`;
      packageInfo = {
        'spdxFileId': [spdxFileId],
        'version': row.node.version,
        // TODO: add CPE, etc
      }
    }
		return { packageId, packageInfo, fileRecord }
	}
}

export default ExportSPDXButton
