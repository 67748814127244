import React from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
//import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
//import { isExtractionFinished } from '../../../../utils/firmware'
import {XYPlot, DiscreteColorLegend, AreaSeries, XAxis, YAxis } from 'react-vis';

// NOTE: Could not get colorDomain/colorRange/colorType=linear to work per https://github.com/uber/react-vis/blob/master/docs/colors.md
// This is just a list of colors we choose from in series for the lines.
// If all are used up, we use a default #ccc.
const colorPalette = ['#1b262c', '#fa1616', '#0f4c75', '#62760c', '#bbe1fa', '#4a3f35']

class ImageEntropyOverview extends React.Component {
  constructor(props) {
    super()
    const data = props.data
    let dataSets = {};
    for (let i in data.entropyAnalysis) {
      const lineName = data.entropyAnalysis[i].extractionContext;  // may be null if base context
      const linePrettyName = (lineName === null) ? 'Submitted Image' : lineName;
      const dataObj = data.entropyAnalysis[i].node;
      let lineSeriesData = [];
      for (let j in dataObj.entropyList) {
        lineSeriesData.push({x: dataObj.offsetMin+(dataObj.blockSize*j), y: dataObj.entropyList[j]})
      }
      dataSets[linePrettyName] = lineSeriesData;
    }
    this.state = {
      dataSets,
      status: props.data.status
    }
  }

  formatXAxisLabels(value, index, scale, tickTotal) {
    return `0x${value.toString(16)}`;
  }

  getLineNameDisplayText(lineName) {
    if (lineName === 'Submitted Image') {
      return lineName
    }
    const lineParts = lineName.split(';')
    // NOTE: Assumes all data is the pipe separated: MIME|filepath format:
    const linePartsStripMime = lineParts.map(x => x.split('|', 2)[1])
    return linePartsStripMime.join('/')
  }

  render() {
    const { classes } = this.props
    const { dataSets } = this.state

    //if (isExtractionFinished(this.props.data.submission.node)) {
      if (Object.keys(dataSets).length === 0) {
        // No entropy data found, perhaps an image run on an old processor version.
        console.warn("No entropy data available for rendering.");
        return null;
      }
      let lineSeriesNodeList = [];
      let lineNameList = []
      for (let lineName in dataSets) {
        const lineColor = (lineName === 'Submitted Image') ? "#012552" : (lineNameList.length < colorPalette.length) ? colorPalette[lineNameList.length] : '#ccc'
        // TODO render key as dots instead of lines?
        //console.log(`Handling new line ${lineName} (${lineColor}) with ${dataSets[lineName].length} entries.`);
        lineNameList.push({title: this.getLineNameDisplayText(lineName), color: lineColor});
        lineSeriesNodeList.push(<AreaSeries key={lineName} data={dataSets[lineName]} stroke={lineColor} fill="#eee" />);
      }
      return (
        <Grid container>
          <Hidden mdDown>
            <Grid item lg={1} className={classes.asideLabel}>
              Entropy
            </Grid>
          </Hidden>
          <Grid item md={12} lg={11} xl={9}>
            <XYPlot height={200} width={1000}>
              { lineSeriesNodeList }
              <YAxis yDomain={[0.0, 1.0]} tickValues={[0.5, 1.0]} style={{
                ticks: {stroke: '#000'},
                text: {stroke: 'none', fill: '#000', fontWeight: 400}
              }} />
              <XAxis tickFormat={this.formatXAxisLabels} tickLabelAngle={-15} style={{
                ticks: {stroke: '#000'},
                text: {stroke: 'none', fill: '#000', fontWeight: 400}
              }} />
            </XYPlot>
          </Grid>
          <Hidden lgDown>
            { (lineNameList.length > 1) ?
              <Grid item xl={2}>
                <Typography>Key:</Typography>
                <DiscreteColorLegend
                  items={lineNameList}
                  orientation="vertical"
                />
              </Grid> : null }
          </Hidden>
        </Grid>
      )
    //}
    /*
    return (
      <div className={classes.centerLoader} >
        <CircularProgress>
          <div>Loading</div>
        </CircularProgress>
        <Typography>Analyzing Image for Entropy</Typography>
      </div>
    )
    */
  }
}

// styling
ImageEntropyOverview = withStyles((theme) => ({
  asideLabel: {
    font: 'bold 12px Sans-Serif',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    transform: 'rotate(90deg)',
    paddingTop: '100px'
  },
  centerLoader: {
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: '10px'
  }
}))(ImageEntropyOverview)


ImageEntropyOverview = withGraphqlQuery({
  query: gql`
  query Image($input: ImageMatchInput) {
    Image(input: $input) {
      id
      status
      entropyAnalysis {
        id
        extractionContext
        node {
          id
          offsetMin
          offsetMax
          blockSize
          entropyList
        }
      }
    }
  }
  `,
  variables: (props) => {
    return {input: {id: props.imageId}}
  },
  onData: (data) => data.Image[0]
})(ImageEntropyOverview)

export default ImageEntropyOverview