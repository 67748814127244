import gql from 'graphql-tag'

const RUN_BINARY_ANALYSIS_MUTATION = gql`
mutation RunBinaryAnalysis($input: RunBinaryAnalysisInput) {
    RunBinaryAnalysis(input: $input) 
}
`

const RunBinaryAnalysis = async (client, binaryId, binaryType) => {
  const response = await client.mutate({
    mutation: RUN_BINARY_ANALYSIS_MUTATION,
    variables: {input: {binaryId, binaryType}}
  })
  return response
}

export default RunBinaryAnalysis;
