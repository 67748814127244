
export const getTask = (tasks, processor) => {
  const task = tasks.find(t => (t.node.processor === processor))
  if (task === undefined) {
    return null
  } else {
    return task.node
  }
}

export const isAnalysisFinished = (status) => {
  const finishedStatuses = [
    "COMPLETE",
    "FAILED",
    "ERROR",
    "INCOMPLETE",
    "CANCELED",
    "ABORTED",
    "NOT SUPPORTED",
    "NOT APPLICABLE"
  ]
  return finishedStatuses.includes(status)
}

export const isExtractionFinished = (submission) => {
  /*
  DEPRECATED FUNCTION AS PERMISSIONS CHECKS WILL FAIL ON api-service.
  To allow permission on api-service side, users would have to be given access to Stages, ProcessingTask, and Workflow nodes for their submissions.
  This would likely be done via pipeline-service granting access to the user's ID in pipeline cleanup.
  */
  const stages = submission.stages
  const stage1 = stages.map(r => r.node).find(s => (s.index === 1))
  if (!stage1) {
    return false
  }
  const imageWorkflow = stage1.workflows.map(r => r.node).find(w => (w.targetType === 'Image'))
  if (!imageWorkflow) {
    return false
  }
  const extractionTask = imageWorkflow.tasks.map(r => r.node).find(t => (t.module === 'extraction'))
  if (!extractionTask) {
    return false
  }
  return (extractionTask.status === 'COMPLETE')
}

export const isAnalysisTask = (m) => {
  const analysisProcessors = [
    'strings',
    'startup',
    'disassembly'
  ]
  return analysisProcessors.includes(m)
}

// TODO: remove this patch when status management is overhauled
export const patchImageStatus = (status) => {
  return (status === 'ABORTED') ? 'INCOMPLETE' : status
}

export const computeFirmwarePollStopCheck = ({data}) => {
  const submission = data[0]
  if (submission !== undefined) {
    //console.log('checking to see if finished', submission.image.node.status)
    if (isAnalysisFinished(submission.image.node.status)) {
      return true
    }
  } else {
    console.warn('Submission not found in data, confirm correct funtion used.', data)
  }
}

/*
 * This function is to be used if a query Submission is made,
 * and then `onData: (data) => (data.Submission[0])` is used.
 * This means the incoming data is a single Submission node.
 * The shape must include image.node.status for this to work.
 */
export const computeFirmwarePollStopCheckForSubmissionSingle = ({data}) => {
  if (data !== undefined) {
    if (isAnalysisFinished(data.image.node.status)) {
      return true
    } else if (data.image.node.status === undefined) {
      console.warn('Status not found in data, confirm correct shape used.', data)
    }
  } else {
    console.warn('Submission not found in data, confirm correct function used.', data)
  }
}

/*
 * This function is to be used if a query Image is made,
 * and then `onData: (data) => (data.Image[0])` is used.
 * This means the incoming data is a single Image node.
 * The shape must include status for this to work.
 */
export const computeFirmwarePollStopCheckForImageSingle = ({data}) => {
  if (data !== undefined) {
    if (isAnalysisFinished(data.status)) {
      return true
    } else if (data.status === undefined) {
      console.warn('Status not found in data, confirm correct shape used.', data)
    }
  } else {
    console.warn('Image not found in data, confirm correct function used.', data)
  }
}

export const computeFirmwareVariables = (props) => {
  return {input: {id: props.match.params.imageId}}
}