import React from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

class EntityLabel extends React.Component {
  render() {
    const { classes, typename, details } = this.props
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>{typename}:</Typography>
        <Typography className={classes.textDetails}>{details}</Typography>
      </div>
    )
  }
}

// styling
EntityLabel = withStyles((theme) => ({
  root: {
    borderRadius: 5,
    background: '#171d5e',
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    color: 'white',
    fontWeight: 600,
    paddingRight: 5
  },
  textDetails: {
    color: 'white'
  }
}))(EntityLabel)

export default EntityLabel