import React from 'react'
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../graphql/core/connectors'

class Versions extends React.Component {
  render() {
    const { data, appVersion } = this.props
    return (
      <div style={{maxWidth: 200, maxHeight: 200}}>
        <div>App Version: {appVersion}</div>
        <div>Server Version: {data.version}</div>
      </div>
    )
  }
}

// data
Versions = withGraphqlQuery({
  query: gql`
    query {
      _info {
        version
      }
    }
  `,
  onData: (data) => data._info
})(Versions)

export default Versions