import React from 'react'
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import { Button, Dropdown, Input } from 'semantic-ui-react'
import FindingsTable from './FindingsTable'
import FlexSpace from '../../../../components/core/layout/FlexSpace'
import VerticalSpace from '../../../../components/core/layout/VerticalSpace'
import DropdownMenu from '../../../../components/core/dropdown/DropdownMenu'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import PageSplit from '../../../../components/core/layout/PageSplit'
import PageToolbar from '../../../../components/core/layout/PageToolbar'
import PageMain from '../../../../components/core/layout/PageMain'
import Page from '../../../../components/core/layout/Page'
import NullSelection from '../../../../components/placeholders/NullSelection'
import { computeFirmwareVariables } from '../../../../utils/firmware'
import FindingGeneric from './types/generic'
import FindingOsConfiguration from './types/osconfig'
import FindingCryptographicKey from './types/cryptography'
import FindingVulnerableFunctionCall from './types/functioncalls'
import FindingVulnerableVersion from './types/version'
import UnknownFinding from './types/unknown'
import DataNotReady from '../../../../components/messages/DataNotReady'
import DataNoResults from '../../../../components/messages/DataNoResults'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import FindingsPrintable from './FindingsPrintable';
import DocumentExportButton from '../../../../components/RunDocumentExportButton'

const finished = (status) => {
  return [
    "COMPLETE"
  ].includes(status)
}

const filterOptions = [
  { key: 'Vulnerable Version', text: 'Vulnerable Version', value: 'FindingVulnerableVersion' },
  { key: 'Vulnerable Function Calls', text: 'Vulnerable Function Calls', value: 'FindingVulnerableFunctionCall' },
  { key: 'OS Configurations', text: 'OS Configurations', value: 'FindingOsConfiguration' },
  { key: 'Cryptography', text: 'Cryptography', value: 'FindingCryptographicKey' },
  { key: 'Info Disclosure', text: 'Info Disclosure', value: 'FindingGeneric' },
]

const getFindingComponent = (__typename) => {
  switch (__typename) {
    case 'FindingGeneric':
      return FindingGeneric
    case 'FindingOsConfiguration':
      return FindingOsConfiguration
    case 'FindingCryptographicKey':
      return FindingCryptographicKey
    case 'FindingVulnerableFunctionCall':
      return FindingVulnerableFunctionCall
    case 'FindingVulnerableVersion':
      return FindingVulnerableVersion
    default:
      return UnknownFinding
  }
}

class FindingsTab extends React.Component {
  state = {
    selectedFindingId: null,
    typeFilter: [],
    titleFilter: '',
  }

  setTitleFilter = (titleFilter) => {
    this.setState({titleFilter})
  }

  setTypeFilter = (typeFilter) => {
    this.setState({typeFilter})
  }

  selectFinding = (id) => {
    this.setState({selectedFindingId: id})
  }

  clearFilters = () => {
    this.setState({typeFilter: []})
  }

  render() {
    const { data } = this.props
    const { selectedFindingId, typeFilter, titleFilter } = this.state
    const submission = data
    let rows = submission.image.node.findings.map(r => r.node)
    if (rows.length === 0) {
      if (finished(submission.image.node.status)) {
        return <DataNoResults msg='No findings found in this firmware, contact support.' />
      } else {
        return <DataNotReady />
      }
    }
    const selectedFinding = (selectedFindingId === null)
      ? null
      : rows.find(f => (f.id === selectedFindingId))
    const FindingComponent = (selectedFinding !== null)
      ? getFindingComponent(selectedFinding.__typename)
      : NullSelection

    return (
      <Page>
        <PageToolbar>
          <Input
            icon='search'
            placeholder='Filter Title'
            onChange={(_, data) => { this.setTitleFilter(data.value) }}
          />
          <VerticalSpace />
          <Dropdown
            placeholder='Filter Finding Types'
            multiple
            selection
            options={filterOptions}
            value={this.state.typeFilter}
            onChange={(_, data) => { this.setTypeFilter(data.value) }}
          />
          <VerticalSpace />
          <Button size='tiny' color='grey' onClick={this.clearFilters}>
            Clear Filters
          </Button>
          <FlexSpace />
          <DropdownMenu name='findingexportmenu'
            buttonText='Export Findings'
            color='white' size='tiny'
            buttonStyleObject={{marginRight: '10px'}}
          >
            <DocumentExportButton
                submissionId={submission.id}
                inDropdown={true}
                showPdf={true}
            />
            <ReactToPrint content={() => this.componentRef}
              copyStyles={true}
              documentTitle={'Pilot Findings Export'}
              /* Set page margin to 0mm to hide printing info date/url/pageno/etc */
              pageStyle={'@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }'}
              removeAfterPrint={true}
            >
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button size='tiny' color='grey' onClick={handlePrint}>Print</Button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
          </DropdownMenu>
        </PageToolbar>
        <PageMain>
          <PageSplit>
            <ScrollSpace>
              <FindingsTable
                rows={rows}
                selectFinding={this.selectFinding}
                selectedFindingId={selectedFindingId}
                titleFilter={titleFilter}
                typeFilter={typeFilter}
              />
            </ScrollSpace>
            <VerticalSpace />
            <ScrollSpace>
              { (selectedFindingId !== null) ? (
                <FindingComponent finding={selectedFinding} id={selectedFindingId} printStyle={false} />
              ) : (
                <NullSelection />
              )}
            </ScrollSpace>
          </PageSplit>
        </PageMain>
        { /* NOTE: This is always computed and just rendered hidden, even if the user doesn't request printing. */ }
        <div style={{ display: "none" }}>
          <FindingsPrintable submissionId={data.id} childRef={el => (this.componentRef = el)} />
        </div>
      </Page>
    )
  }
}

// data
FindingsTab = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        image {
          id
          node {
            id
            status
            findings {
              id 
              node {
                ... on FindingVulnerableFunctionCall {
                  id
                  title
                  scoreCvss
                }
                ... on FindingVulnerableVersion {
                  id
                  title
                  scoreCvss
                  vulnerableEntity {
                    id
                    node {
                      ... on Executable {
                        id
                        version
                      }
                      ... on ExecutableScript {
                        id
                        version
                      }
                      ... on DynamicLib {
                        id
                        version
                      }
                      ... on StaticLib {
                        id
                        version
                      }
                    }
                  }
                }
                ... on FindingOsConfiguration {
                  id
                  title
                  scoreCvss
                }
                ... on FindingGeneric {
                  id
                  title
                  scoreCvss
                }
                ... on FindingCryptographicKey {
                  id
                  title
                  scoreCvss
                }
              }
            }
          }
        }
      }
    } 
  `,
  variables: computeFirmwareVariables,
  onData: (data) => data.Submission[0],
})(FindingsTab)

export default FindingsTab
