import React from 'react'

class PageSplit extends React.Component {
  render() {
    return (
      <div style={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default PageSplit