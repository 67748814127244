/*
 * Renders for each of the the current members of sharing, and allows removal of them.
 */

import React from 'react'
import { Button, Loader } from 'semantic-ui-react'
import revokeAccess from '../../../../../graphql/functions/revokeAccess';

class RevokeAccess extends React.Component {

    state = {
        active: false
    }

    render() {
        const { client, subject, submission, after, owned } = this.props;
        return (
            <div key={subject.id} className='row' style={{gap: 5, alignItems: 'center'}}>
                <div style={{border: 'solid 1px #D0D0D0', width: 400, padding: 7, borderRadius: 5}}>
                    {subject.__typename}: {subject.name}
                    { (subject.__typename === 'Group') ? (
                        <div style={{marginLeft: '10px', border: 'solid 1px #e0e0e0', padding: 3}}>
                            {subject.users.map(u => {
                                return (u.node.name)
                            }).join(", ")}
                        </div>
                    ) : null }
                </div>
                { (owned) ? (
                    <React.Fragment>
                        <Button
                            size='small'
                            color='red'
                            content='Revoke Access'
                            onClick={async () => {
                                this.setState({active: true})
                                await revokeAccess(client, 'Submission', submission.id, subject.__typename, subject.id)
                                after()
                                this.setState({active: false})
                            }}
                        />
                        <Loader inline active={this.state.active} />
                    </React.Fragment>
                ) : null }
            </div>
        )
    }
}

export default RevokeAccess;