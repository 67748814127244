import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  view: {
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    overflow: 'scroll'
  }
})

class View extends React.Component {
  render() {
    const { classes, children } = this.props
    return (
      <div className={classes.view}>
        {children}
      </div>
    )
  }
}

View =  withStyles(styles)(View)

export default View