import React from 'react'
import FindingLayout from '../../../FindingLayout'
import Markdown from '../../../../../../../components/core/media/Markdown'
import { Table } from 'semantic-ui-react'

// NOTE: This relation is being deprecated:
function makeDetailsFromVulnEntityRelation(finding) {
  return (
    <div>
      <div>Vulnerable Entities:</div>
      <Table compact collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Found In</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { finding.vulnerableEntity.map(r => r.node).map((ve) => {
            return (
              <Table.Row key={ve.id}>
                <Table.Cell>{ve.__typename}</Table.Cell>
                <Table.Cell>{ve.foundIn.node.path || ve.foundIn.node.path}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <div>Vulnerabilities:</div>
      <Markdown data={finding.details} />
    </div>
  )
}

function makeDetailsFromVulnerableDataSiteRelation(finding) {
  if (finding.vulnerableDataSite.length === 0) {
    return null
  }
  return (
    <div>
      <div>Vulnerable Entities:</div>
      <Table compact collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Function Called</Table.HeaderCell>
            <Table.HeaderCell>Vulnerable Value</Table.HeaderCell>
            <Table.HeaderCell>Found In Type</Table.HeaderCell>
            <Table.HeaderCell>Found In</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { finding.vulnerableDataSite.map(r => r.node).map((vdcs) => {
            const filePathOrName = (vdcs.extractedFrom.node.foundIn.node.__typename === 'File') ? vdcs.extractedFrom.node.foundIn.node.path : vdcs.extractedFrom.node.foundIn.node.name;
            // Show as `Type path:lineNumber` if a line number, else show as `Type path @ address`:
            const extractedFrom = (vdcs.extractedFrom.type === 'line') ? `${vdcs.extractedFrom.node.foundIn.node.__typename} ${filePathOrName}:${vdcs.extractedFrom.locator}` : `${vdcs.extractedFrom.node.foundIn.node.__typename} ${filePathOrName} @ ${vdcs.extractedFrom.locator}`
            // TODO Use vdcs.confidence
            return (
              <Table.Row key={vdcs.id}>
                <Table.Cell>{vdcs.calledFunction}</Table.Cell>
                <Table.Cell>{vdcs.vulnerableValue.join(', ')}</Table.Cell>
                <Table.Cell>{vdcs.extractedFrom.node.__typename}</Table.Cell>
                <Table.Cell>{extractedFrom}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}


class GenericFunctionFinding extends React.Component {
  render() {
    const finding = this.props.data
    const printStyle = this.props.printStyle || false;
    return (
      <FindingLayout
        type='Vulnerable Function Call' 
        icon='code branch'
        title={finding.title}
        overallCvss={(printStyle === true) ? finding.scoreCvss : null}
        description={finding.description}
        impact={finding.impact}
        recommendation={finding.recommendation}
        Details={() => (finding.vulnerableEntity !== undefined && finding.vulnerableEntity.length > 0) ? makeDetailsFromVulnEntityRelation(finding) : makeDetailsFromVulnerableDataSiteRelation(finding)}
        Entities={() => (
            <div>Relevant entities!!!</div>
        )}
      />
    )
  }
}

export default GenericFunctionFinding