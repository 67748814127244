import React from 'react'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import AppsIcon from '@material-ui/icons/Apps';
import LinkIcon from '@material-ui/icons/Link';
import ExtensionIcon from '@material-ui/icons/Extension';

export const DESC_NX = 'No execute bit set which prevents execution of code in data memory regions.'
export const DESC_PIC = 'Position-independent code has more diverse memory organization which makes attacks which rely on memory structure more difficult.'
export const DESC_RELRO = 'RELocation Read-Only makes the Global Offset Table read only to prevent exploitation of dynamic imports.'
export const DESC_CANARY = 'Canaries provided buffer overflow protections by ensuring that values in the stack buffer have not been overriden.'
//export const DESC_FORTIFY = null; //TODO
export const DESC_STRIPPED = 'A stripped binary does not contain debug information. This can make it harder to reverse engineer the program to find vulnerabilities in it.';

/*
 * Used for rendering in the individual data type tabs (e.g., Executables).
 */
export const hardeningAsListFromNode = (binaryNode) => {
    return [
        {name: 'NX', enabled: binaryNode.hardeningNx, description: DESC_NX},
        {name: 'PIC', enabled: binaryNode.hardeningPic, description: DESC_PIC},
        {name: 'RELRO', enabled: binaryNode.hardeningRelro, description: DESC_RELRO},
        {name: 'Canary', enabled: binaryNode.hardeningCanary, description: DESC_CANARY},
        // NOTE: When hardeningFortified is populated in graph, add to query below and enable this line here to render:
        //{name: 'Fortified', enabled: binaryNode.hardeningFortified, description: 'Intelligently compiled code that helps prevent memory being overwritten beyond its boundaries.'},
        {name: 'Stripped', enabled: binaryNode.hardeningStripped, description: DESC_STRIPPED},
    ];
}

export const showFeatureStatusIcon = (featureStatus) => {
    return ((featureStatus === undefined) ? (
        null
    ) : (featureStatus) ? (
        <CheckIcon style={{color: 'green'}} />
    ) : (
        <ClearIcon color='error' />
    ));
}

/*
 * Renders an Icon for the given libraryType (Executable, DynamicLib, StaticLib).
 */
export const getIconForLibraryType = (libraryType) => {
    return (libraryType === 'Executable') ? (<AppsIcon />) : (
           (libraryType === 'DynamicLib') ? (<LinkIcon />) : (
           (libraryType === 'StaticLib') ? (<ExtensionIcon />) : null))
}