import _ from 'lodash'
import React from 'react'
import gql from 'graphql-tag'
import { basename } from 'path'
import { Table } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import DataNoResults from '../../../../components/messages/DataNoResults'

class ExecutableScriptsTable extends React.Component {
  render() {
    const { selectedExecutableId, setExecutableId } = this.props
    const filesystem = this.props.data
    const rows = filesystem.executableScripts.map(r => r.node)

    if (rows.length === 0) {
      return <DataNoResults msg='No Executable Scripts found in this file system' />
    }

    const sortFunctionList = [
      (x) => {return -x.associatedFindings.length},
      (x) => {return (x.foundIn.node.path === undefined) ? -1 : x.foundIn.node.path}
    ];

    return (
      <Table columns={9}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              Script
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              Findings
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { _.sortBy(rows, sortFunctionList).map(row => {
            const active = (selectedExecutableId === row.id)
            const findingsCount = row.associatedFindings.length
            const foundInType = row.foundIn.node.__typename
            return (
              <Table.Row key={row.id} active={active} onClick={() => setExecutableId(row.id)}>
                <Table.Cell width={5} style={{fontFamily: 'monospace'}}>
                  {(foundInType === 'File') ? basename(row.foundIn.node.path) : row.foundIn.node.name}
                </Table.Cell>
                <Table.Cell width={1}>
                  {(findingsCount !== 0) ? findingsCount : null}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }
}

// data
ExecutableScriptsTable = withGraphqlQuery({
  query: gql`
    query FileSystem($input: FileSystemMatchInput) {
      FileSystem(input: $input) {
        id
        executableScripts {
          id
          node {
            id
            name
            foundIn {
              id
              node {
                __typename
                ... on File {
                  id
                  path
                }
                ... on ImageContents {
                  id
                  name
                }
                ... on BaremetalContents {
                  id
                  name
                }
                ... on Kernel {
                  id
                  name
                }
              }
            }
            associatedFindings {
              id
            }
          }
        }
      }
    } 
  `,
  fetchPolicy: 'network-only',
  //pollInterval: 5000,
  variables: (props) => ({input: {id: props.filesystemId}}),
  onData: (data) => data.FileSystem[0]
})(ExecutableScriptsTable)

export default ExecutableScriptsTable