import gql from 'graphql-tag'

const registerUser = async (client, userEmail) => {
  const results = await client.mutate({
    mutation: gql`mutation RegisterUser($input: RegisterUserInput) {
      RegisterUser(input: $input) {
        id
        name
      }
    }`,
    variables: {input: {name: userEmail, role: 'user'}}
  })
  return results
}

export default registerUser;
