import React from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import CloudOffIcon from '@material-ui/icons/CloudOff'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 350,
    height: 200
  },
  icon: {
    fontSize: 96,
    marginBottom: 20
  },
  spacer: {
    height: 50
  }
})

class ApiCallError extends React.Component {
  render() {
    const { classes, error } = this.props
    console.log('ApiCallError:')
    console.log(this.props)
    return (
      <div className={classes.root}> 
        <div className={classes.spacer} />
        <Paper className={classes.box}>
          <CloudOffIcon className={classes.icon} />
          <Typography variant='h4'>API Call Error</Typography>
          {(error.networkError?.result) ? (
            <div>
              <div>{error.networkError.result.message}</div>
              <div>(traceid: {error.networkError.result.traceId})</div>
            </div>
           ) : null}
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(ApiCallError)
