/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import gql from 'graphql-tag';
import React from 'react';
import { Button, Dropdown, Input } from 'semantic-ui-react'; //TODO: shift some to material
import Page from '../../../../components/core/layout/Page';
import { PageMainNoFlex } from '../../../../components/core/layout/PageMain';
import FlexSpace from '../../../../components/core/layout/FlexSpace'
import PageToolbar from '../../../../components/core/layout/PageToolbar'
import DataNotReady from '../../../../components/messages/DataNotReady';
import DataNoResults from '../../../../components/messages/DataNoResults'
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import { isAnalysisFinished } from '../../../../utils/firmware';
import BinaryHardeningTable from './BinaryHardeningTable';
import BinaryHardeningSummary from './BinaryHardeningSummary';

const filterOptions = [
  { key: 'Executable', text: 'Executable', value: 'Executable' },
  { key: 'DynamicLib', text: 'Dynamic Library', value: 'DynamicLib' },
  { key: 'StaticLib', text: 'Static Library', value: 'StaticLib' },
]

/*
 * Objective is to produce an array given the part of the graph result we are looking at which
 * is uniform and has the path, type of node, enclosing container, hardening protections, and file magic as peers.
 */
export const extractDataForBinaryType = (containerInfo, binaryNodeType, binaryNodeList) => {
	if (binaryNodeList === undefined) {
		// This could be as something like BaremetalContents doesn't query for executables.
		return [];
	}
	// Otherwise remap things into an list of objects and return.
	const dataRows = [];
	binaryNodeList.forEach((e) => {
    dataRows.push({
      containerInfo,
      binaryNodeType,
      'name': e.node.name,
      // NOTE: value will be null for field if not filled in by processor
      //       value will be undefined for field if not in the shape requested
      'hardeningNx': e.node.hardeningNx,
      'hardeningPic': e.node.hardeningPic, //undefined for StaticLibs as not in shape
      'hardeningRelro': e.node.hardeningRelro, //undefined for StaticLibs as not in shape
      'hardeningCanary': e.node.hardeningCanary,
      'hardeningFortified': e.node.hardeningFortified,
      'hardeningStripped': e.node.hardeningStripped,
      // items in BaremetalContents don't have a foundIn relationship:
      'path': (e.node.foundIn === undefined) ? null : e.node.foundIn.node.path,
      'magic': (e.node.foundIn === undefined) ? null : e.node.foundIn.node.magic
    });
	});
	return dataRows;
}

/*
 * Take in relations fuch as a relation to a FileSystem node (e.g., imageNode.fileSystems), and
 * produce rows for each type of binary relationship it has, all merged together to a single list.
 */
function makeRowsForContainer(containerRelation) {
  // also have containerRelation.node.__typename
  console.log(containerRelation)
  const containerInfo = `${containerRelation.node.name} at ${containerRelation.offset}`
  const rows = [];
  if (containerRelation.node.executables !== undefined) {
    rows.push(...extractDataForBinaryType(containerInfo, 'Executable', containerRelation.node.executables))
  }
  if (containerRelation.node.dynamicLibs !== undefined) {
    rows.push(...extractDataForBinaryType(containerInfo, 'DynamicLib', containerRelation.node.dynamicLibs))
  }
  if (containerRelation.node.staticLibs !== undefined) {
    rows.push(...extractDataForBinaryType(containerInfo, 'StaticLib', containerRelation.node.staticLibs))
  }
  return rows;
}

class BinaryHardeningTab extends React.Component {
  constructor(props) {
    //console.log('BinaryHardening')
    //console.log(props)
    super(props)
    const imageNode = props.data.image.node;
    this.imageId = imageNode.id;
    //console.log(imageNode)

    // Collect FileSystems executables/libs:
    this.rows = imageNode.filesystems.flatMap(makeRowsForContainer)
    // NOTE: We only use fileSystems as non-Linux things won't have hardening on them, at least
    //       that we can mark from how we analyze them today!
    //.concat(imageNode.baremetalContents.flatMap(makeRowsForContainer))
    // NODE: Add more types of containers here!
    //console.log(this.rows)
	}

  state = {
    typeFilter: [],
    pathFilter: '',
  }

  setPathFilter = (pathFilter) => {
    this.setState({pathFilter})
  }

  setTypeFilter = (typeFilter) => {
    this.setState({typeFilter})
  }

  clearFilters = () => {
    this.setState({typeFilter: []})
  }

  render() {
    const { typeFilter, pathFilter } = this.state

    if (this.rows.length === 0) {
      if (isAnalysisFinished(this.props.data.image.node.status)) {
        return <DataNoResults msg='No Linux filesystems were found in this firmware to inspect for binary hardening.' />
      } else {
        return <DataNotReady />
      }
    }

    // NOTE: for layout these must add up:
    //  BinaryHardening summary is set to 150px
    //  PageToolbar is set to 50px
    //  BinaryHardeningTable is set to 100% - 200px
    return (
      <Page >
        <PageMainNoFlex>
          <BinaryHardeningSummary imageId={this.imageId} />
          <PageToolbar>
            <Input 
              icon='search' 
              placeholder='Search By Path' 
              onChange={(_, data) => { this.setPathFilter(data.value) }} 
            />
            <FlexSpace />
            <Dropdown 
              placeholder='Filter' 
              multiple 
              selection 
              options={filterOptions} 
              value={this.state.typeFilter}
              onChange={(_, data) => { this.setTypeFilter(data.value) }}
            />
            <Button size='tiny' color='grey' onClick={this.clearFilters} style={{margin: 5}}>
              Clear Filters
            </Button>
          </PageToolbar>
          <BinaryHardeningTable 
            rows={this.rows}
            pathFilter={pathFilter}
            typeFilter={typeFilter}
          />
        </PageMainNoFlex>
      </Page>
    )
  }
}

// data
BinaryHardeningTab = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        image {
          id
          node {
            id
            status
            filesystems {
              id
              offset
              node {
                id
                name
                executables {
                  id
                  node {
                    id
                    name
                    hardeningNx
                    hardeningPic
                    hardeningRelro
                    hardeningCanary
                    hardeningFortified
                    hardeningStripped
                    foundIn {
                      id
                      node {
                        ... on File {
                          id
                          path
                          magic
                        }
                      }
                    }
                  }
                }
                dynamicLibs {
                  id
                  node {
                    id
                    name
                    hardeningNx
                    hardeningPic
                    hardeningRelro
                    hardeningCanary
                    hardeningFortified
                    hardeningStripped
                    foundIn {
                      id 
                      node {
                        ... on File {
                          id
                          path
                          magic
                        }
                      }
                    }
                  }
                }
                staticLibs {
                  id
                  node {
                    id
                    name
                    hardeningNx
                    hardeningCanary
                    hardeningFortified
                    hardeningStripped
                    foundIn {
                      id
                      node {
                        ... on File {
                            id
                            path
                            magic
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({ input: { id: props.match.params.imageId } }),
  onData: (data) => (data.Submission[0])
})(BinaryHardeningTab)


export default BinaryHardeningTab
