import React from 'react'
import { withStyles } from '@material-ui/core/styles';

const style = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }
}

class AppMain extends React.Component {

  render() {
    const { classes, children } = this.props
    return (
      <div className={classes.root}>
        {children}
      </div>
    )
  }
}

AppMain = withStyles(style)(AppMain)

export default AppMain