import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function PopupCodeDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { openButtonText, title, codeText } = props;
  const textAreaRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copyToClipboard = (e) => {
    // Approach from https://stackoverflow.com/questions/25455567/how-can-i-select-a-paragraph-with-javascript-on-click/25456308#25456308
    var range = document.createRange();
    var selection = window.getSelection();
    range.selectNodeContents(textAreaRef.current);
    selection.removeAllRanges();
    selection.addRange(range);
    
    document.execCommand('copy');
    // To not show the whole text area selected:
    selection.removeAllRanges();
  };

  return (
    <div>
      <Button variant="outlined" color="default" size='small' onClick={handleClickOpen}>
        {openButtonText}
      </Button>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"
            style={{
              fontFamily: 'Courier',
              whiteSpace: 'pre-line',
              background: 'black',
              color: 'white',
              padding: 10,
            }}
            ref={textAreaRef}
          >
            {codeText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={copyToClipboard} color="secondary" autoFocus>
            Copy
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
