import React from 'react'

class PageMain extends React.Component {
  render() {
    return (
      <div style={{ 
        flex: 1,
        paddingTop: 20,
        paddingBottom: 20,
        height: 'calc(100% - 50px)',
        display: 'flex',
        flexDirection: 'column',
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export class PageMainNoFlex extends React.Component {
  render() {
    return (
      <div style={{ 
        paddingTop: 20,
        paddingBottom: 20,
        height: 'calc(100% - 10px)',
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default PageMain
