import gql from 'graphql-tag'

const grantAccess = async (client, resourceType, resourceId, subjectType, subjectId) => {
  const results = await client.mutate({
    mutation: gql`mutation GrantAccess($input: GrantAccessInput) {
      GrantAccess(input: $input)
    }`,
    variables: {input: {resourceType, resourceId, subjectType, subjectId}}
  })
  return results
}

export default grantAccess;
