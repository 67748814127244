import React from 'react';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'

const style = (theme) => ({
  fab: {
    padding: 0,
    margin: 0,
    minHeight: 30,
    minWidth: 30,
    height: 35,
    width: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 0
  },
})

class ImageCircleButton extends React.Component {
  render() {
    const { classes, src } = this.props
    return (
        <Link to='/' >
          <Fab className={classes.fab}>
            <img alt='' width='25px' height='25px' src={src} />
          </Fab>
        </Link>
    )
  }
}

ImageCircleButton = withStyles(style)(ImageCircleButton)

export { ImageCircleButton }
