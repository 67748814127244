import React from 'react'

class VerticalSpace extends React.Component {
  render() {
    return (
      <div style={{paddingLeft: 10}} />
    )
  }
}

export default VerticalSpace