import gql from 'graphql-tag'

const deleteGroup = async (client, groupId) => {
  const results = await client.mutate({
    mutation: gql`mutation GroupDelete($input: [GroupMatchInput]) {
      GroupDelete(input: $input) { id }
    }`,
    variables: {input: {id: groupId}}
  })
  return results
}

export default deleteGroup;
