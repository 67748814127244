import React from 'react'
import gql from 'graphql-tag'
import {Button} from 'semantic-ui-react'
import {withGraphqlQuery} from '../../graphql/core/connectors'


function downloadReport(client, submissionId, reportType) {
    client.mutate({
        mutation: gql`
            mutation DownloadDocumentExport($input: DownloadDocumentExportInput) {
                DownloadDocumentExport(input: $input)
            }
       `,
        variables: {
            input: {
                'submissionId': submissionId,
                'reportType': reportType,
            }
        }
    })
        .then((res) => {
            const documentUrl = res.data.DownloadDocumentExport
            fetch(documentUrl, {
                method: 'GET'
            }).then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    const baseName = documentUrl.split('/').reverse()[0]
                    let fileExt = `.${reportType}`.toLowerCase()
                    if (fileExt === '.csv') {
                        fileExt = '.zip' // CSV files are zipped together
                    }
                    const indexOfExt = baseName.indexOf(fileExt) + fileExt.length
                    const downloadName = baseName.substring(0, indexOfExt)
                    a.style.display = 'none'
                    a.href = url
                    a.download = downloadName
                    document.body.appendChild(a)
                    a.click()
                    window.URL.revokeObjectURL(url)
                })
        })
}

function generateReport(setGenerating, client, submissionId, startPolling) {
    setGenerating('true')
    client.mutate({
        mutation: gql`
            mutation RunDocumentExport($input: RunDocumentExportInput) {
                RunDocumentExport(input: $input)
            }
        `,
        variables: {
            input: {'submissionId': submissionId}
        }
    })
        .catch(e => {
            console.error(e)
        })
    startPolling(5000)
}

const useStateWithLocalStorage = localStorageKey => {
    const [value, setValue] = React.useState(
        localStorage.getItem(localStorageKey) || 'false'
    )

    React.useEffect(() => {
        localStorage.setItem(localStorageKey, value)
    }, [value, localStorageKey])

    return [value, setValue]
}

const DownloadReportButton = (props) => {
    const {client, submissionId, reportType, ...rest} = props
    return (
        <Button
            icon
            compact
            onClick={() => {
                downloadReport(client, submissionId, reportType)
            }}
            {...rest}
        >
            Download {reportType}
        </Button>
    )
}

let DocumentExportButton = (props) => {
    const {data, client, submissionId, startPolling, stopPolling, inDropdown, showPdf} = props
    const {pdfReportLocation} = data
    const [generating, setGenerating] = useStateWithLocalStorage(`pilot-${submissionId}-report-generated`)

    if (pdfReportLocation) {
        stopPolling()
    }
    const isGenerating = generating === 'true'
    const shouldShowPdf = showPdf === true
    const generateDocText = (shouldShowPdf) ? 'Generate Report CSV & PDF' : 'Generate Report CSV'
    const styling = (inDropdown) ? {
        size: 'tiny',
        color: 'grey',
        style: {'margin-bottom': '10px'},
    } : {}
    return (
        <>
            {
                (pdfReportLocation) ?
                    (
                        <>
                            <DownloadReportButton
                                submissionId={submissionId}
                                client={client}
                                reportType={'CSV'}
                                {...styling}
                            />
                            {((shouldShowPdf) ?
                            <DownloadReportButton
                                submissionId={submissionId}
                                client={client}
                                reportType={'PDF'}
                                {...styling}
                            />
                            : <></>)}
                        </>
                    )
                    : (
                        <>
                            <Button
                                icon
                                compact
                                disabled={isGenerating}
                                onClick={isGenerating ? null : () => {
                                    generateReport(setGenerating, client, submissionId, startPolling)
                                }}
                                {...styling}
                            >
                                {isGenerating ? 'Generating Report...' : generateDocText}
                            </Button>
                        </>
                    )
            }
        </>
    )
}

DocumentExportButton = withGraphqlQuery({
    query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        pdfReportLocation
      }
    } 
  `,
    variables: (props) => {
        return {input: {id: props.submissionId}}
    },
    onData: (data) => data.Submission[0],
})(DocumentExportButton)

export default DocumentExportButton
