import moment from 'moment'

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatHex = (i, n=8) => {
  return `0x${parseInt(i).toString(16).toUpperCase().padStart(n, '0')}`
}

export function toRwxStr(fieldInt) {
  let result = ""
  result += (fieldInt&(1<<2)) ? "r" : "-"
  result += (fieldInt&(1<<1)) ? "w" : "-"
  result += (fieldInt&1) ? "x" : "-"
  return result
}

export function toChmodStr(modeInt) {
  const valO = (modeInt&parseInt("7", 8))
  const valG = (modeInt&parseInt("70", 8))>>3
  const valU = (modeInt&parseInt("700", 8))>>6
  return toRwxStr(valU) + toRwxStr(valG) + toRwxStr(valO);
}

export const prettyDate = (date) => {
  return `${moment(date).format('lll')} (${moment(date).fromNow()})`
}

export const shortSha256 = (s) => {
  return `${s.slice(0,10)}...${s.slice(-10)}`
}