import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Icon } from 'semantic-ui-react'

class SidebarMenu extends React.Component {
  render() {
    const { items, classes, location } = this.props
    return (
      <List>
      { items.map((item) => {
        const { title, link, icon } = item
        const active = (location.pathname.match(`^${link}`) !== null)
        return (
          <ListItem button component={Link} key={link} to={link} selected={active}>
            <ListItemIcon >
              <div className={classes.icon}>
                <Icon name={icon} />
              </div>
            </ListItemIcon>
            <ListItemText>
              <Typography className={classes.text} variant='body1'>{title}</Typography>
            </ListItemText>
          </ListItem>
      )})}
      </List>
    )
  }
}

SidebarMenu = withRouter(SidebarMenu)

SidebarMenu = withStyles((theme) => ({
  selectedItem: {
    background: 'blue'
  },
  item: {
    selected: {
      background: 'blue'
    }
  },
  activeItem: {
    background: 'blue',
  },
  text: {
    fontSize: 13.5,
    fontWeight: 500,
    //color: '#D0D0D0'
  },
  icon: {
    //color: '#D0D0D0',
    display: 'flex', 
    alignItems: 'center', 
    paddingLeft: 2
  }
}))(SidebarMenu)

export default SidebarMenu
     