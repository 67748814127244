import React from 'react'
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../../../../../graphql/core/connectors';
import NetworkInfo from './variants/NetworkInfo';
import SensitiveInfo from './variants/SensitiveInfo';

class FindingGeneric extends React.Component {
  render() {
    const { data, printStyle } = this.props
    switch (data.title) {
      case 'Disclosed Network Information':   // TODO: Remove as this is deprecated, here so old findings render.
      case 'Disclosed Domain Names':
      case 'Disclosed Email Addresses':
      case 'Disclosed IP Addresses':
      case 'Disclosed URLs':
        return <NetworkInfo data={data} printStyle={printStyle} />
      case 'Sensitive Info Disclosed':
        return <SensitiveInfo data={data} printStyle={printStyle} />
      default:
        console.error("Unknown generic finding type:", data.title);
        return <div>Unknown generic finding: {data.title}</div>
    }
  }
}

// data
FindingGeneric = withGraphqlQuery({
  query: gql`
    query FindingGeneric($input: FindingGenericMatchInput) {
      FindingGeneric(input: $input) {
        id
        idCwe
        idCve
        scoreCvss
        title
        description
        impact
        recommendation
        details
        source
        vulnerableEntity {
          id
          node {
            __typename
            ... on FileContents {
              id
              type
              value
            }
          }
        }
      }
    } 
  `,
  variables: (props) => {
    return {input: {id: props.id}}
  },
  onData: (data) => data.FindingGeneric[0]
})(FindingGeneric)

export default FindingGeneric