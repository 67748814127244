import React from 'react'

class PageRoot extends React.Component {
  render() {
    return (
      <div style={{
        flex: '1',
        height: '100vh',
        display: 'flex', 
        flexDirection: 'column', 
        paddingTop: '10px',
        paddingLeft: '20px',
        paddingRight: '20px', 
        paddingBottom: '20px'}}
      >
        {this.props.children}
      </div>
    )
  }
}

export default PageRoot