import React from 'react';
import { withRouter} from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import DropdownMenu from '../dropdown/DropdownMenu'
import Button from '@material-ui/core/Button';

class AppbarProfileMenu extends React.Component {
  render() {
    const { classes, primary, secondary, onSignOut } = this.props
    return (
      <DropdownMenu icon='user circle' color='white' name='profilemenu'>
        <div>
          <div className={classes.userSection}>
            <Typography className={classes.userNameText} variant='body1'>{primary}</Typography>
            <Typography className={classes.userEmailText} variant='subtitle1'>{secondary}</Typography>
          </div>
          <Divider className={classes.spacer} />
          <div>Profile</div>
          <Divider className={classes.spacer} />
          <div className={classes.row}>
            <Button variant="contained" onClick={onSignOut}>Signout</Button>
          </div>
        </div>
      </DropdownMenu>
    )
  }
}

const styles = (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  spacer: {
    marginTop: 5,
    marginBottom: 5
  },
  userSection: {
    width: 200,
    //padding: 15,
  },
  userNameText: {
    fontSize: 16,
    fontWeight: 500
  },
  userEmailText: {
    fontSize: 13
  },
  linkSection: {
    padding: 10
  },
  linkText: {
    fontSize: 14
  },
  signoutSection: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5
  },
  signoutButton: {
    width: 200
  },
  signoutButtonIcon: {
    marginLeft: theme.spacing(1)
  }
})
AppbarProfileMenu = withStyles(styles)(withRouter(AppbarProfileMenu))

export default AppbarProfileMenu
