import grey from '@material-ui/core/colors/grey'

const baseTheme = {
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: grey[700],
      main: grey[800],
      dark: grey[900],
    }
  },
  status: {
    danger: 'orange',
  },
  sidebarWidth: 240,
  header: {
    height: 60,
  },
  appbar: {
    height: 48,
    background: grey[800]
  },
  selected: {
    background: 'green'
  },
}

export { baseTheme }