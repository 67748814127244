import _ from 'lodash'
import React from 'react'
import gql from 'graphql-tag'
import { withStyles, Typography } from '@material-ui/core'
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../graphql/core/connectors';
import PageMain from '../core/layout/PageMain'
import { formatHex } from '../../utils/format'
import DataNoResults from '../messages/DataNoResults'


function textTruncate(text, length) {
  if (text === undefined) {
    //console.warn("No text given by node.");
    return '';
  }
  if (length === undefined) {
    length = 25;
  }
  if (text.length > length) {
    return text.substring(0, length - 3) + '...';
  } else {
    return text;
  }
}

class ExtractedContainerSelector extends React.Component {

  state = {
    filesystemId: null
  }

  async download(filesystemId) {
    const result = await this.props.client.query({
      query: gql` 
        query { 
          FileSystem(input: {id: "${filesystemId}"}) {
            downloadUrl
          }
        }
      `,
    }).catch((e) => console.log(e))
    const url = result.data.FileSystem[0].downloadUrl

    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const { classes, data, showOnlyTypes } = this.props
    const submission = data

    // If no filesystems, contents, or other containers are found, display message that there is no data:
    const filesystems = submission.image.node.filesystems
    const baremetalContents = submission.image.node.baremetalContents
    const configContents = submission.image.node.configContents
    if (filesystems.length === 0 && baremetalContents.length === 0 && configContents.length === 0) {
      return <DataNoResults msg='No file system or other container was found in this firmware.' />
    }

    // Create dropdown for existing filesystems, contents, and other containers:
    const typeAndData = {
      'File System': filesystems,
      'Baremetal Contents': baremetalContents,
      'Configuration Contents': configContents
    };
    const indexedContainers = {}; // key is contianer ID, contents is the node from the query
    const options = [];
    // eslint-disable-next-line
    Object.keys(typeAndData).map((typeKey) => {
      // If the type is not one we want to show, skip over it:
      if (showOnlyTypes === undefined || showOnlyTypes.includes(typeKey)) {
        // For each type that we have defined, take it's data and add it to options:
        // eslint-disable-next-line
        typeAndData[typeKey].map((container) => {
          indexedContainers[container.node.id] = {
            type: typeKey,
            ...container.node
          };
          // TODO: Include typeKey and use this for UI icon display later:
          options.push({
            key: container.node.id,
            value: container.node.id,
            text: `${formatHex(container.offset)} ${textTruncate(container.node.name)}`
          });
        })
      } else {
        //console.log("Not displaying containers of type", typeKey);
      }
    });

    // Take the user provided state for which item to view, or if not, pick a sane one:
    let containerId = this.state.filesystemId
    if (containerId === null) {
      // Try to find a good default item to show the user:
      for (let fs of filesystems) {
        // TODO: we grab tons of rows of ids from the graph just to be able to do this check, instead just get a count back!
        if (fs.node.files.length > 1) {
          containerId = fs.node.id;
          break;
        }
      }
      // If we get here, no FSs had files in them, try other things that we may have
      if (containerId === null && baremetalContents.length > 0) {
        containerId = baremetalContents[0].node.id;
      }
      if (containerId === null && configContents.length > 0) {
        containerId = configContents[0].node.id;
      }
    }
    
    const selectedContainer = indexedContainers[containerId];
    if (selectedContainer === undefined) {
      // This should only happen when no container in the firmware has executables.
      // NOTE: Probably need to make sure of that and have better messaging for it.
      return <DataNoResults msg='No items of the selected type were found within the container.' />
    }
    return (
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <Typography className={classes.text}>Container:</Typography>
          <Dropdown selection 
            options={options} 
            value={containerId} 
            onChange={(_,{value}) => {this.setState({filesystemId: value})}} 
          />
          { (selectedContainer.type === 'File System') ? (
          <div style={{marginLeft: 10}}>
            <Button 
              size='small' 
              color='blue' 
              onClick={() => { 
                this.download(containerId)
              }}
            >
              <Icon name='download' />
              Download
            </Button>
          </div>) : null }
          <div className={classes.desc}>
            { (selectedContainer !== undefined) ? (
              <Typography>{selectedContainer.fstype || selectedContainer.desc}</Typography>
            ) : (
              <div>Please select a file system or other container to view within.</div>
            )}
          </div>
        </div>
        <PageMain>
          { _.isFunction(this.props.children) ? (
            this.props.children({containerId, containerType: selectedContainer.type})
          ) : (
            React.cloneElement(this.props.children, {containerId, containerType: selectedContainer.type})
          )}
        </PageMain>
      </div>
    )
  }
}

// style
ExtractedContainerSelector = withStyles((theme) => ({
  root: {
    flex: '1',
    height: '100%',
  },
  toolbar: {
    marginBottom: '5px', 
    marginTop: '20px', 
    height: '30px', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center'
  },
  desc: {
    paddingLeft: '20px'
  },
  text: {
    fontSize: '18px',
    marginRight: '10px'
  },
}))(ExtractedContainerSelector)

// data
ExtractedContainerSelector = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        image {
          id
          node {
            id
            status
            filesystems {
              id
              offset
              node {
                id
                name
                fstype
                files {
                  id
                }
              }
            }
            baremetalContents {
              offset
              id
              node {
                id
                name
                desc
              }
            }
            configContents {
              offset
              id
              node {
                id
                name
                desc
              }
            }
          }
        }
      }
    }
  `,
  fetchPolicy: 'network-only',
  variables: (props) => ({
    input: {id: props.submissionId}
  }),
  onData: (data) => data.Submission[0]

})(ExtractedContainerSelector)

export default ExtractedContainerSelector
