import React from 'react'
import gql from 'graphql-tag'
import Typography from '@material-ui/core/Typography'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import DataNoResults from '../../../../components/messages/DataNoResults'
import BoundPortsList from './BoundPortsList'

class NetworkDisplay extends React.Component {
  render() {
    const filesystem = this.props.data

    return (
      <ScrollSpace plain={true}>
        <Typography variant="h5">Bound Network Ports</Typography>
        { (filesystem.boundPorts.length > 0) ? (
          <BoundPortsList data={filesystem.boundPorts} />
        ) : (
          <DataNoResults type='Bound Network Ports' />
        )}
      </ScrollSpace>
    )
  }
}

// data
NetworkDisplay = withGraphqlQuery({
  query: gql`
    query FileSystem($input: FileSystemMatchInput) {
      FileSystem(input: $input) {
        id
        boundPorts {
          id
          node {
            id
            protocol
            portNumber
            boundAddress
            method
            foundIn {
              id
              usageAddress
              usageMethod
              node {
                ... on Executable {
                  id
                  name
                }
                ... on ExecutableScript {
                  id
                  name
                }
                ... on DynamicLib {
                  id
                  name
                }
              }
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.filesystemId}
  }),
  onData: (data) => data.FileSystem[0]
})(NetworkDisplay)

export default NetworkDisplay
