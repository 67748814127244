import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withStyles, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import View from '../../../components/core/layout/View'
import ViewContent from '../../../components/core/layout/ViewContent'
import FlexSpace from '../../../components/core/layout/FlexSpace'
import gql from 'graphql-tag'
import { Icon, Button, Input } from 'semantic-ui-react'
import { Separator } from '../../../components/core/layout/Separator'
import { withGraphqlQuery } from '../../../graphql/core/connectors'
import NewImageButton from '../../../components/buttons/NewImageButton'
import Centered from '../../../components/core/layout/Centered'
import Row from '../../../components/core/layout/Row'
import SubmissionsTable from '../../../components/composite/SubmissionsTable'


class FirmwareSearch extends React.Component {
  render() {
    const { refetch, data, userProfile } = this.props
    const myFirmware = data.filter(s => {
      if (!s.user || !s.user.node) return false 
      return s.user.node.id === userProfile.id
    });
    //console.log(userProfile)
    const sharedFirmware = data.filter(s => {
      for (let sw of s.sharedWith) {
        if (sw) {
          if (sw.node) {
            if (sw.node.id === userProfile.id)
              return true;
            if (userProfile.groups) {
              if (userProfile.groups.includes(sw.node.id)) {
                if (s.user) {
                  if (s.user.node) {
                    if (s.user.node.id === userProfile.id) {
                      return false
                    }
                  }
                }
                return true;
              }
            }
          }
        }
      }
      return false; 
    });
    return (
      <React.Fragment>
        <View>
          <ViewContent>
            <Centered>
              <FirmwareSection title='Firmware Submissions' data={myFirmware} refetch={refetch} button={true} />
              <div style={{marginTop: 100}} />
              <FirmwareSection title='Firmware Shared with Me' data={sharedFirmware} refetch={refetch} button={false} />
              <div style={{marginBottom: 200}} />
            </Centered>
          </ViewContent>
        </View>
      </React.Fragment>
    )
  }
}

// router
FirmwareSearch = withRouter(FirmwareSearch)

// styling
FirmwareSearch = withStyles({
  cell: {
    padding: 0,
    paddingLeft: 10,
    margin: 0
  },
  toolbar: {
    padding: 15,
    display: 'flex',
    flexDirection: 'row'
  },
  text: {
    fontSize: 12
  },
  iconButton: {
    padding: 0,
    margin: 0,
    minWidth: 30,
    minHeight: 30
  },
  notification: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center'
  },
  submitLink: {
    background: 'lightgray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '50%',
    borderRadius: 10,
  }
})(FirmwareSearch)

// data
FirmwareSearch = withGraphqlQuery({
  fetchPolicy: 'network-only',
  pollInterval: 5000,
  query: gql`
    query {
      Submission {
        id
        warnings
        errors
        productName
        productVersion
        dateSubmitted
        sharedWith {
          node {
            ... on User {
              id
              name
            }
            ... on Group {
              id
              name
            }
          }
        }
        user {
          id
          node {
            id
            name
          }
        }
        image {
          id
          node {
            id
            size
            inputFilename
            status
          }
        }
      }
    }
  `,
  onData: (data) => data.Submission
})(FirmwareSearch)

/*
*/

// state
FirmwareSearch = connect(
  (state) => {
    const { userProfile } = state
    return { userProfile: userProfile }
  },
  null
)(FirmwareSearch)

export default FirmwareSearch


class FirmwareSection extends React.Component {

  state = {
    searchTerm: ''
  }

  render() {
    const { data, title, refetch, button } = this.props;
    const { searchTerm } = this.state;
    const firmware = _.orderBy(data, [s => s.dateSubmitted], ['desc'])
      .filter(s => (
        s.productName.toLowerCase().includes(searchTerm) ||
        s.productVersion.toLowerCase().includes(searchTerm) ||
        s.image.node.inputFilename.toLowerCase().includes(searchTerm) ||
        s.user.node.name.includes(searchTerm)
      ))
    return (
      <div style={{margionTop: 20}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 800}}>
          <Typography variant='h5'>{title}</Typography>
          <FlexSpace/>
          {(button === true) ? (
            <NewImageButton />
          ): null}
        </div>
        <Separator />
        <Divider />
        <Separator />
        <Row>
            <Input icon='search' placeholder='Search' value={searchTerm} onChange={(event, {value}) => { this.setState({searchTerm: value})}} />
            <div style={{marginRight: 10}} />
            <Button size='small' color='green' icon onClick={() => {refetch()}}><Icon name='refresh'/></Button>
        </Row>
        <Separator />
        { (firmware.length === 0) ? (
          <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div>No Firmware Images</div>
          </div>
        ) : (
            <SubmissionsTable data={firmware} filterUploading={true} />
        )}
      </div>
    )
  }
}
