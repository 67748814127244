import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Divider } from '@material-ui/core'
import Page from '../../../../components/core/layout/Page'
import PageMain from '../../../../components/core/layout/PageMain'
import CertificatePane from './certificates'
import KeysPane from './keys'

class CertificatesTab extends React.Component {
  render() {
    const submissionId = this.props.match.params.imageId;

    return (
      <Page>
        <PageMain>
          <CertificatePane submissionId={submissionId} />
          <Divider />
          <KeysPane submissionId={submissionId} />
        </PageMain>
      </Page>
    )
  }
}

// routing
CertificatesTab = withRouter(CertificatesTab)

// style unused at this level
// TODO: Removing this breaks the render, figure out why!
CertificatesTab = withStyles((theme) => ({
}))(CertificatesTab)

// data unused at this level

export default CertificatesTab
