import blue from '@material-ui/core/colors/blue'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  palette: {
    primary: {
      light: blue[700],
      dark: blue[800],
      main: blue[900]
    }
  },
  appbar: {
    background: '#003366'
  }
}
