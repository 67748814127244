import React from 'react'
import { Table, Divider } from 'semantic-ui-react'
import FindingLayout from '../../../FindingLayout'
import EntityLabel from '../../../../../../../components/labels/EntityLabel'


function makeSerialShellsTable(serialShell) {
  return (
    <React.Fragment>
      <div>Serial Shells:</div>
      <Table compact collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Program</Table.HeaderCell>
            <Table.HeaderCell>TTY</Table.HeaderCell>
            <Table.HeaderCell>Baud Rate</Table.HeaderCell>
            <Table.HeaderCell>Shell</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{serialShell.program}</Table.Cell>
            <Table.Cell>{serialShell.tty}</Table.Cell>
            <Table.Cell>{(serialShell.baud === null) ? 'NA' : serialShell.baud}</Table.Cell>
            <Table.Cell>{(serialShell.shell === null) ? 'NA' : serialShell.shell}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

function makeConfigContentsTable(configContents) {
  return (
    <React.Fragment>
      <div>Related Configuration Parameters:</div>
      <Table compact collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        { configContents.filecontents.map((fc) => {
          if (['stdin', 'stdout', 'stderr', 'baudrate', 'bootargs', 'bootcmd', 'silent', 'bootdelay', 'console'].indexOf(fc.node.type) >= 0) {
            return (
              <Table.Row key={fc.node.type}>
                <Table.Cell>{fc.node.type}</Table.Cell>
                <Table.Cell style={{fontFamily: 'monospace', maxWidth: '500px', overflow: 'clip'}}>{fc.node.value}</Table.Cell>
              </Table.Row>
            )
          } else {
            return null;
          }
        }) }
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

class SerialConsole extends React.Component {
  render() {
    const finding = this.props.data
    const printStyle = this.props.printStyle || false;
    // TODO: handle empty
    const vulnerableEntity = finding.vulnerableEntity[0].node
    const serialShell = (vulnerableEntity.__typename === 'SerialShell') ? vulnerableEntity : null;  // if linked to a SerialShell
    const configContents = (vulnerableEntity.__typename === 'ConfigContents') ? vulnerableEntity : null; // if linked to a ConfigContents, which in turn has FileContents
    return (
      <FindingLayout
        type='OS Configuration' 
        icon='wrench'
        title={finding.title}
        overallCvss={(printStyle === true) ? finding.scoreCvss : null}
        description={finding.description}
        impact={finding.impact}
        recommendation={finding.recommendation}     
        Details={() => (
          <div>
              <div>
                { (serialShell !== null) ? "Startup Configuration:" :
                  (configContents !== null) ? "Configuration Region:" :
                  null }
              </div>
              <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
              { (serialShell !== null) ? <EntityLabel typename='File' details={serialShell.launchedBy.node.path} /> :
                (configContents !== null) ? <EntityLabel typename='Region' details={configContents.desc} /> :
                null }
                <div style={{flex: '1'}} />
              </div>
            <Divider hidden />
            { (serialShell !== null) ? makeSerialShellsTable(serialShell) : 
              (configContents !== null) ? makeConfigContentsTable(configContents) :
              null }
          </div>
        )}
      />
    )
  }
}

export default SerialConsole
