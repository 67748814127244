import React from 'react'
import FindingLayout from '../../../FindingLayout'
import { Table } from 'semantic-ui-react'

class WeakKeys extends React.Component {
  render() {
    const finding = this.props.data
    const printStyle = this.props.printStyle || false;
    const keys = finding.vulnerableEntity.map(r => r.node).filter(n => (n.__typename === 'CryptographicKey'))
    return (
      <FindingLayout
        type='Cryptography' 
        icon='key'
        cve={finding.idCve}
        cwe={finding.idCwe}
        title={finding.title}
        overallCvss={(printStyle === true) ? finding.scoreCvss : null}
        description={finding.description}
        impact={finding.impact}
        recommendation={finding.recommendation}     
        Details={() => (
          <div>
            <Table compact celled collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Private</Table.HeaderCell>
                  <Table.HeaderCell>Size</Table.HeaderCell>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              { keys.map((key) => {
                return (
                  <Table.Row key={key.id}>
                    <Table.Cell>{key.type.toUpperCase()}</Table.Cell>
                    <Table.Cell>{key.private ? 'True' : 'False'}</Table.Cell>
                    <Table.Cell>{key.bits}</Table.Cell>
                    <Table.Cell>{key.extractedFrom.node.path}</Table.Cell>
                  </Table.Row>
                )
              })}
              </Table.Body>
            </Table>
          </div>
        )}
      />
    )
  }
}

export default WeakKeys