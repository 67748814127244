import React from 'react'
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../../../../../graphql/core/connectors';
import WeakKeys from './variants/WeakKeys'
import WeakCerts from './variants/WeakCerts'
import PrivateKeys from './variants/PrivateKeys'
import ExpiringCerts from './variants/ExpiringCerts'

class FindingCryptographicKey extends React.Component {
  render() {
    // TODO: handle empty
    const { data, printStyle } = this.props
    switch (data.title) {
      case 'Weak Cryptographic Key':
      case 'Weak Asymmetric Cryptographic Key':
        return <WeakKeys data={data} printStyle={printStyle} />
      case 'Certificate signed with Weak Private Key':
        return <WeakCerts data={data} printStyle={printStyle} />
      case 'Private Key(s) Disclosed':
        return <PrivateKeys data={data} printStyle={printStyle} />
      case 'Certificate(s) expired or near expiration':
        return <ExpiringCerts data={data} printStyle={printStyle} />
      default:
        console.error("Unknown cryptography finding type:", data.title);
        return <div>Unknown cryptography finding: {data.title}</div>
    }
  }
}

// data
FindingCryptographicKey = withGraphqlQuery({
  query: gql`
    query FindingCryptographicKey($input: FindingCryptographicKeyMatchInput) {
      FindingCryptographicKey(input: $input) {
        id
        idCwe
        idCve
        scoreCvss
        title
        description
        impact
        recommendation
        details
        vulnerableEntity {
          id
          node {
            ... on Certificate {
              id
              type
              bits
              signatureAlgorithm
              subject
              notAfter
              extractedFrom {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                }
              }
            }
            ... on CryptographicKey {
              id
              private
              type
              bits
              extractedFrom {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  variables: (props) => {
    return {input: {id: props.id}}
  },
  onData: (data) => data.FindingCryptographicKey[0]
})(FindingCryptographicKey)

export default FindingCryptographicKey