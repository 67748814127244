import { SET_USER_TOKEN } from "../actionTypes";

const initialState = null

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_TOKEN: return action.payload
    default: return state;
  }
}
