import React from 'react'
import { Message } from 'semantic-ui-react'

class DataNotReady extends React.Component {
  render() {
    return (
      <Message>
        <Message.Header>Analysis in Progress</Message.Header>
        <p>Please wait while analysis runs. Results will be displayed when they become available. </p>
      </Message>
    )
  }
}

export default DataNotReady