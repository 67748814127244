import React from 'react'
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'

class BaseTile extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.root}>
        {this.props.children}
      </Paper>
    )
  }
}

// styling
BaseTile = withStyles((theme) => ({
  root: {
    width: 160,
    height: 120,
    padding: 10,
    marginRight: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    fontSize: 12
  }
}))(BaseTile)


export default BaseTile




