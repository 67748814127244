import React from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Icon } from 'semantic-ui-react'

const colors = {
  green: '#21BA45',
  blue: '#2185D0',
  orange: '#e8941e',
  red: '#bf281d',
}

export const statuses = {
  RUNNING: {
    background: colors.blue,
    text: 'white',
    icon: 'cog'
  },
  COMPLETE: {
    background: colors.green,
    text: 'white',
    icon: 'checkmark'
  },
  INCOMPLETE: {
    background: colors.orange,
    text: 'white',
    icon: 'warning circle'
  },
  CLEANUP: {
    background: colors.blue,
    text: 'white',
    icon: 'refresh'
  },
  FAILED: {
    background: colors.red,
    text: 'white',
    icon: 'warning sign'
  },
  ERROR: {
    background: colors.red,
    text: 'white',
    icon: 'warning sign'
  },
  CANCELED: {
    background: 'black',
    text: 'white',
    icon: 'remove circle'
  },
  ABORTED: {
    background: 'black',
    text: 'white',
    icon: 'remove circle'
  },
  WAITING: {
    background: 'purple',
    text: 'white',
    icon: 'clock'
  },
  SCHEDULED: {
    background: 'purple',
    text: 'white',
    icon: 'clock'
  },
  UPLOADING: {
    background: 'purple',
    text: 'white',
    icon: 'cloud upload'
  },
  "NOT STARTED": {
    background: 'grey',
    text: 'white',
    icon: 'hourglass'
  },
  "NOT SUPPORTED": {
    background: 'grey',
    text: 'white',
    icon: 'minus circle'
  },
  "NOT APPLICABLE": {
    background: 'grey',
    text: 'white',
    icon: 'minus circle'
  },
  unknown: {
    background: 'grey',
    text: 'black',
    icon: 'question'
  }
}

class StatusLabel extends React.Component {
  render() {
    let { classes, text } = this.props
    let s = statuses[text]
    if (s === undefined) {
      s = statuses.unknown
    }
    let loading = ['RUNNING', 'CLEANUP'].includes(text)
    if (text === 'NOT APPLICABLE') {
      text = "N/A"
    }
    if (text === 'NOT SUPPORTED') {
      text = "UNSUPPORTED"
    }
    return (
      <div className={classes.root} style={{background: s.background}}>
        <div style={{marginRight: 10}}>
          <Icon loading={loading} fitted name={s.icon} inverted />
        </div>
        <Typography style={{color: s.text}} >{text}</Typography>
      </div>
    )
  }
}

StatusLabel = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '130px',
    height: '30px',
    borderRadius: '6px',
  },
  icon: {
    color: 'white',
    width: '20px',
    paddingRight: '5px',
    height: '100%',
    background: 'red'
  }

}))(StatusLabel)

export default StatusLabel

