import React from 'react'
import { Table } from 'semantic-ui-react'
import FindingLayout from '../../../FindingLayout'

class WeakCerts extends React.Component {
  render() {
    const finding = this.props.data
    const printStyle = this.props.printStyle || false;
    const certs = finding.vulnerableEntity.map(r => r.node).filter(n => (n.__typename === 'Certificate'))
    return (
      <FindingLayout
        type='Cryptography' 
        icon='key'
        cve={finding.idCve}
        cwe={finding.idCwe}
        title={finding.title}
        overallCvss={(printStyle === true) ? finding.scoreCvss : null}
        description={finding.description}
        impact={finding.impact}
        recommendation={finding.recommendation}     
        Details={() => (
          <div>
            <Table compact celled collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Key Modulus</Table.HeaderCell>
                  <Table.HeaderCell>Signature Algorithm</Table.HeaderCell>
                  <Table.HeaderCell>Certificate Subject</Table.HeaderCell>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              { certs.map((cert) => {
                return (
                  <Table.Row key={cert.id}>
                    <Table.Cell>{cert.bits}</Table.Cell>
                    <Table.Cell>{cert.signatureAlgorithm}</Table.Cell>
                    <Table.Cell>{cert.subject}</Table.Cell>
                    <Table.Cell>{cert.extractedFrom.node.path}</Table.Cell>
                  </Table.Row>
                )
              })}
              </Table.Body>
            </Table>
          </div>
        )}
      />
    )
  }
}

export default WeakCerts