import React from 'react'
import gql from 'graphql-tag'
import { Table, Popup, Icon, Message } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import FileEntityById from '../../../../components/labels/FileEntityById'

function createIconElement(method) {
  if (method.has('inittab') && method.has('Emulation')) {
    return (<Icon.Group>
      <Icon name={'power'} />
      <Icon corner name={'file text'} />
    </Icon.Group>)
  } else {
    return <Icon name={(method.has('inittab')) ? 'file text' : 'power'} />
  }
}


class StartupDisplay extends React.Component {
  render() {
    const filesystem = this.props.data;

    // TODO: Separate using startupCommands for an indication of if there is a startup log, as they may not always overlap!
    if (filesystem.startupCommands.length === 0) {
      return (
        <Message>
          <Message.Header>No startup commands found in this filesystem</Message.Header>
          <p>
            This is likely due to the filesystem not being the main filesystem on the device.
          </p>
          <p>
            If it is the main filesystem, it may be due to issues finding a supported inittab file
            and/or issues with conducting automated emulation.
            Contact us for assistance.
          </p>
        </Message>
      )
    }
    
    const startupCommands = filesystem.startupCommands;
    // TODO: Ensure order is preserved:
    startupCommands.sort((a, b) => a.orderIndex - b.orderIndex);
    let scByNode = {};
    startupCommands.map((sc) => {
      const fromFileParsing=(sc.orderIndex === null);  // aka NOT from emulation
      // We create an ID of the Executable graph ID plus how it was invoked:
      const unique_id = `${sc.node.id} ${sc.invocation} ${sc.arguments.join(' ')}`;
      if (scByNode[unique_id] === undefined) {
        scByNode[unique_id] = {
          binaryName: sc.node.name,
          invocation: `${sc.invocation} ${sc.arguments.join(' ')}`,
          index: sc.orderIndex,
          sourceFileId: sc.sourceFileId,
          method: new Set()
        };
      }
      scByNode[unique_id]['method'].add((fromFileParsing) ? 'inittab' : 'Emulation');
      return null;  // to avoid linter errors
    });

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Executable</Table.HeaderCell>
            <Table.HeaderCell>Command</Table.HeaderCell>
            <Popup
              content={'Indicates the relative order the execution was observed. Only available if found due to emulation.'}
              trigger={<Table.HeaderCell>Relative Order</Table.HeaderCell>}
              />
            <Popup
              content={'Indicates how it was determined that this executable may be launched during startup.'}
              trigger={<Table.HeaderCell>Found By</Table.HeaderCell>}
              />
            <Popup
              content={'The file that appears to have launched the command.'}
              trigger={<Table.HeaderCell>Found In</Table.HeaderCell>}
              />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { Object.keys(scByNode).map(function(key, i) {
            const scn = scByNode[key];
            const fromFileParsing=(scn.index === null);  // aka NOT from emulation

            return (
              <Table.Row key={key}>
                <Table.Cell>{scn.binaryName}</Table.Cell>
                <Table.Cell style={{fontFamily: 'monospace'}}>{scn.invocation}</Table.Cell>
                <Table.Cell>{scn.index}</Table.Cell>
                <Table.Cell>
                  {createIconElement(scn.method)}
                  {Array.from(scn.method).join(' & ')}
                  {
                    // If it is from emulation and has a sourceFileId, we know something about what launched it:
                    (!fromFileParsing && (scn.sourceFileId !== null)) ? <Icon name={'fork'} /> : null
                  }
                </Table.Cell>
                <Table.Cell>
                  { (scn.sourceFileId !== null) ? <FileEntityById fileId={scn.sourceFileId} /> : null }
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }
}

// data
StartupDisplay = withGraphqlQuery({
  query: gql`
    query FileSystem($input: FileSystemMatchInput) {
      FileSystem(input: $input) {
        id
        startupCommands {
          id
          sourceFileId
          invocation
          arguments
          orderIndex
          node {
            ... on Executable {
              id
              name
            }
            ... on ExecutableScript {
              id
              name
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.filesystemId}
  }),
  onData: (data) => data.FileSystem[0]
})(StartupDisplay)

export default StartupDisplay
