import React from 'react'
import gql from 'graphql-tag'
import { basename } from 'path'
import { Typography, withStyles } from '@material-ui/core'
import { Table } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import SimpleTable from '../../../../components/core/tables/SimpleTable'
import Cvss from '../../../../components/labels/Cvss'
import { shortSha256 } from '../../../../utils/format'

class ExecutableScriptDisplay extends React.Component {
  render() {
    const { classes } = this.props
    const script = this.props.data
    const foundInType = script.foundIn.node.__typename
    const title = (foundInType === 'File') ? basename(script.foundIn.node.path) : script.foundIn.node.name;
    return (
      <div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        <div>
          { (foundInType === 'File') ? (<SimpleTable 
            order={['Path', 'Type', 'Syntax', 'SHA256', 'Size' /*, 'Version', 'Date'*/]}
            fields={{
              'Path': script.foundIn.node.path,
              'Type': script.foundIn.node.magic,
              'Syntax': script.syntax,
              'SHA256': shortSha256(script.foundIn.node.sha256),
              'Size': script.foundIn.node.size,
              //'Version': script.version,
              //'Date': script.date
            }}
          />) : (<SimpleTable 
            order={['Type', 'Description']}
            fields={{
              'Type': foundInType,
              'Description': script.foundIn.node.desc
            }}
          />)}
        </div>
        <Typography className={classes.section}>Findings</Typography>
        { script.associatedFindings.length === 0 ? <div>No findings have been found related to this script.</div> : (
          <Table compact collapsing celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Severity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            { script.associatedFindings.map(r => r.node).map((finding) => {
                return (
                  <Table.Row key={finding.id}>
                    <Table.Cell>{finding.title}</Table.Cell>
                    <Table.Cell><Cvss score={finding.scoreCvss} /></Table.Cell>
                  </Table.Row>
                )
            })}
            </Table.Body>
          </Table>
        )}
        { /*
        <Typography className={classes.section}>Invoked Executables</Typography>
        { (script.executable.length === 0) ? (<div>None</div>) : (
          <div>
            <Table compact collapsing celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>name</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              {script.executable.map((exe) => {
                return (
                  <Table.Row key={exe.node.id}>
                    <Table.Cell>{exe.node.name}</Table.Cell>
                  </Table.Row>
                )
              })}
              </Table.Body>
            </Table>
          </div>
        )}
        */ }
      </div>
    )
  }
}

// style
ExecutableScriptDisplay = withStyles((theme) => ({
  root: {
    display: 'flex', 
    flexDirection: 'column', 
    paddingTop: '20px',
    paddingLeft: '20px', 
    paddingRight: '20px',
    paddingBottom: '50px',
    height: 'calc(100%)',  
  },
  title: {
    fontSize: 26,
    fontWeight: 800,
    marginBottom: '10px'
  },
  section: {
    fontSize: 20,
    fontWeight: 500,
    marginTop: 20,
    marginBottom: 10
  },
}))(ExecutableScriptDisplay)

// data
ExecutableScriptDisplay = withGraphqlQuery({
  query: gql`
    query ExecutableScript($input: ExecutableScriptMatchInput) {
      ExecutableScript(input: $input) {
        id
        name
        date
        version
        syntax
        foundIn {
          id
          node {
            __typename
            ... on File {
              id
              path
              magic
              size
              sha256
            }
            ... on ImageContents {
              id
              name
              desc
            }
            ... on BaremetalContents {
              id
              name
              desc
            }
            ... on Kernel {
              id
              name
              desc
            }
          }
        }
        executable {
          id
          node {
            ... on Executable {
              id
              name
            }
            ... on ExecutableScript {
              id
              name
            }
          }
        }
        associatedFindings {
          id
          node {
            ... on FindingGeneric {
              id
              title
              scoreCvss
            }
            ... on FindingVulnerableVersion {
              id
              title
              scoreCvss
            }
            ... on FindingCryptographicKey {
              id
              title
              scoreCvss
            }
            ... on FindingVulnerableFunctionCall {
              id
              title
              scoreCvss
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.executableId}
  }),
  onData: (data) => data.ExecutableScript[0]
})(ExecutableScriptDisplay)

export default ExecutableScriptDisplay
