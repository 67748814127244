import React from 'react'
import { Message } from 'semantic-ui-react'

class DataUnavailable extends React.Component {
  render() {
    return (
      <Message>
        <Message.Header>Data Unavailable</Message.Header>
        <p>Expected data not found. An error may have occurred during analysis.</p>
      </Message>
    )
  }
}

export default DataUnavailable