import React from 'react'
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../../../../../graphql/core/connectors';
import GenericFunctionFinding from './variants/GenericFunctionFinding'

class FindingVulnerableFunctionCall extends React.Component {
  render() {
    // TODO: handle empty
    const { data, printStyle } = this.props;
    return <GenericFunctionFinding data={data} printStyle={printStyle} />
  }
}

// data
const fragmentBinaryWithFoundIn = `{
  id
  name
  foundIn {
    id
    node {
      __typename
      ... on File {
        id
        path
      }
      ... on ImageContents {
        id
        name
      }
      ... on BaremetalContents {
        id
        name
      }
      ... on Kernel {
        id
        name
      }
    }
  }
}`;

FindingVulnerableFunctionCall = withGraphqlQuery({
  query: gql`
    query FindingVulnerableFunctionCall($input: FindingVulnerableFunctionCallMatchInput) {
      FindingVulnerableFunctionCall(input: $input) {
        id
        idCwe
        scoreCvss
        title
        description
        impact
        recommendation
        details
        vulnerableDataSite {
          id
          node {
            id
            confidence
            calledFunction
            vulnerableValue
            extractedFrom {
              id
              type
              locator
              node {
                __typename
                ... on Executable ${fragmentBinaryWithFoundIn}
                ... on ExecutableScript ${fragmentBinaryWithFoundIn}
                ... on DynamicLib ${fragmentBinaryWithFoundIn}
                ... on StaticLib ${fragmentBinaryWithFoundIn}
                ... on BaremetalContents {
                  id
                  name
                }
                ... on Kernel {
                  id
                  name
                }
              }
            }
          }
        }
        vulnerableEntity {
          id
          node {
            ... on Executable {
              id
              name
              foundIn {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on ImageContents {
                    id
                    name
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                  ... on Kernel {
                    id
                    name
                  }
                }
              }
            }
            ... on ExecutableScript {
              id
              name
              foundIn {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on ImageContents {
                    id
                    name
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                  ... on Kernel {
                    id
                    name
                  }
                }
              }
            }
            ... on DynamicLib {
              id
              name
              foundIn {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on ImageContents {
                    id
                    name
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                  ... on Kernel {
                    id
                    name
                  }
                }
              }
            }
            ... on StaticLib {
              id
              name
              foundIn {
                id
                node {
                  __typename
                  ... on File {
                    id
                    path
                  }
                  ... on ImageContents {
                    id
                    name
                  }
                  ... on BaremetalContents {
                    id
                    name
                  }
                  ... on Kernel {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  variables: (props) => {
    return {input: {id: props.id}}
  },
  onData: (data) => data.FindingVulnerableFunctionCall[0]
})(FindingVulnerableFunctionCall)

export default FindingVulnerableFunctionCall