import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Sidebar from '../../components/core/layout/Sidebar'
import SidebarMenu from '../../components/core/layout/SidebarMenu'
import 'graphql-playground/playground.css'
import firebase from 'firebase/app'
import 'firebase/auth'
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.min.css';
import { withRBAC } from '../../components/core/access/RBAC'

let ApiDocs = (props) => {
    console.log('ApiDocs ...')
  return (
    <div>API Documentation: coming soon!</div>
  )
}

ApiDocs = withRBAC('admin')(ApiDocs)


let ApiKeys = (props) => {
  return (
    <div>API Keys: coming soon!</div>
  )
}

ApiKeys = withRBAC('admin')(ApiKeys)


let ApiWorkbench = (props) => {
    const projectId = firebase.app().options.projectId
    let apiUrl;
    switch (projectId) {
      case 'pilot-security-staging':
        apiUrl = 'https://api.staging.pilot-security.com/api/graphql'
        break
      case 'pilot-security-preview':
        apiUrl = 'https://api.preview.pilot-security.com/api/graphql'
        break
      case 'pilot-security-prod':
        apiUrl = 'https://api.app.pilot-security.com/api/graphql'
        break
      default:
         apiUrl = process.env.REACT_APP_PILOT_API_URL
        if (!apiUrl) {
          apiUrl = 'http://localhost:80/api/graphql'
        }
        break
    }

    return (
        <GraphiQL
            fetcher={async graphQLParams => {
                const token = await firebase.auth().currentUser.getIdToken()
                const data = await fetch(
                    //'http://localhost:80/api/graphql',
                    apiUrl,
                    {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        authentication: token,
                        'user-agent': 'pilot-webapp'
                    },
                    body: JSON.stringify(graphQLParams),
                    credentials: 'same-origin',
                    },
                );
                return data.json().catch(() => data.text());
            }}
        />
    )
}

ApiWorkbench = withRBAC('admin')(ApiWorkbench)


 const menuItems = [
    {
        type: 'item',
        link: `/api/docs`,
        title: 'Documentation',
        icon: 'book'
    },
    {
        type: 'item',
        link: `/api/keys`,
        title: 'Keys',
        icon: 'key'
    },
    {
        type: 'item',
        link: `/api/playground`,
        title: 'Workbench',
        icon: 'code'
    }
]

class ApiRoute extends React.Component {
  render() {
    return (
        <div style={{display: 'flex', flexDirection: 'row', height: '100%' }}>
            <Sidebar>
                <SidebarMenu items={menuItems} />
            </Sidebar>
            <Switch>
                <Route path='/api/docs' exact={true} component={ApiDocs} />
                <Route path='/api/keys' exact={true} component={ApiKeys} />
                <Route path='/api/playground' exact={true} component={ApiWorkbench} />
                <Redirect to='/api/docs' />
            </Switch>
        </div>
    )
  }
}

ApiRoute = withRBAC('admin')(ApiRoute)

export default ApiRoute
