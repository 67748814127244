import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography'
import { Icon } from 'semantic-ui-react'
import BaseTile from '../core/tiles/BaseTile'

class SummaryTile extends React.Component {
  render() {
    const { classes, icon, text, count } = this.props
    return (
      <BaseTile>
        <div className={classes.main} >
          <div className={classes.mainItem}>
            <Icon name={icon} size='huge'/>
          </div>
          <div className={classes.mainItem}>
            <Typography className={classes.textCount}>{count}</Typography>
          </div>
        </div>
        <div className={classes.desc}>
          <Typography className={classes.textDesc}>{text}</Typography>
        </div>
      </BaseTile>
    )
  }
}

// props
SummaryTile.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
}

// styling
SummaryTile = withStyles((theme) => ({
  main: {
    //background: 'green',
    display: 'flex',
    flexDirection: 'row',
    flex: '1',
    width: '100%',
    marginBottom: '10px'
  },
  mainItem: {
    display: 'flex', 
    flexDirection: 'column', 
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center'
  },
  desc: {
    //background: 'blue',
    textAlign: 'center',
    flex: '1',
    width: '100%'
  },
  textDesc: {
    fontSize: 14,
    fontWeight: 500,
    color: '#404142'
  },
  textCount: {
    fontSize: 40,
    fontWeight: 400,
  }
}))(SummaryTile)


export default SummaryTile



