import React from 'react'
import gql from 'graphql-tag'
import { Message } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'

class EmulationDisplay extends React.Component {
  render() {
    const log = this.props.data

    if (log.length === 0) {
      return (
        <Message>
          <Message.Header>No emulation log available</Message.Header>
          <p>
            This is likely due to the filesystem not being the main filesystem on the device.
          </p>
          <p>
            If it is the main filesystem, the emulation attempt was unsuccessful. 
          </p>
        </Message>
      )
    }

    return <div style={{
      fontFamily: 'Courier',
      whiteSpace: 'pre-line',
      background: 'black', 
      color: 'white',
      padding: 10,
      }}
      >{log}</div>
  }
}

// data
EmulationDisplay = withGraphqlQuery({
  query: gql`
    query FileSystem($input: FileSystemMatchInput) {
      FileSystem(input: $input) {
        id
        emulationLog
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.filesystemId}
  }),
  onData: (data) => data.FileSystem[0].emulationLog,
  Error: (props) => {
    return (
      <Message>
        <Message.Header>No emulation log available</Message.Header>
        <p>
          This is likely due to the filesystem not being the main filesystem on the device.
        </p>
        <p>
          If it is the main filesystem, the emulation attempt was unsuccessful. 
        </p>
      </Message>
    )
  }
})(EmulationDisplay)


export default EmulationDisplay
