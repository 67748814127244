/*
Copyright 2020 Pilot Security. All Rights Reserved.
*/

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { extractDataForLibraryType, tableColumns, tableHeaders } from './utils';


/*
 * This is used to render one sub-section, e.g. 'Executables' or 'Libraries' and the
 * the corresponding table rows.
 */
class SbomTableSection extends React.Component {
	render() {
		const { sectionHeader, sectionData } = this.props;
		return (
		<Box margin={1}>
			<Typography variant="h6" gutterBottom component="div">
				{sectionHeader}
			</Typography>
			{
				sectionData.length === 0 ? 
					(<div>No {sectionHeader} found in this container.</div>) :
					(
						<Table size="small" aria-label="columns">
							<TableHead>
								<TableRow>
									{
										tableColumns.map((c) => (
											<TableCell key={c}>{c}</TableCell>
										))
									}
								</TableRow>
							</TableHead>
							<TableBody>
								{sectionData.map((row, i) => (
									<TableRow key={i}>
										<TableCell>{row.name}</TableCell>
										<TableCell>{row.version}</TableCell>
										<TableCell>{row.paths.join(', ')}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)
			}
		</Box>)
	}
}


class SbomTable extends React.Component {
	constructor(props) {
		super(props)
		const fsNode = props.data.data.node;
		this.exeData = extractDataForLibraryType('executables', fsNode.executables)
		this.libData = extractDataForLibraryType('dynamicLibs', fsNode.dynamicLibs).concat(
			extractDataForLibraryType('staticLibs', fsNode.staticLibs))
	}

	render() {
		return (
			<React.Fragment>
				<SbomTableSection sectionHeader={tableHeaders[0]} sectionData={this.exeData} />
				<SbomTableSection sectionHeader={tableHeaders[1]} sectionData={this.libData} />
			</React.Fragment>
		)
	}
}

export default SbomTable