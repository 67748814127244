import React from 'react'
import gql from 'graphql-tag'
import { withGraphqlQuery } from '../../graphql/core/connectors'
//import EntityLabel from './EntityLabel'

class FileEntityById extends React.Component {
  render() {
    const { data } = this.props
    //return <EntityLabel typename={`File`} details={data.path} />
    return `File: ${data.path}`;
  }
}

// data
FileEntityById = withGraphqlQuery({
  query: gql`
    query File($input: FileMatchInput) {
      File(input: $input) {
        id
        path
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.fileId}
  }),
  onData: (data) => data.File[0]
})(FileEntityById)

export default FileEntityById