import React from 'react'
import gql from 'graphql-tag'
import { withStyles, Typography, Box } from '@material-ui/core'
import { Dropdown, Icon } from 'semantic-ui-react'
import FileBrowser from 'react-keyed-file-browser'
import { withGraphqlQuery } from '../../../../graphql/core/connectors'
import ScrollSpace from '../../../../components/core/layout/ScrollSpace'
import VerticalSpace from '../../../../components/core/layout/VerticalSpace'
import PageSplit from '../../../../components/core/layout/PageSplit'
import SimpleTable from '../../../../components/core/tables/SimpleTable'
import DataNoResults from '../../../../components/messages/DataNoResults'
import PopupCodeDialog from '../../../../components/messages/PopupCodeDialog'


const organizeChains = (certs) => {
  let firstRootId = null;
  let chains = {}
  for (let i in certs) {
    const result = certs[i];
    if (firstRootId === null) {
      firstRootId = result.root.id;
    }
    if (chains[result.root.subjectHash] === undefined) {
      chains[result.root.subjectHash] = {
        length: result.chain.length,
        rootSubject: result.root.subject,
        rootId: result.root.id,
        chains: [result.chain]  // list of lists
      }
    } else {
      chains[result.root.subjectHash].chains.push(result.chain)
    }
  }
  return chains
}

class CertificatesPane extends React.Component {
  constructor(props) {
    super()
    const certs = props.data
    const chains = organizeChains(certs)
    this.state = {
      chains,
      selectedChainRootId: (Object.values(chains).length > 0) ? Object.values(chains)[0].rootId : null,
      selectedCertId: null,
      options: Object.values(chains).map((chain) => {
        return {
          key: chain.rootId,
          value: chain.rootId,
          text: chain.rootSubject
        }
      })
    }
  }

  render() {
    const { classes } = this.props
    const { chains, selectedChainRootId } = this.state

    if (Object.values(chains).length === 0) {
      return <DataNoResults type='Certificates' />
    }

    // organize chains
    const activeChain = Object.values(chains).find(c => (c.rootId === selectedChainRootId))
    const paths = {}
    const activeUnifiedChain = []
    for (let chain of activeChain.chains) {
      for (let cert of chain) {
        const subjectSafe = cert.subject.replace(/\//g, '%2f');
        const issuerSafe = cert.issuer.replace(/\//g, '%2f');
        const pathToIssuer = paths[cert.issuerHash];
        if (pathToIssuer === undefined) {
          paths[cert.subjectHash] = `${issuerSafe}`;
          activeUnifiedChain.push(Object.assign(cert, {'key': `${subjectSafe}/`}))
        } else if (cert.issuerHash === cert.subjectHash) {
          console.warn(`Found DUPLICATE to issuerHash: ${cert.issuerHash}: ${pathToIssuer}`)
          // NOTE: Above warning happens when the same cert is found in multiple files typically.
          // TODO: Show in rendering the source file for these certificates.
          activeUnifiedChain.push(Object.assign(cert, {'key': `${paths[cert.issuerHash]}/`}))
        } else {
          activeUnifiedChain.push(Object.assign(cert, {'key': `${paths[cert.issuerHash]}/${subjectSafe}/`}))
        }
      }
    }

    // find selected cert
    const selectedCert = activeUnifiedChain.find(c => (c.id === this.state.selectedCertId))
    const isCurrentCertSelfSigned = (selectedCert !== undefined) && (selectedCert.subjectHash === selectedCert.issuerHash);

    return (
      <Box className={classes.section}>
        <Box className={classes.toolbar}>
          <Typography className={classes.text}><Icon name='address card' /> Root Certificate:</Typography>
          <Dropdown selection 
            options={this.state.options} 
            value={this.state.selectedChainRootId} 
            onChange={(_,{value}) => {this.setState({selectedChainRootId: value})}} 
          />
        </Box>
        <Box className={classes.columnarea}>
          <PageSplit>
            <ScrollSpace>
              <FileBrowser
                canFilter={false}
                detailRenderer={() => ''}
                files={activeUnifiedChain}
                onSelect={(file) => {
                  this.setState({selectedCertId: file.id})
                }}
                headerRenderer={null}
                icons={{
                  Folder: <Icon name='caret right' />,
                  FolderOpen: <Icon name='dropdown' />
                }}
              />
            </ScrollSpace>
            <VerticalSpace />
            <ScrollSpace>
              <div style={{padding: 10}}>
              { (selectedCert === undefined) ? (
                <div>Please Select a Certificate</div>
              ) : (
                <SimpleTable
                  order={['Subject', 'Subject Hash', 'Issuer', 'Issuer Hash', 'Type', 'Signature Algorithm', 'Serial Number', 'Expiration', 'Certificate']}
                  fields={{
                    'Subject': selectedCert.subject,
                    'Subject Hash': selectedCert.subjectHash,
                    'Issuer': (isCurrentCertSelfSigned) ? 'Self-signed.' : selectedCert.issuer,
                    'Issuer Hash': selectedCert.issuerHash,
                    'Type': selectedCert.type,
                    'Signature Algorithm': selectedCert.signatureAlgorithm,
                    'Serial Number': selectedCert.serial,
                    'Expiration': selectedCert.notAfter,
                    'Certificate': (selectedCert.pemKey !== null && selectedCert.pemKey !== '') ? <PopupCodeDialog openButtonText='View' title='Certificate in PEM Encoding' codeText={selectedCert.pemKey} /> : 'Not extracted.',
                  }}
                />
              )}
              </div>
            </ScrollSpace>
          </PageSplit>
        </Box>
      </Box>
    )
  }
}

// style
CertificatesPane = withStyles((theme) => ({
  section : {
    padding: '10px',
    maxHeight: '50%',
    height: '50%'
  },
  toolbar: {
    marginBottom: '20px', 
    marginTop: '20px', 
    height: '30px', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center'
  },
  columnarea: {
    height: 'calc(100% - 30px)'
  },
  text: {
    fontSize: '18px',
    marginRight: '10px'
  },
}))(CertificatesPane)

// data
CertificatesPane = withGraphqlQuery({
  query: gql`
    query FindImageCertificateChains($input: ImageCertificateChainsInput) {
      FindImageCertificateChains(input: $input) {
        root {
          id
          subject
          subjectHash
          serial
        }
        chain {
          id
          subjectHash
          issuerHash
          subject
          issuer
          type
          signatureAlgorithm
          serial
          notAfter
          pemKey
        }
      }
    }
  `,
  variables: (props) => ({
    input: {submissionId: props.submissionId}
  }),
  onData: (data) => data.FindImageCertificateChains
})(CertificatesPane)

export default CertificatesPane
