import React from 'react'
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import runBinaryAnalysis from '../../graphql/functions/runBinaryAnalysis'
import downloadBndb from '../../graphql/functions/downloadBndb'

class BinaryAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      analyzeMsg: ''
    };
  }

  render() {
    const {executable, client, startPolling, binaryType} = this.props
    const {disabled, analyzeMsg} = this.state
    const numBndbLocationsFound = executable.location.filter(r => (r.type === 'BNDB')).length
    return (
      <React.Fragment>
        {!(numBndbLocationsFound !== 0) ? (
          <React.Fragment>
          { (executable.analysisTask !== null) ? (
             <Button variant="contained" color='primary' disabled={disabled}>
              <HourglassEmptyIcon />{executable.analysisTask.node.status}
            </Button>
          ) : (
            <React.Fragment>
              <Button variant="contained" color='primary' disabled={disabled}
                onClick={async () => {
                  this.setState({disabled: true})
                  await runBinaryAnalysis(client, executable.id, binaryType)
                  .catch((e) => {
                    this.setState({analyzeMsg: "Failed to run"})
                  })
                  startPolling(200)
                }}
              >
                <SearchIcon />Start Binary Analysis
              </Button>
              <div>{analyzeMsg}</div>
            </React.Fragment>
          )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button variant="contained"
              onClick={async () => {
                const success = await downloadBndb(client, executable.id, binaryType) 
                if (!success)
                  this.setState({analyzeMsg: "Issue obtaining file to download"})
                this.setState({disabled: false})
              }} 
            >
              <GetAppIcon />Download BinaryNinja Database
            </Button>
            <div>{analyzeMsg}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default BinaryAnalysis