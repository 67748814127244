import React from 'react'
import { Message } from 'semantic-ui-react'

class EmptyContents extends React.Component {
  render() {
    return (
      <div>
        <Message>No contents extracted from firmware</Message>
      </div>
    )
  }
}

export default EmptyContents