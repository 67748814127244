import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Typography } from '@material-ui/core'

export const icons = {
  FileSystem: 'folder',
  Image: 'archive',
  Executable: 'cube',
  BaremetalContents: 'microchip',
  ConfigContents: 'file text',
  CryptographicData: 'unlock alternate',
}

class ComponentTasksBlock extends React.Component {
  render() {
    const { targetType, targetId }  = this.props
    return (
      <div style={{marginBottom: '20px'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px'}}>
          <Icon size='large' name={icons[targetType]} />
          <Typography style={{fontSize: '16px'}}>{targetType}</Typography>
          <Typography style={{fontSize: '12px', marginLeft: '5px'}}>({targetId})</Typography>
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default ComponentTasksBlock