import React from 'react';
import { Route, Switch, Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withApollo } from '@apollo/react-hoc'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { setUserProfile } from './redux/actions'
import Appbar from './components/core/layout/Appbar'
import AppLayout from './components/core/layout/AppLayout'
import AppMain from './components/core/layout/AppMain'
import AppbarNavLinks from './components/core/layout/AppbarNavLinks'
import AppbarProfileMenu from './components/core/layout/AppbarProfileMenu'
import FlexSpace from './components/core/layout/FlexSpace'
import { ImageCircleButton } from './components/core/buttons/ImageCircleButton'
import Versions from './components/misc/Versions'
import DropdownMenu from './components/core/dropdown/DropdownMenu'
import FirmwareRoute from './routes/firmware'
import AdminRoute from './routes/admin'
import ApiRoute from './routes/api'
import TermsOfServiceComponent from './routes/misc/TermsOfService'
import pilotIcon from './resources/img/pilot-blue-128x128.png'
import firebase from 'firebase/app'
import { adminFilter } from './utils/access'
import 'firebase/auth'
import './App.css'


class App extends React.Component {
  render() {
    const { userProfile } = this.props
    return (
      <AppLayout>
        <CssBaseline />
        <Appbar>
          <ImageCircleButton src={pilotIcon} />
          <AppbarNavLinks links={[
              { text: 'Firmware', path: '/firmware' },
              { text: 'Admin', path: '/admin', admin: true },
              { text: 'API', path: '/api', admin: true },
            ].filter(adminFilter(userProfile))}
          />
          <FlexSpace />
          <AppbarProfileMenu
            primary={userProfile.name}
            secondary={userProfile.role}
            links={[
              {url: '/profile/settings', text: 'Settings'}
            ]}
            onSignOut={() => {
              firebase.auth().signOut()
              .then(() => {
                //console.log('Signed out user')
                this.props.setUserProfile(null)
              })
            }}
          />
          <DropdownMenu icon='question circle' color='white' name='helpmenu'>
            <Versions appVersion={process.env.REACT_APP_VERSION} />
            <Button variant="contained" component={Link} to='/tos'>Terms of Service</Button>
          </DropdownMenu>
        </Appbar>
        <AppMain>
          <Switch>
            <Route path='/firmware' exact={false} component={FirmwareRoute} />
            <Route path='/admin' exact={false} component={AdminRoute} />
            <Route path='/api' exact={false} component={ApiRoute} />
            <Route path='/tos' exact={true} component={TermsOfServiceComponent} />
            <Redirect to='/firmware' />
          </Switch>
        </AppMain>
      </AppLayout>
    )
  }
}

// state
App = connect(
  (state) => {
    const { userProfile } = state
    return { userProfile: userProfile }
  },
  { setUserProfile }
)(App)

// data
App = withApollo(App)

export default App
