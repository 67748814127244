import React from 'react'
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer'
/*
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton'
import ExpandIcon from '@material-ui/icons/ChevronRight'
import CollapseIcon from '@material-ui/icons/ChevronLeft'
*/

class Sidebar extends React.Component { 

  state = { 
    open: true
  }

  toggleOpen = () => {
    this.setState({open: !this.state.open})
  }

  render() {
    const { classes } = this.props
    const { open } = this.state
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div style={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 48px)'}}>
          <div style={{height: '100%'}}>
            {this.props.children}
          </div>
          {/*
          <div className={classes.drawerButton}>
            {(open) ? (
              <IconButton onClick={this.toggleOpen}><CollapseIcon /></IconButton>
            ) : (
              <IconButton onClick={this.toggleOpen}><ExpandIcon /></IconButton>
            )}
          </div>
          */}
        </div>
      </Drawer>
    )
  }
}

Sidebar = withStyles((theme) => ({
  drawerPaper: {
    zIndex: 0,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: theme.sidebarWidth,
    border: '1px solid rgba(0,0,0,.12)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    zIndex: 0,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  drawerButton: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  grow: {
    flexGrow: 1
  },
}))(Sidebar)

export default Sidebar